import React from 'react';
import CountDown from 'react-countdown';
import ReactPlayer from 'react-player';
import PoweredByInspify from '../../../Legal/PoweredByInspify';
import SessionHealthCheckButton from '../../../Meeting/HealthCheck/SessionHealthCheckButton';

const RDUWW21CountDown = ({
  endDate,
  onEnded
}: {
  endDate: Date;
  onEnded: () => void;
}) => {
  React.useEffect(() => {
    if (!endDate || new Date() >= endDate) {
      onEnded();
    }
  }, []);

  const renderNumber = (num: number, label: string) => {
    let str = `${num}`;
    if (num / 10 < 1) {
      str = `0${num}`;
    }
    return (
      <div className="box">
        <div className="timebox">
          <div>{str[0]}</div>
          <div>{str[1]}</div>
        </div>
        <span>{label}</span>
      </div>
    );
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      onEnded();
    }
    return (
      <div className="timer-container">
        {days !== 0 && renderNumber(days, 'DAYS')}{' '}
        {renderNumber(hours, 'HOURS')} {renderNumber(minutes, 'MINUTES')}{' '}
        {days === 0 && renderNumber(seconds, 'SECONDS')}
      </div>
    );
  };

  const assetHost = 'https://panos2.inspify.io';

  const glitchVideoUrl = `${assetHost}/virtual_boutique/68f1c557-3873-4069-83e7-ec6b0e7a7985/glitch_v2.mp4`;
  const backgroundImageUrl = `${assetHost}/virtual_boutique/6578a5b9-0382-4ebc-a4b5-ebeb4a5408a5/launchevent/norules.png`;
  const timerImageUrl = `${assetHost}/virtual_boutique/6578a5b9-0382-4ebc-a4b5-ebeb4a5408a5/launchevent/timer.png`;

  return (
    <div className="lcd">
      <div className="container">
        <div className="col col-content">
          <div className="logo-container">
            <img src={backgroundImageUrl} alt="" />
          </div>
          <h1>
            YOU ARE INVITED TO ENTER
            <br />
            THE FUTURE OF HYPER HOROLOGY
          </h1>
          <CountDown
            zeroPadDays={2}
            zeroPadTime={2}
            date={endDate}
            renderer={renderer}
          />
        </div>
        <div className="col col-bg">
          <ReactPlayer
            width="100%"
            url={glitchVideoUrl}
            playing
            muted
            loop
            volume={0}
            playsinline
          />
        </div>
      </div>
      <div className="powered-container">
        <PoweredByInspify position="right bottom" color="light" />
      </div>
      <SessionHealthCheckButton />

      <style jsx global>{`
        body {
          background: #000 !important;
        }
        .timer-container {
          display: flex;
          justify-content: center;
          margin-bottom: 0;
        }
        .box {
          text-align: center;
          margin: 0 5px;
        }
        .timebox {
          display: flex;
        }
        .timebox div {
          margin: 1px;
          font-size: 38px;
          font-weight: 700;
          background: url(${timerImageUrl}) center top no-repeat;
          background-size: 100% auto;
          color: #fff;
          display: flex;
          justify-content: center;
          align-content: center;
          flex-direction: column;
          width: 40px;
          height: 70px;
        }

        .col-bg > div {
          height: 40vh !important;
        }

        @media (min-width: 768px) {
          .timer-container {
            margin-bottom: 0;
          }
          .box {
            margin: 0 15px;
          }
          .timebox div {
            width: 80px;
            height: 140px;
            margin: 2px;
            font-size: 70px;
          }

          .col-bg > div {
            height: 60vh !important;
          }
        }
      `}</style>
      <style jsx>{`
        .powered-container {
          position: fixed;
          bottom: 10px;
          left: 0;
          right: 0;
        }
        .logo-container {
          margin: 0 auto 20px;
          max-width: 700px;
        }
        .logo-container img {
          width: 90%;
        }
        .lcd {
          background-color: #000;
          color: #fff;
          text-align: center;
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        .sub {
          display: block;
          max-width: 250px;
          text-align: center;
          margin: 20px auto;
          color: #ccc;
          font-size: 0.7em;
        }
        .container {
          margin: auto;
          padding: 0;
        }
        .col {
          padding: 0;
          margin: 0;
        }

        .col-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .col-bg :global(video) {
          object-fit: cover;
        }
        h1 {
          text-align: center;
          font-size: 16px;
          line-height: 1.4;
          margin-bottom: 20px;
          font-weight: 700;
        }

        @media (orientation: landscape) {
          .container {
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;
          }

          .col-bg img {
            max-height: 80vh;
          }
        }

        @media (min-width: 768px) {
          .col-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          h1 {
            font-size: 28px;
            margin-top: 10px;
            margin-bottom: 40px;
          }
        }
      `}</style>
    </div>
  );
};

export default RDUWW21CountDown;
