import React from 'react';
import { IProduct } from '../../interfaces/index';

interface Props {
  title: string;
  products: IProduct[];
  onClose: () => void;
  onClick: (product: IProduct) => void;
}
const ClientDetailProductsPanel = ({
  title,
  products,
  onClose,
  onClick
}: Props) => {
  return (
    <div className="all-items">
      <div className="items">
        <button
          className="close"
          type="button"
          onClick={() => {
            onClose();
          }}
        >
          &times;
        </button>
        <h4>{title}</h4>
        <div className="image-container">
          {products.map((p) => (
            <div key={p.id}>
              <img
                onClick={() => onClick(p)}
                src={p.previewImageUrl
                  .replace('.jpg', '.png')
                  .replace('.jpeg', '.png')}
              />
              <span>{p.modelCode}</span>
            </div>
          ))}
        </div>
      </div>
      <style jsx>
        {`
          .all-items :global(.image-container img) {
            height: 114px;
            cursor: pointer;
          }

          .all-items :global(.image-container) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            height: 110px;
            overflow: hidden;
            height: auto;
          }

          .all-items :global(.image-container > div) {
            text-align: center;
            width: 134px;
            margin-bottom: 30px;
          }
          .all-items :global(.image-container span) {
            width: 100%;
            display: inline-block;
            text-align: center;
            font-size: 12px;
            letter-spacing: 1px;
            font-weight: 400;
          }

          .all-items {
            position: fixed;
            background: #fff;
            left: 350px;
            width: 350px;
            top: 0;
            bottom: 0;
            overflow: auto;
            color: #666;
          }
          :global(.SMPortrait) .all-items{
            left:0 !important;
          }
          .all-items h4 {
            letter-spacing: 5px;
            border-bottom: 1px dotted #777;
            display: flex;
            justify-content: space-between;
            margin-top: 70px;
            font-weight: 300;
            padding-bottom: 12px;
            font-size: 24px;
          }
          .all-items .items {
            position: relative;
            padding: 30px;
          }
          .all-items .close {
            position: absolute;
            right: 5px;
            top: 0;
            color: #666;
            background-color: transparent;
            padding: 0;
            width: 45px;
            height: 45px;
            font-size: 24px;
            cursor: pointer;
            z-index: 10;
          }
        `}
      </style>
    </div>
  );
};

export default ClientDetailProductsPanel;
