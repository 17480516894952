import { useRouter } from 'next/router';
import React from 'react';

const PasswordProtection = ({
  correctPassword = 'abc123',
  children
}: {
  correctPassword?: string;
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const pass = router.query.pass as string;
  const mode = router.query.mode as string;
  const [password, setPassword] = React.useState('');
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleLogin = () => {
    if (password === correctPassword) {
      setLoggedIn(true);
    } else {
      setError(true);
    }
  };

  React.useEffect(() => {
    if (pass === correctPassword || mode === 'cockpit') {
      setLoggedIn(true);
    }
  }, [pass]);

  if (loggedIn) {
    return <div>{children}</div>;
  }

  return (
    <div className={`password-protection`}>
      {!loggedIn && (
        <div className="input-container">
          {error && (
            <div className="alert alert-danger text-center" role="alert">
              Wrong password!
            </div>
          )}
          <div className="input-group input-group-lg mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
            />
            <div className="input-group-append">
              <button className="btn btn-dark" onClick={handleLogin}>
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      )}
      <style jsx>{`
        .btn-light {
          position: fixed;
          bottom: 20px;
          right: 20px;
          opacity: 0.5;
        }
        .password-protection {
          z-index: 9999;
          position: fixed;
          left: 0;
          bottom: 0;
          right: 0;
          top: 0;
          background: black;
          display: flex;
          justify-content: center;
          align-item: center;
          flex-direction: column;
          opacity: 1;
        }
        .input-container {
          max-width: 350px;
          margin: 0 auto;
        }
        .input-group {
          border: 4px solid #efefef;
          border-radius: 10px;
        }
        .alert {
          padding: 2px;
          border-radius: 10px;
        }

        @-webkit-keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default PasswordProtection;
