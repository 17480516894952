import React from 'react';

function SSPBoutiqueStyle() {
  return (
    <style jsx global>{`
      body:not(.in-meeting) .popup-page-content {
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        border-radius: 0;
      }
      body:not(.in-meeting) .popup-page-content .iframe-container {
        width: 100vw;
      }
    `}</style>
  );
}

export default SSPBoutiqueStyle;
