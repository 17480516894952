import React from 'react';
import { useTranslation } from '../../../i18n';
import { NotificationType } from '../../../interfaces';
import NotificationPopup from '../../NotificationPopup';
import { RiSignalWifiErrorLine } from 'react-icons/ri';
import { MeetingContext } from '../MeetingContext';

const MeetingDisconnectNotification = () => {
  const { t } = useTranslation();

  const { networkState } = React.useContext(MeetingContext);

  if (networkState?.isOnline) return null;
  return (
    <>
      <NotificationPopup
        label={t('vb:no_internet')}
        icon={<RiSignalWifiErrorLine size={14} />}
        type={NotificationType.WARNING}
        className="status-user"
      />
    </>
  );
};

export default MeetingDisconnectNotification;
