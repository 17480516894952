const RotateLandscape = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 122.88 106.43"
  >
    <g>
      <path d="M11.1,0h35.63c3.05,0,5.85,1.25,7.85,3.25c2.03,2.03,3.25,4.8,3.25,7.85v31.46h-3.19V12.18H3.15v75.26l0,0 h7.61v11.61c0,1.58,0.27,3.1,0.77,4.51H11.1c-3.05,0-5.85-1.25-7.85-3.25C1.22,98.27,0,95.51,0,92.45V11.1 c0-3.05,1.25-5.85,3.25-7.85C5.28,1.22,8.04,0,11.1,0L11.1,0L11.1,0z M100.09,30.42c0.75-0.86,2.06-0.95,2.91-0.19 c0.86,0.75,0.95,2.06,0.19,2.91l-5.89,6.71c-0.74,0.85-2.02,0.94-2.88,0.22l0,0l-6.85-5.72c-0.88-0.73-0.99-2.04-0.26-2.91 c0.73-0.88,2.04-0.99,2.91-0.26l3.01,2.51c-0.82-3.89-2.71-7.43-5.48-10.2c-3.58-3.59-8.66-5.91-14.85-6.1 c-1.14-0.03-2.04-0.98-2.01-2.12s0.98-2.04,2.12-2.01c7.33,0.22,13.37,3.01,17.67,7.31c3.48,3.49,5.8,7.98,6.71,12.9L100.09,30.42 L100.09,30.42z M122.88,59.7v35.63c0,3.05-1.25,5.85-3.25,7.85c-2.03,2.03-4.8,3.25-7.85,3.25h-78.9c-3.05,0-5.85-1.25-7.85-3.25 c-2.03-2.03-3.25-4.8-3.25-7.85V59.7c0-3.05,1.25-5.85,3.25-7.85c2.03-2.03,4.79-3.25,7.85-3.25h78.9c3.05,0,5.85,1.25,7.85,3.25 C121.66,53.88,122.88,56.64,122.88,59.7L122.88,59.7L122.88,59.7z M35.41,77.49c0,2.51-2.03,4.57-4.57,4.57 c-2.51,0-4.57-2.03-4.57-4.57c0-2.51,2.03-4.57,4.57-4.57C33.36,72.92,35.41,74.95,35.41,77.49L35.41,77.49L35.41,77.49z M37.88,51.75v51.49h72.82V51.75H37.88L37.88,51.75z" />
    </g>
  </svg>
);

export default RotateLandscape;
