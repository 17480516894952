import React from 'react';
import ChatBubble from './ChatBubble';
import { MeetChatContext } from './MeetChatContext';
import { BiChevronDown } from 'react-icons/bi';
import ChatBubbleAction from './ChatBubbleAction';
import { getNow } from '../../../utils/clock';
import { meetingColor } from '../../../utils/meeting';

const ChatList = () => {
  const {
    messages,
    isPresenter,
    scrollTo,
    setScrollTo,
    setSeenMessageLastTime,
    unreadMessages
  } = React.useContext(MeetChatContext);
  const [autoScroll, setAutoScroll] = React.useState(true);
  const [selectedBubble, setSelectedBubble] = React.useState<string>(null);

  React.useEffect(() => {
    setSeenMessageLastTime(autoScroll ? null : getNow());
  }, [autoScroll]);
  React.useEffect(() => {
    const jumpTo = (id: string, smooth?: boolean) => {
      document.getElementById(id)?.scrollIntoView({
        block: 'end',
        behavior: smooth ? 'smooth' : 'auto'
      });
    };

    if (scrollTo) {
      jumpTo(scrollTo);
    } else if (autoScroll) {
      jumpTo('chatEndSection');
    }
  }, [scrollTo, messages?.length]);

  React.useEffect(() => {
    const el = document.getElementById('ChatList');
    const listener = () => {
      if (el.offsetHeight + el.scrollTop >= el.scrollHeight - 80) {
        setAutoScroll(true);
      } else {
        setAutoScroll(false);
      }
    };
    el.addEventListener('scroll', listener);
    return () => {
      el.removeEventListener('scroll', listener);
    };
  }, []);

  const scrollDownButton = (
    <div className="btn" onClick={() => setScrollTo('chatEndSection')}>
      {!!unreadMessages && (
        <div className="unread-container">
          <div className="unreadMessages">{unreadMessages}</div>
        </div>
      )}
      <BiChevronDown color="#333" />
      <style jsx>{`
        .btn {
          position: absolute;
          right: 0;
          bottom: 20px;
          line-height: 20px;
          font-size: 20px;
          padding: 5px 10px;
          border-radius: 10px 0 0 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          background: rgba(255, 255, 255, 0.7);
          animation: fade-in 1s ease-out both;
        }
        .unread-container {
          position: relative;
          width: 100%;
        }

        .unreadMessages {
          position: absolute;
          border-radius: 50px;
          background: ${meetingColor.warning};
          line-height: 18px;
          font-size: 13px;
          color: #fff;
          padding: 0 6px;
          top: -12px;
          left: -10px;
          transform: translateX(-50%);
        }

        .btn:hover {
          background: #fff;
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );

  const chatList = (
    <div id="ChatList">
      <div className="list-wrapper">
        {messages?.map((msg) => (
          <ChatBubble
            key={msg.id}
            message={msg}
            onClick={setSelectedBubble}
            quote={
              msg.quote ? (
                <ChatBubble
                  message={messages.find((m) => m.id === msg.quote)}
                  isQuoted
                />
              ) : null
            }
          />
        ))}
      </div>
      <div id="chatEndSection" />
      <style jsx>{`
        #ChatList {
          padding: 10px 10px 0;
          overflow-y: auto;
          overflow-x: hidden;
          position: absolute;
          left: 0;
          max-height: 100%;
          right: 0;
          bottom: 0;
        }
        #chatEndSection {
          margin-top: 10px;
        }
        #ChatList::-webkit-scrollbar {
          display: none;
        }
        #ChatList :global(.bubble-container.remote-bubble:last-child .bubble) {
          animation: slide-in-left 0.2s ease-out both;
        }

        #ChatList :global(.bubble-container.local-bubble:last-child .bubble) {
          animation: slide-in-right 0.2s ease-out both;
        }

        @keyframes slide-in-right {
          0% {
            transform: translateX(100%);
            opacity: 0;
          }
          100% {
            transform: translateX(0);
            opacity: 1;
          }
        }
        @keyframes slide-in-left {
          0% {
            transform: translateX(-100%);
            opacity: 0;
          }
          100% {
            transform: translateX(0);
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );

  return (
    <>
      {chatList}
      {!autoScroll && scrollDownButton}
      {selectedBubble && (
        <ChatBubbleAction
          onClose={() => setSelectedBubble(null)}
          message={messages.find((msg) => msg.id === selectedBubble)}
          isPresenter={isPresenter}
        />
      )}
    </>
  );
};

export default ChatList;
