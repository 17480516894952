import { SMGlobalSpec } from './SMGlobal';

const portraitSize = (totalVideos: number): [string, string] => {
  if (totalVideos < 3) return ['100%', '100%'];
  if (totalVideos === 3) return ['100%', '50%'];
  if (totalVideos === 4) return ['50%', '50%'];
  if (totalVideos <= 6) return ['50%', '33.33%'];
  if (totalVideos <= 8) return ['50%', '25%'];
  return ['50%', '25vh'];
};

const landscapeSize = (totalVideos: number): [string, string] => {
  if (totalVideos < 3) return ['100%', '100%'];
  if (totalVideos === 3) return ['50%', '100%'];
  if (totalVideos === 4) return ['50%', '50%'];
  if (totalVideos <= 6) return ['33.33%', '50%'];
  if (totalVideos <= 8) return ['25%', '50%'];
  return ['33.33%', '50vh'];
};

const localPortrait = (totalVideos: number) => {
  if (totalVideos < 4 && totalVideos !== 1) return ['90px', '50px'];
  return portraitSize(totalVideos);
};

const localLandscape = (totalVideos: number) => {
  if (totalVideos < 4 && totalVideos !== 1) return ['90px', '60px'];
  return landscapeSize(totalVideos);
};

const SMTile = ({ totalVideos }: { totalVideos: number }) => (
  <>
    <style jsx global>{`
      .tile-mode.SM #videos-wrapper {
        min-width: 100%;
        min-height: 100%;
      }
      .tile-mode.SM #participant-wrapper {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: ${SMGlobalSpec.logoSectionHeight}px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .in-meeting.tile-mode.SM #local-wrapper .video-thumbnail video {
        border-radius: 5px;
      }

      .in-meeting.tile-mode.SM #local-wrapper {
        display: flex;
        position: ${totalVideos < 4 && totalVideos !== 1 ? 'fixed' : 'initial'};
        left: ${totalVideos < 4 && totalVideos !== 1 ? '10px' : 'auto'};
        top: ${totalVideos < 4 && totalVideos !== 1
          ? `${SMGlobalSpec.logoSectionHeight + 10}px`
          : 'auto'};
      }

      .tile-mode.SM #local-wrapper .video-thumbnail {
        display: block;
        border-radius: 5px;
        border: ${totalVideos < 4 && totalVideos !== 1
          ? 'none'
          : '0.5px solid #000'};
        width: 100%;
        height: 100%;
      }

      .tile-mode.SM .video-thumbnail,
      .tile-mode.SM .video-thumbnai video {
        border-radius: 5px;
      }

      .tile-mode.SMPortrait .video-thumbnail {
        width: ${portraitSize(totalVideos)[0]};
        height: ${portraitSize(totalVideos)[1]};
      }
      .in-meeting.tile-mode.SMPortrait #local-wrapper {
        width: ${localPortrait(totalVideos)[0]};
        height: ${localPortrait(totalVideos)[1]};
      }

      .tile-mode.SMLandscape .video-thumbnail {
        width: ${landscapeSize(totalVideos)[0]};
        height: ${landscapeSize(totalVideos)[1]};
      }
      .in-meeting.tile-mode.SMLandscape #local-wrapper {
        width: ${localLandscape(totalVideos)[0]};
        height: ${localLandscape(totalVideos)[1]};
      }
      .tile-mode.SM .remote-video-container .display-name {
        font-size: 1.3em;
        padding: 8px 10px;
      }
      
      .tile-mode.SM #local-wrapper .video-thumbnail .display-name {
        font-size: ${totalVideos < 4 && totalVideos !== 1 ? '9px' : '1.3em'};
        padding: ${totalVideos < 4 && totalVideos !== 1 ? '2px 5px' : '8px 10px'};
      }

    `}</style>
  </>
);
export default SMTile;
