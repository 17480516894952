import React from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { useTranslation } from '../../i18n';
import { useLocalUserMeetingEntitlement } from '../hooks/meeting';
import { SMGlobalSpec } from './MeetingLayout/SMGlobal';
import { SMLandscapeNormalSpec } from './MeetingLayout/SMLandscapeNormal';
import { MDLandscapeNormalSpec } from './MeetingLayout/MDLandscapeNormal';
import { MDGlobalSpec } from './MeetingLayout/MDGlobal';

const RoleIndicator = () => {
  const { t } = useTranslation();
  const [_, isSourceOfMirrorActions] = useLocalUserMeetingEntitlement();

  if (isSourceOfMirrorActions) return null;

  return (
    <div className="role-indicator">
      <AiOutlineEye />
      <span className="initial">{t('vb:view_only')}</span>
      <span className="mouseover">{t('vb:controlled_by_advisor')}</span>
      <style global jsx>{`
        .tile-mode .role-indicator{
          display:none !important;
        }
        .MD .role-indicator{
          top: ${MDGlobalSpec.logoSectionHeight / 2}px !important;
          font-size:0.9em !important;
        }
        .MD .role-indicator svg {
          height: 22px !important;
        }
        .SMLandscape .role-indicator {
          left: ${SMLandscapeNormalSpec.videosWrapper.width + 5}px !important;
        }
        .MDLandscape .role-indicator {
          left: ${MDLandscapeNormalSpec.videosWrapper.width + 10}px !important;
        }
      `}</style>
      <style jsx>{`
        .role-indicator {
          direction: ltr !important;
          right: auto;
          left: 5px;
          top: ${SMGlobalSpec.logoSectionHeight / 2}px;
          transform: translateY(-50%);
          color: #909090;
          padding: 3px 5px;
          letter-spacing: 2px;
          font-size: 0.6em;
          font-weight: 500;
          background: #000;
          position: fixed;
          border-radius: 5px;
          display: flex;
          align-items: center;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        .initial {
          text-transform: uppercase;
        }
        .mouseover {
          display: none;
          letter-spacing: 0.8px;
        }
        .role-indicator:hover .initial {
          display: none;
        }
        .role-indicator:hover .mouseover {
          display: inline;
        }

        .role-indicator :global(svg) {
          width: auto;
          height: 15px;
          margin: auto;
          margin-right: 4px;
        }
      `}</style>
    </div>
  );
};

export default RoleIndicator;
