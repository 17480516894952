import React from 'react';
import { useDispatch } from 'react-redux';
import { IMeeting, MeetingLayoutMode, MeetingRole } from '../../../interfaces';
import { actionToggleEnlargeVideo } from '../../../redux/actions';

import {
  useLocalUserMeetingEntitlement,
  useMeetingLocalAudio
} from '../../hooks/meeting';

import { MeetingContext } from '../MeetingContext';
import VideosContainer from './VideosContainer';
import EnlargedVideo from './EnlargedVideo';
import OneThirdVideos from './OneThirdVideos';

/* eslint-disable no-var */
declare global {
  var JitsiMeetJS: any;
}
/* eslint-enable no-var */

const MeetingVideos = ({
  meeting,
  showStudioGrid
}: {
  meeting: IMeeting;
  showStudioGrid?: boolean;
}) => {
  const dispatch = useDispatch();
  const popupVideoPlayWithSound = meeting?.popupState?.videoPlayWithSound;
  const [canControlMeeting, _] = useLocalUserMeetingEntitlement();
  const { restoreAudio, muteAudioAndRestoreLater } = useMeetingLocalAudio();

  const enlargedVideoParticipantId =
    meeting?.layout?.enlargedVideoParticipantId;

  const layoutMode = meeting?.layout?.mode;
  const isOneThirdLayout = layoutMode === MeetingLayoutMode.ONE_THIRD;
  const oneThirdLayout = meeting?.layout?.oneThirdLayout;
  const localRole = meeting.localUser?.role;

  const { room } = React.useContext(MeetingContext);

  const isLocalVideoEnlarged = enlargedVideoParticipantId === room?.myUserId();

  const onToggleEnlarge = () => {
    const meetingLayout = meeting?.layout;
    const shouldEnlarge =
      meetingLayout?.enlargedVideoParticipantId !== enlargedVideoParticipantId;
    dispatch(
      actionToggleEnlargeVideo({
        participantId: enlargedVideoParticipantId,
        shouldEnlarge
      })
    );
  };

  React.useEffect(() => {
    if (popupVideoPlayWithSound) {
      muteAudioAndRestoreLater();
    } else {
      restoreAudio();
    }
  }, [popupVideoPlayWithSound]);

  const shouldUseOneThirdLayout = isOneThirdLayout && oneThirdLayout;
  return (
    <>
      {shouldUseOneThirdLayout && (
        <div className="one-third-layout">
          <OneThirdVideos layout={oneThirdLayout} />
        </div>
      )}
      {enlargedVideoParticipantId && !shouldUseOneThirdLayout && (
        <EnlargedVideo
          isLocalVideoEnlarged={isLocalVideoEnlarged}
          participantId={enlargedVideoParticipantId}
          onToggleEnlarge={onToggleEnlarge}
          shouldShowCloseButton={canControlMeeting}
          localRole={localRole}
          stats={(meeting.remoteUsers || {})[enlargedVideoParticipantId]?.stats}
          resolution={
            (meeting.stats?.resolution || {})[enlargedVideoParticipantId]
          }
          showEnlargedGrid={
            showStudioGrid &&
            (meeting.remoteUsers || {})[enlargedVideoParticipantId]?.role ===
              MeetingRole.STUDIO
          }
          isOverlayImgVisible={meeting?.isOverlayImgVisible}
        />
      )}
      <VideosContainer
        meetingLayout={meeting?.layout || {}}
        remoteUsers={meeting?.remoteUsers || {}}
        localUser={meeting?.localUser || {}}
        canControlMeeting={canControlMeeting}
      />
    </>
  );
};

export default MeetingVideos;
