import React from 'react';
import { IProduct, IMainState } from '../../interfaces';
import { useSelector } from 'react-redux';
import { BsFillCaretRightFill } from 'react-icons/bs';
import { ClientDetailSection } from './ClientDetail';
import { useTranslation } from '../../i18n';

const ClientDetailInterestedProducts = ({
  title,
  onClick,
  onViewAll
}: {
  title: string;
  onClick: (product: IProduct) => void;
  onViewAll: (
    products: IProduct[],
    title: string,
    type: ClientDetailSection
  ) => void;
}) => {
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const remoteUsers = meeting?.remoteUsers || {};
  const participantId = meeting?.clientDetailPanelParticipantId;
  const products = remoteUsers[participantId]?.interestedProducts || [];
  const { t } = useTranslation();

  return (
    <>
      <h4>
        <span>{title}</span>
        {!!products.length && products.length > 3 && (
          <button
            className="btn btn-sm btn-view"
            type="button"
            onClick={() => {
              onViewAll(
                products,
                title,
                ClientDetailSection.INTERESTED_PRODUCTS
              );
            }}
          >
            {t('view_all')} <BsFillCaretRightFill />
          </button>
        )}
      </h4>
      <div className="image-container">
        {products.map((p) => (
          <div key={p.id}>
            <img
              onClick={() => onClick(p)}
              src={p.previewImageUrl
                .replace('.jpg', '.png')
                .replace('.jpeg', '.png')}
            />
            <span>{p.modelCode}</span>
          </div>
        ))}
      </div>

      <style jsx>
        {`
          h4 {
            font-size: 1rem;
            margin-top: 20px;
            margin-bottom: 10px;
            letter-spacing: 5px;
            font-weight: 600;
            border-bottom: 1px dotted #777;
            display: flex;
            justify-content: space-between;
          }
          .btn-view {
            color: #999;
            margin-top: -6px;
          }
          :global(.image-container img) {
            height: 80px;
            cursor: pointer;
          }

          :global(.image-container) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            height: 110px;
            overflow: hidden;
          }

          :global(.image-container > div) {
            width: 80px;
            margin-bottom: 10px;
            text-align: center;
          }
          :global(.image-container span) {
            width: 100%;
            display: inline-block;
            text-align: center;
            font-size: 12px;
            letter-spacing: 1px;
            font-weight: 400;
          }

          .all-items {
            position: fixed;
            background: #fff;
            left: 350px;
            width: 350px;
            top: 0;
            bottom: 0;
            overflow: auto;
            color: #666;
          }

          :global(.SMPortrait) .all-items {
            left: 0 !important;
          }

          .items {
            position: relative;
            padding: 30px;
          }
        `}
      </style>
    </>
  );
};

export default ClientDetailInterestedProducts;
