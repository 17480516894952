import {
  SERVER_ERROR,
  CONNECTION_DROPPED_ERROR,
  OTHER_ERROR
} from './../../utils/constants';
import { logJitsiEvent } from '../../analytics';
import { WRONG_STATE } from '../../utils/constants';

const tryConnect = (connectionConfig: any) => {
  const connection = new JitsiMeetJS.JitsiConnection(
    null,
    undefined,
    connectionConfig
  );
  return new Promise((resolve, reject) => {
    connectionListener(connection, resolve, reject);
    connection.connect();
  });
};

const onWrongState = () => logJitsiEvent(WRONG_STATE, null);

const onConnectionDropped = () => logJitsiEvent(CONNECTION_DROPPED_ERROR, null);

const onServerError = (error) => logJitsiEvent(SERVER_ERROR, { error });

const onOtherError = (error) => logJitsiEvent(OTHER_ERROR, { error });

const connectionListener = (connection, resolve, reject) => {
  const JitsiConnectionErrors = JitsiMeetJS.errors.connection;
  const JitsiConnectionEvents = JitsiMeetJS.events.connection;
  const handleConnectionFailed = (error) => {
    reject(error);
  };
  const handleConnectionEstablished = () => {
    unsubscribe();
    resolve(connection);
  };
  const unsubscribe = () => {
    connection.removeEventListener(
      JitsiConnectionEvents.CONNECTION_ESTABLISHED,
      handleConnectionEstablished
    );
    connection.removeEventListener(
      JitsiConnectionEvents.CONNECTION_FAILED,
      handleConnectionFailed
    );
  };

  connection.addEventListener(
    JitsiConnectionEvents.CONNECTION_ESTABLISHED,
    handleConnectionEstablished
  );

  connection.addEventListener(
    JitsiConnectionEvents.CONNECTION_FAILED,
    handleConnectionFailed
  );
  const handleConnectionDisconnect = () => {
    console.log('CONNECTION DISCONNECTED');
    const listeners: {
      [key: string]: Function;
    } = {
      [JitsiConnectionEvents.CONNECTION_DISCONNECTED]:
        handleConnectionDisconnect,
      [JitsiConnectionEvents.WRONG_STATE]: onWrongState,
      [JitsiConnectionErrors.CONNECTION_DROPPED_ERROR]: onConnectionDropped,
      [JitsiConnectionErrors.SERVER_ERROR]: onServerError,
      [JitsiConnectionErrors.OTHER_ERROR]: onOtherError
    };
    for (const event in listeners) {
      connection.removeEventListener(event, listeners[event]);
    }
  };
  const listeners: {
    [key: string]: Function;
  } = {
    [JitsiConnectionEvents.CONNECTION_DISCONNECTED]: handleConnectionDisconnect,
    [JitsiConnectionEvents.WRONG_STATE]: onWrongState,
    [JitsiConnectionErrors.CONNECTION_DROPPED_ERROR]: onConnectionDropped,
    [JitsiConnectionErrors.SERVER_ERROR]: onServerError,
    [JitsiConnectionErrors.OTHER_ERROR]: onOtherError
  };
  for (const event in listeners) {
    connection.addEventListener(event, listeners[event]);
  }
};
export default tryConnect;
