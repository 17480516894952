import React from 'react';
import { IFile } from '../../interfaces';
import { BsX, BsPlus } from 'react-icons/bs';
import { RiVideoFill } from 'react-icons/ri';
import { AiOutlineReload } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { actionBrowseStorybookInMeeting } from '../../redux/actions';
import { isVideo } from '../../utils/file';
const VideoThumnail = () => {
  return (
    <div
      className="video-thumnail d-flex align-items-center justify-content-center"
      style={{
        width: '140px',
        height: '90px',
        backgroundColor: '#444'
      }}
    >
      <RiVideoFill size={24} color="fff" />{' '}
    </div>
  );
};

const FileItem = ({
  featureImage,
  onOpenFile,
  title
}: {
  featureImage: string;
  onOpenFile: () => void;
  title: string;
}) => {
  return (
    <>
      <div className="storybook-item" onClick={onOpenFile}>
        {featureImage ? (
          <div className="storybook-item__image">
            <img src={featureImage} alt={title} />
          </div>
        ) : (
          <VideoThumnail />
        )}
        <div className="storybook-item__title">{title}</div>
      </div>
      <style jsx>{`
        .storybook-item {
          display: flex;
          cursor: pointer;
          margin-top: 20px;
        }
        .storybook-item__image {
          width: 140px;
          flex-shrink: 0;
          height: auto;
        }
        .storybook-item__image img {
          width: 100%;
          height: 100%;
        }
        .storybook-item__title {
          flex: 1;
          margin-left: 20px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          max-height: 50px;
          text-align: left;
        }
      `}</style>
    </>
  );
};

function MeetingFileListV2({
  files = [],
  onOpenFile,
  onClose,
  onReload
}: {
  files: IFile[];
  onOpenFile: (file: IFile) => void;
  onClose: () => void;
  onReload: () => void;
}) {
  const dispatch = useDispatch();
  const openPresentations = () => {
    dispatch(actionBrowseStorybookInMeeting(true));
  };

  const renderFileList = React.useMemo(() => {
    if (files?.length !== 0)
      return files.map((file) => {
        const isVideoFile = isVideo(file.thumbnails?.[0]);
        return (
          <FileItem
            key={file.id}
            featureImage={isVideoFile ? '' : file.thumbnails?.[0]}
            title={file.title}
            onOpenFile={() => onOpenFile(file)}
          />
        );
      });
    else return <div className="no-file">No presentation</div>;
  }, [files]);
  return (
    <>
      <div className="presentation-container">
        <div className="presentation">
          <div className="header">
            <div className="title">
              <span className="icon"></span>
              <span>Presentation</span>
            </div>
            <div className="header-action">
              <button className="btn btn-reload" onClick={onReload}>
                <AiOutlineReload color="fff" size={20} />
              </button>
              <button className="btn btn-close" onClick={onClose}>
                <BsX color="fff" size={24} />
              </button>
            </div>
          </div>
          <div className="meeting-file-list">
            <div className="files">{renderFileList}</div>
          </div>
          <div className="action">
            <button className="btn" onClick={openPresentations}>
              <BsPlus color="fff" size={24} />
              <span>Open presentations</span>
            </button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .presentation-container {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
        .icon {
          display: block;
          width: 20px;
          position: relative;
          height: 20px;
          border: white solid 1px;
        }
        .icon::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 25%;
          border-right: white solid 1px;
        }
        .header-action {
          display: flex;
          align-items: center;
        }
        .btn.btn-reload {
          margin-left: 10px;
        }
        .presentation {
          display: flex;
          flex-direction: column;
          background-color: #000;
          color: white;
          padding: 10px 20px;
          gap: 20px;
          height: 100vh;
          max-width: 100%;
          width: 500px;
          height: 600px;
        }
        .presentation .title {
          display: flex;
          align-items: center;
        }
        .title span:last-child {
          margin-left: 10px;
        }
        .files ::global(.no-file) {
          font-size: 20px;
          padding: 40px 0px;
        }
        .header {
          display: flex;
          justify-content: space-between;
          border-bottom: #333 solid 0.5px;
        }
        .meeting-file-list {
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          flex: 1;
        }
        .action {
          border-top: #333 solid 0.5px;
          padding: 10px 0;
        }
        .files {
          display: flex;
          flex-direction: column;
        }
        .btn:hover {
          color: white;
        }
        .btn {
          padding-left: 0;
          display: flex;
          gap: 10px;
          align-items: center;
          color: white;
        }
      `}</style>
    </>
  );
}

export default MeetingFileListV2;
