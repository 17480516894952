import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import { IMainState, NotificationType } from '../../../interfaces';
import {
  actionApproveRaiseHand,
  actionRemoveRaiseHandInAlert,
  actionToggleRaiseHand,
  actionUnmuteParticipantAudioTrack
} from '../../../redux/actions';
import NotificationPopup from '../../NotificationPopup';
import { MeetingContext } from './../MeetingContext';
import {
  DID_APPROVE_RAISE_HAND,
  DID_REJECT_RAISE_HAND
} from './../../../utils/constants';
import { IconRaiseHand } from '../MeetingControls/MeetingIcons';

const MeetingRaiseHandNotification = () => {
  const dispatch = useDispatch();
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );

  const { room } = React.useContext(MeetingContext);
  const raiseHandApproved = meeting?.raiseHand?.approved;

  const participantIds = meeting?.raiseHand?.participantInAlert;
  const allowToAccept = meeting?.isPresenter;
  const getMessage = (id: string) => {
    if (id === room?.myUserId()) {
      return t('request_speak');
    } else {
      return `${
        meeting?.remoteUsers?.[id]?.displayName || ' A participant'
      } ${t('would_like_to_speak')}`;
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {raiseHandApproved && (
        <NotificationPopup
          type={NotificationType.SUCCESS}
          label={t('allowed_to_speak')}
          onClose={() => dispatch(actionApproveRaiseHand(''))}
          autoClose={true}
        />
      )}
      {participantIds?.map((participantId, index) => (
        <NotificationPopup
          key={participantId + index}
          type={allowToAccept ? NotificationType.SUCCESS : undefined}
          label={getMessage(participantId)}
          onOk={
            allowToAccept
              ? () => {
                  dispatch(actionApproveRaiseHand(participantId));
                  dispatch(actionToggleRaiseHand(participantId));
                  dispatch(
                    actionUnmuteParticipantAudioTrack({ participantId })
                  );
                  logEvent(DID_APPROVE_RAISE_HAND, DID_APPROVE_RAISE_HAND, {
                    participantId
                  });
                }
              : undefined
          }
          onCancel={
            allowToAccept
              ? () => {
                  dispatch(actionToggleRaiseHand(participantId));
                  logEvent(DID_REJECT_RAISE_HAND, DID_REJECT_RAISE_HAND, {
                    participantId
                  });
                }
              : undefined
          }
          okLabel={t('approve')}
          cancelLabel={t('reject')}
          onClose={
            !allowToAccept
              ? () => {
                  dispatch(actionRemoveRaiseHandInAlert(participantId));
                }
              : undefined
          }
          icon={<IconRaiseHand />}
          autoHide={allowToAccept ? true : false}
          autoClose={allowToAccept ? false : true}
        />
      ))}
    </>
  );
};

export default MeetingRaiseHandNotification;
