import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../i18n';
import { IMainState, JitsiLocalTrack } from '../../../interfaces';
import {
  actionLocalTrackVideoMuteDidChange,
  actionToggleEnlargeVideo,
  actionToggleShareScreen
} from '../../../redux/actions';
import { actionReplaceLocalTrackAsync } from '../../../redux/asyncActions';
import { meetingColor } from '../../../utils/meeting';
import MeetingButtonWithIcon from '../MeetingControls/MeetingButtonWithIcon';
import { IconShareScreen } from '../MeetingControls/MeetingIcons';
import {
  disposeTrackIfNeeded,
  getLocalAudioTrack,
  getLocalVideoTrack,
  getVideoStreamEffects,
  handleSetLocalTrack
} from '../utils/track';

function ShareScreenButton() {
  const dispatch = useDispatch();
  const meeting =
    useSelector((state: IMainState) => state.clientState?.meeting) || {};
  const isSharingScreen = meeting.localUser?.isSharingScreen;
  const currentCameraDevice = meeting.localUser?.activeDevices?.camera;
  const videoEffect = meeting?.localUser?.videoEffect?.effect;
  const isVideoMuted = meeting?.localUser?.videoMuted;
  const isPreviousMutedRef = React.useRef<boolean>(isVideoMuted);
  const currentCameraDeviceRef = React.useRef<string>(currentCameraDevice);

  const myId = meeting.localUser?.participantId;
  const enlargedParticipantId = meeting.layout?.enlargedVideoParticipantId;
  const isEnlarged = enlargedParticipantId === myId;

  const startShareScreen = async () => {
    JitsiMeetJS.createLocalTracks({
      devices: ['desktop'],
      facingMode: 'environment'
    })
      .then(async (tracks: JitsiLocalTrack[]) => {
        const screenVideoTrack = getLocalVideoTrack(tracks);
        const screenAudioTrack = getLocalAudioTrack(tracks);
        if (screenAudioTrack) disposeTrackIfNeeded(screenAudioTrack);
        if (screenVideoTrack) {
          screenVideoTrack.on(
            JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
            stopShareScreen
          );
          isPreviousMutedRef.current = isVideoMuted;
          currentCameraDeviceRef.current = currentCameraDevice;
          dispatch(actionLocalTrackVideoMuteDidChange(false));
          dispatch(actionReplaceLocalTrackAsync(screenVideoTrack));
          dispatch(actionToggleShareScreen(true));
        }
      })
      .catch((err) => {
        console.log('error when creating desktop stream', err);
      });
  };

  const stopShareScreen = async () => {
    const effects = await getVideoStreamEffects([videoEffect]);
    dispatch(actionLocalTrackVideoMuteDidChange(isPreviousMutedRef.current));
    dispatch(actionToggleShareScreen(false));
    handleSetLocalTrack(
      'video',
      currentCameraDeviceRef.current,
      JitsiMeetJS,
      {},
      dispatch,
      effects
    );
  };

  const onToggleShareScreen = React.useCallback(() => {
    isSharingScreen ? stopShareScreen() : startShareScreen();
  }, [isSharingScreen, isVideoMuted, currentCameraDevice]);

  React.useEffect(() => {
    const shouldToggleEnlarged =
      (isSharingScreen && !isEnlarged) || (!isSharingScreen && isEnlarged);
    if (meeting?.isPresenter && shouldToggleEnlarged) {
      dispatch(
        actionToggleEnlargeVideo({
          participantId: myId,
          shouldEnlarge: isSharingScreen
        })
      );
    }
  }, [isSharingScreen]);

  const { t } = useTranslation();
  return (
    <MeetingButtonWithIcon onClick={onToggleShareScreen}>
      <IconShareScreen
        fill={isSharingScreen ? meetingColor.success : 'white'}
      />
      <span>
        {isSharingScreen ? t('stop_share_screen') : t('share_screen')}
      </span>
    </MeetingButtonWithIcon>
  );
}

export default ShareScreenButton;
