import React from 'react';
import { IDeviceWarning } from '../../../interfaces';
import { useTranslation } from 'next-i18next';
import { BsX } from 'react-icons/bs';

function DeviceWarning({
  message,
  timeout,
  onClose
}: {
  message: IDeviceWarning;
  timeout?: number;
  onClose?: (id: string) => void;
}) {
  const { t } = useTranslation();
  const timeoutIdRef = React.useRef<any>();
  const close = () => {
    onClose(message.id);
    clearTimeout(timeoutIdRef.current);
  };
  React.useEffect(() => {
    if (timeout) {
      timeoutIdRef.current = setTimeout(close, timeout);
    }
  }, [timeout]);

  const description = t(message.descriptionKey);
  const title = t(message.titleKey);
  const onMouseEnter = () => {
    clearTimeout(timeoutIdRef.current);
  };
  const onMouseLeave = () => {
    timeoutIdRef.current = setTimeout(close, timeout);
  };
  return (
    <>
      <div
        className="device-warning-item"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <span className="title">{title}</span>
        <span className="desc">{description}</span>
        <button className="btn btn-close" onClick={close}>
          <BsX size={20} />
        </button>
      </div>
      <style jsx>
        {`
          .device-warning-item {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 20px;
            background-color: #fff;
            animation: slide-left 0.3s ease-in-out;
            width: 500px;
            max-width: calc(100vw - 50px);
            border-left: 5px solid orange;
            text-align: left;
            align-items: flex-start;
          }
          .btn-close {
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: transparent;
          }
          .title {
            font-size: 16px;
            font-weight: bold;
          }
          .desc {
            font-size: 14px;
            max-height: 200px;
            overflow: auto;
          }
          @keyframes slide-left {
            0% {
              transform: translateX(-100%);
              opacity: 0;
            }
            100% {
              transform: translateX(0);
            }
          }
        `}
      </style>
    </>
  );
}

export default DeviceWarning;
