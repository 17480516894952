import React from 'react';
import { BiCheck, BiX } from 'react-icons/bi';
import { logEvent } from '../analytics';
import { NotificationType } from '../interfaces';
import { DID_CLICK_NOTIFICATION_BUTTON } from '../utils/constants';
import { meetingColor } from '../utils/meeting';

interface Props {
  icon?: React.ReactNode;
  label?: string;
  onClose?: () => void;
  type?: NotificationType;
  onOk?: () => void;
  onCancel?: () => void;
  okLabel?: string;
  cancelLabel?: string;
  autoHide?: boolean;
  className?: string;
  autoClose?: boolean;
}

const NotificationPopup = ({
  icon,
  label,
  onClose,
  type,
  onOk,
  onCancel,
  okLabel,
  cancelLabel,
  autoHide,
  className,
  autoClose
}: Props) => {
  const getClassName = () => {
    switch (type) {
      case NotificationType.WARNING:
        return 'type-warning';
      case NotificationType.SUCCESS:
        return 'type-success';
      case NotificationType.INFO:
        return 'type-info';
      default:
        return '';
    }
  };

  const okButton = (
    <div
      className="btn btn-secondary btn-sm ntf-btn btn-ok"
      onClick={() => {
        onOk();
        logEvent(DID_CLICK_NOTIFICATION_BUTTON, DID_CLICK_NOTIFICATION_BUTTON, {
          button: okLabel || 'ok',
          label
        });
      }}
    >
      {okLabel || <BiCheck />}
      <style jsx>{`
        .btn {
          background: rgba(255, 255, 255, 0.4);
          border: 1px solid rgba(255, 255, 255, 0.6);
        }
        .btn:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      `}</style>
    </div>
  );
  const cancelButton = (
    <div
      className="btn btn-outline-light btn-sm ntf-btn btn-cancel"
      onClick={() => {
        onCancel();
        logEvent(DID_CLICK_NOTIFICATION_BUTTON, DID_CLICK_NOTIFICATION_BUTTON, {
          button: cancelLabel || 'cancel',
          label
        });
      }}
    >
      {cancelLabel || <BiX />}
    </div>
  );

  const closeButton = (
    <BiX
      color="white"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        onClose();
        logEvent(DID_CLICK_NOTIFICATION_BUTTON, DID_CLICK_NOTIFICATION_BUTTON, {
          button: 'close',
          label
        });
      }}
    />
  );

  const ref = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    if (autoHide || autoClose) {
      setTimeout(() => {
        ref?.current?.classList?.remove('show');
      }, 3000);
      setTimeout(() => {
        ref?.current?.classList?.add('hide');
      }, 3100);

      if (autoClose) {
        setTimeout(() => {
          onClose();
        }, 3400);
      }
    }
  }, [autoHide, autoClose]);

  return (
    <div
      ref={ref}
      className={`NotificationPopup ${className} ${getClassName()} show ${
        !icon && 'no-icon'
      }`}
    >
      <div className="ntf-content">
        {icon && <div className="ntf-icon">{icon}</div>}
        {label && <div className="ntf-label">{label}</div>}
        <div className="ntf-actions">
          {onOk && okButton}
          {onCancel && cancelButton}
          {onClose && !autoClose && closeButton}
        </div>
      </div>
      <style jsx global>{`
        .NotificationPopup {
          border-radius: 100px 0 0 100px;
          background: ${meetingColor.transparentDark};
          color: #fff;
          padding: 2px 5px 2px 2px;
          transform: translateX(0);
          transition: all 0.3s linear;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        .MD .NotificationPopup {
          padding: 5px;
        }

        .NotificationPopup.show {
          animation: slide-in 0.3s linear both;
          z-index: 2;
        }

        .NotificationPopup.hide {
          transform: translateX(100%);
          z-index: 1;
          background: ${meetingColor.transparentDark} !important;
        }
        .MD .NotificationPopup.hide {
          transform: translateX(calc(100% - 0px));
        }

        .notification-container.expanded .NotificationPopup.hide {
          color: #fff;
        }

        .NotificationPopup.hide {
          color: transparent;
        }

        .ntf-label {
          padding: 0 6px;
          font-size: 0.8em;
          text-align: left;
          line-height: 1;
        }
        .MD .ntf-label {
          font-size: 1em;
          padding: 0 10px;
        }
        .ntf-actions {
          display: flex;
        }
        .NotificationPopup .ntf-btn {
          border-radius: 100px;
          font-size: 0.8em;
          margin-left: 5px;
          text-transform: uppercase;
          line-height: 1;
        }

        .SM .NotificationPopup .ntf-btn {
          padding: 0;
          font-size: 0.7em;
          padding: 2px 5px;
        }

        .ntf-icon {
          width: 30px;
          height: 30px;
          border-radius: 100px;
          padding: 1px;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          flex-shrink: 0;
        }

        .status-user .ntf-icon svg {
          width: 20px;
        }

        .ntf-icon svg {
          margin: auto;
          width: 100%;
          height: auto;
        }
        .hide .ntf-icon {
          display: none;
          background: none;
        }
        .notification-container.expanded .hide .ntf-icon {
          display: flex;
        }
        .no-icon.type-warning,
        .type-warning .ntf-icon {
          background: ${meetingColor.warning};
        }
        .no-icon.type-success,
        .type-success .ntf-icon {
          background: #4ac300;
        }
        .no-icon.type-info,
        .type-info .ntf-icon {
          background: ${meetingColor.info};
        }
        .ntf-content {
          display: flex;
          align-items: center;
        }

        @keyframes slide-in {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(0);
          }
        }
      `}</style>
    </div>
  );
};

export default NotificationPopup;
