import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IMainState } from '../../interfaces';
import Search from './Search';
import DocumentViewerPopup from '../DocumentsViewer/DocumentViewerPopup';
import { mapBrowerLanguageToSBLanguage } from '../../utils/window';
import { TLanguage } from '../../mappers/polotno';
function StreamingStudioStorybook() {
  const isSearch = useSelector(
    (state: IMainState) => state.clientState?.vb?.sspStorybook?.open
  );

  const [browserLanguage, setBrowerLanguage] = useState<TLanguage>('en');
  useEffect(() => {
    setBrowerLanguage(mapBrowerLanguageToSBLanguage(navigator.language))
  },[])

  return (
    <>
      <div className="popup-page">
        {isSearch && <Search />}
        <DocumentViewerPopup  activateLanguage={browserLanguage}/>
      </div>
      <style jsx>{`
        .popup-page {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: transparent;
        }
      `}</style>
    </>
  );
}

export default StreamingStudioStorybook;
