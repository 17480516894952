import { BiChevronUp, BiStats } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../i18n';
import {
  IMeeting,
  IVirtualBoutiqueConfig,
  MeetingLayoutMode,
  MeetingRole
} from '../../../interfaces';
import {
  actionMuteParticipantAudioTrack,
  actionResyncMeeting,
  actionSetAudioOutput,
  actionUnmuteAllParticipantsAudioTrack
} from '../../../redux/actions';
import { logEvent } from '../../../analytics/index';
import {
  DID_CLICK_RESYNC_MEETING_BUTTON,
  DID_LOGIN_HUB_IN_MEETING,
  DID_MUTE_ALL_PARTICIPANTS,
  DID_SEARCH_FOR_STORYBOOK,
  DID_UNMUTE_ALL_PARTICIPANTS
} from '../../../utils/constants';
import MeetingButtonWithIcon from './MeetingButtonWithIcon';
import {
  IconMicrophoneOn,
  IconMicrophoneOff,
  IconCameraOn,
  IconRaiseHand,
  IconEndSession,
  IconCameraOff,
  IconLayout,
  IconScanStudio,
  IconInvite,
  IconMuteAllMic,
  IconUnmuteAllMic,
  IconNormalMode,
  IconMore,
  IconStudio,
  IconRefresh,
  IconStorybook,
  IconSpeaker,
  IconInvisible
} from './MeetingIcons';
import React from 'react';
import ToolTipWrapper from '../../Common/ToolTipWrapper';
import Microphones from '../Controls/Microphones';
import Cameras from '../Controls/Cameras';
import { meetingColor, onMeetingOverlay } from '../../../utils/meeting';
import { SessionContext } from '../../VirtualBoutique/SessionContextContainer';
import VirtualBackgroundButton from '../SelectVirtualBg/VirtualBackgroundButton';
import ShareScreenButton from '../PresenterVideo/ShareScreenButton';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import LiveChatButton from '../Chat/ChatButton';
import Speakers from '../Controls/Speakers';
import { getLocalVideoTrack, hasAvailableDevices } from '../utils/track';
import { usePageVisibility, useViewportInfo } from '../../hooks/ux';
import {
  actionBrowseStorybookInMeetingAsync,
  actionChangeInputDeviceAsync
} from '../../../redux/asyncActions';
import { useMeetingDebugLogger } from '../../hooks/meeting';

const MainControls = ({
  meeting,
  config,
  isLocalAudioMuted,
  isLocalVideoMuted,
  onClickAudio,
  onClickVideo,
  onStop,
  onToggleRaiseHand,
  onClickInvite,
  localUserEntitlement = [false, false, false],
  onClickScanStudio,
  onClickConnectStudio,
  onClickLayoutButton,
  onCloseMenu,
  isSmallScreen,
  onToggleStatsMode,
  onOpenDocument,
  advisorCustomControls = null
}: {
  meeting: IMeeting;
  config: IVirtualBoutiqueConfig;
  isLocalAudioMuted: boolean;
  isLocalVideoMuted: boolean;
  onClickAudio: () => void;
  onClickVideo: () => void;
  onStop: () => void;
  onToggleRaiseHand: () => void;
  onClickInvite: () => void;
  localUserEntitlement?: boolean[];
  onClickScanStudio: () => void;
  onClickConnectStudio?: () => void;
  onClickLayoutButton: () => void;
  onCloseMenu: () => void;
  onToggleStatsMode?: () => void;
  onOpenDocument?: () => void;
  isSmallScreen?: boolean;
  advisorCustomControls?: React.ReactNode;
}) => {
  const meetingId = meeting?.meetingId;
  const [canControlMeeting, isSourceOfMirrorActions] = localUserEntitlement;
  const [showMicOption, setShowMicOption] = React.useState(false);
  const [showSpeakerOption, setShowSpeakerOption] = React.useState(false);
  const [showCamOption, setShowCamOption] = React.useState(false);
  const [showMoreMenu, setShowMoreMenu] = React.useState(false);

  const localTracks = meeting.localUser?.tracks || [];
  const role = meeting?.localUser?.role;
  const isStudio = role === MeetingRole.STUDIO;
  const hasVideoTrack = localTracks.some((t) => t.getType() === 'video');
  const devices = meeting?.localUser?.availableDevices;
  const microphones = devices?.filter((d) => d.kind === 'audioinput') || [];
  const speakers = devices?.filter((d) => d.kind === 'audiooutput') || [];
  const cameras = devices?.filter((d) => d.kind === 'videoinput') || [];
  const localVideoTrack = React.useMemo(
    () => getLocalVideoTrack(localTracks),
    [localTracks]
  );
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isRaisingHand = (meeting?.raiseHand?.participantIds || []).includes(
    meeting.localUser.participantId
  );
  const logger = useMeetingDebugLogger();
  const isPageVisible = usePageVisibility();
  const { onMobile } = useViewportInfo();
  const isSharingScreen = meeting?.localUser?.isSharingScreen;
  const isOnPresenterMode = meeting?.localUser?.isOnPresenterMode;

  const { shouldMuteAudio, session } = React.useContext(SessionContext);
  const [muteAll, setMuteAll] = React.useState(shouldMuteAudio);

  const isDisabledCam = React.useMemo(() => {
    return !hasAvailableDevices(devices, 'videoinput');
  }, [devices]);

  const isDisableMic = React.useMemo(() => {
    return !hasAvailableDevices(devices, 'audioinput');
  }, [devices]);

  const shouldCloseMenu = () => {
    if (isSmallScreen && canControlMeeting) {
      onCloseMenu();
    }
  };

  const onResyncConference = () => {
    dispatch(actionResyncMeeting());
    logEvent(DID_CLICK_RESYNC_MEETING_BUTTON, DID_CLICK_RESYNC_MEETING_BUTTON, {
      meetingId
    });
  };

  const shouldShowMicOption = microphones?.length > 1;
  const languageCode = meeting?.languageCode;

  const toggleCameraIfNeeded = React.useCallback(
    (isPageVisible: boolean) => {
      if (!localVideoTrack) return; // no video track
      const isMuted = localVideoTrack.isMuted();
      if (!isPageVisible && !isMuted) {
        return localVideoTrack.mute();
      }
      if (isPageVisible && !isLocalVideoMuted && isMuted) {
        return localVideoTrack.unmute();
      }
    },
    [localVideoTrack, isLocalVideoMuted]
  );

  React.useEffect(() => {
    if (!onMobile) return;
    toggleCameraIfNeeded(isPageVisible);
  }, [onMobile, isPageVisible]);

  React.useEffect(() => {
    i18n.changeLanguage(languageCode);
  }, [languageCode]);

  const audio = (
    <div className="relative-wrapper">
      <MeetingButtonWithIcon
        id="meetingAudioControl"
        disabled={isDisableMic}
        className={`audio ${isLocalAudioMuted ? 'muted-warning' : ''}`}
        onClick={() => {
          onClickAudio();
          shouldCloseMenu();
        }}
      >
        {isLocalAudioMuted ? <IconMicrophoneOff /> : <IconMicrophoneOn />}
        <span>{isLocalAudioMuted ? 'Unmute My Mic' : 'Mute My Mic'}</span>
      </MeetingButtonWithIcon>

      {shouldShowMicOption && (
        <div
          className="popup-arrow mic-arrow"
          onClick={() => {
            setShowMicOption(!showMicOption);
            shouldCloseMenu();
          }}
        >
          <BiChevronUp color="white" />
        </div>
      )}
    </div>
  );
  const isVideoMuted = React.useMemo(
    () => (isSharingScreen ? !isOnPresenterMode : isLocalVideoMuted),
    [isSharingScreen, isOnPresenterMode, isLocalVideoMuted]
  );

  const camera = (
    <div className="relative-wrapper">
      <MeetingButtonWithIcon
        id="meetingVideoControl"
        disabled={isDisabledCam}
        className={`video ${isVideoMuted ? 'muted-warning' : ''}`}
        onClick={() => {
          onClickVideo();
          shouldCloseMenu();
        }}
      >
        {isVideoMuted ? <IconCameraOff /> : <IconCameraOn />}
        <span>{isVideoMuted ? 'Turn on My Cam' : 'Turn off My Cam'}</span>
      </MeetingButtonWithIcon>

      {cameras?.length > 1 && !isSharingScreen && (
        <div
          className="popup-arrow cam-arrow"
          onClick={() => {
            setShowCamOption(!showCamOption);
            shouldCloseMenu();
          }}
        >
          <BiChevronUp color="white" />
        </div>
      )}
    </div>
  );

  const speaker = (
    <div
      className="relative-wrapper speaker-options"
      onClick={() => {
        setShowSpeakerOption(!showSpeakerOption);
        shouldCloseMenu();
      }}
    >
      <MeetingButtonWithIcon id="meetingSpeakerControl">
        {<IconSpeaker fill="#fff" />}
        <span>Speakers</span>
      </MeetingButtonWithIcon>

      <div className="popup-arrow speaker-arrow">
        <BiChevronUp color="white" />
      </div>
    </div>
  );

  const meetingLayoutToggleButton = (
    <MeetingButtonWithIcon
      id="meetingLayoutControl"
      className="tile"
      onClick={() => {
        onClickLayoutButton();
        shouldCloseMenu();
      }}
    >
      {meeting?.layout?.mode === MeetingLayoutMode.TILE ? (
        <>
          <IconNormalMode />
          <span>{t('presentation_layout')}</span>
        </>
      ) : (
        <>
          <IconLayout />
          <span>{t('tile_layout')}</span>
        </>
      )}
    </MeetingButtonWithIcon>
  );

  const invitePeople = (
    <>
      <MeetingButtonWithIcon
        id="invitePeople"
        className="tile"
        onClick={() => {
          onClickInvite();
          shouldCloseMenu();
        }}
      >
        <IconInvite />
        <span>{t('invite_to_meeting')}</span>
      </MeetingButtonWithIcon>
    </>
  );

  const scanStudio = (
    <MeetingButtonWithIcon
      id="scanStudio"
      onClick={() => {
        onClickScanStudio();
        shouldCloseMenu();
      }}
    >
      <IconScanStudio />
      <span>{t('connect_studio')}</span>
    </MeetingButtonWithIcon>
  );

  const connectStudio = (
    <MeetingButtonWithIcon
      id="connectStudio"
      onClick={() => {
        onClickConnectStudio();
        shouldCloseMenu();
      }}
    >
      <IconStudio />
      <span>{t('connect_store_device')}</span>
    </MeetingButtonWithIcon>
  );

  const resyncConference = (
    <MeetingButtonWithIcon
      id="resyncButton"
      onClick={() => {
        onResyncConference();
        shouldCloseMenu();
      }}
    >
      <IconRefresh />
      <span>Resync Conference</span>
    </MeetingButtonWithIcon>
  );

  const overlayImage = (
    <MeetingButtonWithIcon
      id="overlayImageButton"
      onClick={() => {
        onMeetingOverlay(dispatch, meeting, !meeting?.isOverlayImgVisible);
        shouldCloseMenu();
      }}
    >
      <IconInvisible />
      <span>{meeting?.isOverlayImgVisible ? 'Hide ' : 'Show '}overlay</span>
    </MeetingButtonWithIcon>
  );

  const toggleStats = (
    <MeetingButtonWithIcon
      id="toggleStatsButton"
      onClick={() => {
        onToggleStatsMode();
        shouldCloseMenu();
      }}
    >
      <BiStats />
      <span>
        {meeting?.statsMode ? 'Turn off stats mode' : 'Turn on stats mode'}
      </span>
    </MeetingButtonWithIcon>
  );

  const onClickOpenDocument = () => {
    if (isSourceOfMirrorActions && session) {
      onOpenDocument();
      shouldCloseMenu();
      return;
    }
    const onSuccess = () =>
      logger(DID_SEARCH_FOR_STORYBOOK, DID_SEARCH_FOR_STORYBOOK);
    const onPopupOpen = () =>
      logger(DID_LOGIN_HUB_IN_MEETING, DID_LOGIN_HUB_IN_MEETING);
    dispatch(actionBrowseStorybookInMeetingAsync(onPopupOpen, onSuccess));
  };

  const openDocument = onOpenDocument ? (
    <MeetingButtonWithIcon
      id="openDocument"
      onClick={onClickOpenDocument}
      disabled={meeting?.isOverlayImgVisible}
    >
      <IconStorybook />
      <span>{t('open_storybook')}</span>
    </MeetingButtonWithIcon>
  ) : null;

  const muteAllParticipants = muteAll ? (
    <MeetingButtonWithIcon
      id="unmuteAllParticipants"
      className="muted-warning"
      onClick={() => {
        dispatch(actionUnmuteAllParticipantsAudioTrack());
        setMuteAll(false);
        logEvent(DID_UNMUTE_ALL_PARTICIPANTS, DID_UNMUTE_ALL_PARTICIPANTS, {
          meetingId
        });
        shouldCloseMenu();
      }}
    >
      <IconUnmuteAllMic />
      <span>{t('vb:unmute_all_users')}</span>
    </MeetingButtonWithIcon>
  ) : (
    <MeetingButtonWithIcon
      id="muteAllParticipants"
      onClick={() => {
        dispatch(actionMuteParticipantAudioTrack({ participantId: 'all' }));
        setMuteAll(true);
        logEvent(DID_MUTE_ALL_PARTICIPANTS, DID_MUTE_ALL_PARTICIPANTS, {
          meetingId
        });
        shouldCloseMenu();
      }}
    >
      <IconMuteAllMic />
      <span>{t('vb:mute_all_users')}</span>
    </MeetingButtonWithIcon>
  );

  const micOptions = (
    <ToolTipWrapper
      parentId="meetingAudioControl"
      width={250}
      onClose={() => setShowMicOption(false)}
      distanceFromParent={30}
    >
      <div className="text-left control-menu">
        <Microphones
          devices={microphones}
          onSelect={(devicedId: string) => {
            dispatch(actionChangeInputDeviceAsync(devicedId, 'audio'));
            setShowMicOption(false);
          }}
          activeDevice={meeting?.localUser?.activeDevices?.microphone || ''}
          allowChange={meeting?.localUser?.isInputChangeAvailable || false}
        />
      </div>
    </ToolTipWrapper>
  );

  const speakerOptions = (
    <ToolTipWrapper
      parentId="meetingSpeakerControl"
      width={250}
      onClose={() => setShowSpeakerOption(false)}
      distanceFromParent={30}
    >
      <div className="text-left control-menu">
        <Speakers
          devices={speakers}
          onSelect={(devicedId: string) => {
            dispatch(actionSetAudioOutput(devicedId));
            setShowSpeakerOption(false);
          }}
          activeDevice={meeting?.localUser?.activeDevices?.speaker || ''}
          allowChange={meeting?.localUser?.isOutputChangeAvailable || false}
        />
      </div>
    </ToolTipWrapper>
  );

  const cameraOptions = (
    <ToolTipWrapper
      parentId="meetingVideoControl"
      width={280}
      onClose={() => setShowCamOption(false)}
      distanceFromParent={30}
    >
      <div className="text-left control-menu">
        <Cameras
          devices={cameras}
          activeDevice={meeting?.localUser?.activeDevices?.camera || ''}
          allowChange={meeting?.localUser?.isInputChangeAvailable || false}
          onSelect={(deviceId: string) => {
            dispatch(actionChangeInputDeviceAsync(deviceId, 'video'));
            setShowCamOption(false);
          }}
        />
      </div>
    </ToolTipWrapper>
  );

  const raiseHand = (
    <MeetingButtonWithIcon
      className="raise-hand"
      onClick={() => {
        onToggleRaiseHand();
        shouldCloseMenu();
      }}
    >
      <IconRaiseHand fill={isRaisingHand ? meetingColor.success : 'white'} />
      <span>{t('raise_hand')}</span>
    </MeetingButtonWithIcon>
  );

  const moreButton = (
    <MeetingButtonWithIcon
      id="moreMenu"
      onClick={() => {
        setShowMoreMenu(!showMoreMenu);
      }}
    >
      <IconMore />
      <span>Show More</span>
    </MeetingButtonWithIcon>
  );

  const moreMenu = (
    <ToolTipWrapper
      parentId="moreMenu"
      width={280}
      onClose={() => setShowMoreMenu(false)}
      distanceFromParent={30}
    >
      <div className="control-menu">
        <button
          className="list-group-item list-group-item-action"
          onClick={() => {
            onClickInvite();
            setShowMoreMenu(false);
            shouldCloseMenu();
          }}
        >
          <IconInvite />
          <span>{t('invite_to_meeting')}</span>
        </button>
        {onClickConnectStudio && (
          <button
            className="list-group-item list-group-item-action connect-streaming-studio"
            onClick={() => {
              onClickConnectStudio();
              setShowMoreMenu(false);
              shouldCloseMenu();
            }}
          >
            <IconStudio />
            <span>{t('connect_store_device')}</span>
          </button>
        )}

        <button
          className="list-group-item list-group-item-action"
          onClick={() => {
            onClickScanStudio();
            setShowMoreMenu(false);
            shouldCloseMenu();
          }}
        >
          <IconScanStudio />
          <span>{t('connect_studio')}</span>
        </button>

        <button
          className="list-group-item list-group-item-action"
          onClick={() => {
            onResyncConference();
            setShowMoreMenu(false);
            shouldCloseMenu();
          }}
        >
          <IconRefresh />
          <span>Resync Conference</span>
        </button>
        {config?.overlay && (
          <button
            id="overlayButton"
            className="list-group-item list-group-item-action"
            onClick={() =>
              onMeetingOverlay(dispatch, meeting, !meeting?.isOverlayImgVisible)
            }
          >
            <IconInvisible />
            <span>
              {meeting?.isOverlayImgVisible ? 'Hide ' : 'Show '}overlay
            </span>
          </button>
        )}
        {onToggleStatsMode && (
          <button
            className="list-group-item list-group-item-action"
            onClick={() => {
              onToggleStatsMode();
              setShowMoreMenu(false);
              shouldCloseMenu();
            }}
          >
            <BiStats />
            <span>
              {meeting?.statsMode
                ? 'Turn off stats mode'
                : 'Turn on stats mode'}
            </span>
          </button>
        )}
      </div>
    </ToolTipWrapper>
  );

  const endSession = (
    <MeetingButtonWithIcon
      id="meetingEndButton"
      onClick={() => {
        onStop();
        shouldCloseMenu();
      }}
    >
      <IconEndSession />
      <span style={{ color: meetingColor.warning }}>{t('end_session')}</span>
    </MeetingButtonWithIcon>
  );

  const [isMobile, setIsMobile] = React.useState(true);

  React.useEffect(() => {
    setIsMobile(isUserOnMobile());
  }, []);

  return (
    <div className="MainControls">
      <div className="controls-group">
        <div className="group-main">
          <div className="group-item primary-group">
            <LiveChatButton callback={shouldCloseMenu} />
            {canControlMeeting && muteAllParticipants}
            {isSourceOfMirrorActions && advisorCustomControls}
            {openDocument}
            {config?.entitlement?.shareScreen && !isMobile && (
              <ShareScreenButton />
            )}
          </div>
          <div className="group-item secondary-group">
            {!isStudio && audio}
            {camera}
            {speakers?.length > 1 && speaker}
            {hasVideoTrack && (
              <VirtualBackgroundButton disabled={isSharingScreen} />
            )}
            {canControlMeeting && meetingLayoutToggleButton}
            {!canControlMeeting && raiseHand}
          </div>

          {isSourceOfMirrorActions && (
            <>
              <div className="group-item icon-only-group">{moreButton}</div>
              <div className="group-item slide-menu-only-group">
                {invitePeople}
                {onClickConnectStudio && connectStudio}
                {scanStudio}
                {resyncConference}
                {config?.overlay && overlayImage}
                {onToggleStatsMode && toggleStats}
              </div>
            </>
          )}
        </div>

        <div className="group-item last-group">{endSession}</div>
      </div>

      {shouldShowMicOption && showMicOption && micOptions}
      {showCamOption && cameraOptions}
      {showSpeakerOption && speakerOptions}
      {showMoreMenu && moreMenu}
      <style jsx global>{`
        .MainControls #meetingSpeakerControl {
          cursor: pointer;
        }
        .MainControls .controls-group {
          background: ${meetingColor.transparentDark2};
        }
        .SM.canControl .MainControls .controls-group {
          background: ${meetingColor.transparentDark};
        }
        .MainControls .relative-wrapper {
          position: relative;
        }
        .MainControls .popup-arrow {
          position: absolute;
          top: 50%;
          left: 0;
          top: 11px;
          padding: 3px;
        }
        .MainControls .meeting-button {
          border-radius: 100px;
        }
        .canControl .MainControls .popup-arrow {
          transform: rotate(-90deg);
        }
        .canControl .MainControls .popup-arrow svg {
          width: 22px;
          height: auto;
        }
        .client .MainControls .popup-arrow,
        .MD .MainControls .popup-arrow {
          cursor: pointer;
          left: auto;
          transform: rotate(0);
          padding: 0;
          right: 0;
          border-radius: 100px;
          width: 20px;
          height: 20px;
          line-height: 16px;
          top: -9px;
          background: #000;
          border: 1px solid #666;
          left: 36px;
          text-align: center;
        }
        .client .MainControls .popup-arrow:hover,
        .MD .MainControls .popup-arrow:hover {
          background: #666;
        }
        .client .MainControls .popup-arrow svg,
        .MD .MainControls .popup-arrow svg {
          width: 18px;
          height: auto;
          display: block;
        }
        .MD .muted-warning,
        .client .muted-warning {
          background-color: ${meetingColor.warning} !important;
        }
        .MDLandscape .meeting-button:hover span {
          color: #fff !important;
          display: block !important;
          position: absolute;
          top: -38px;
          background: ${meetingColor.transparentDark2};
          border-radius: 100px;
          padding: 0 10px 0;
          line-height: 2;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }
      `}</style>
    </div>
  );
};

export default MainControls;
