import { useTranslation } from '../../i18n';

const StudioDisconnected = () => {
  const { t } = useTranslation();
  return (
    <div className="streaming-stopped-container">
      <strong>{t('vb:streaming_stopped')}</strong>
      <style jsx>{`
        .streaming-stopped-container {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          text-transform: uppercase;
        }
      `}</style>
    </div>
  );
};
export default StudioDisconnected;
