import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent, logJitsiEvent } from '../../analytics';
import {
  boutiquesWithBoshDataChannel,
  inspifyBrandId,
  inspifyTestBrandId
} from '../../config';
import {
  DevicePermissionStatus,
  IDevicePermission,
  IMainState,
  IVirtualBoutiqueConfig,
  JitsiDevice,
  JitsiMeetingRoom,
  JitsiParticipant,
  MeetingRole,
  NudgeStatus,
  ParticipantMeetingState
} from '../../interfaces';
import {
  actionAddDevicesList,
  actionAdvisorMeetingInit,
  actionBridgeChannelClosed,
  actionBridgeChannelOpened,
  actionDidAddLocalTrack,
  actionDidAddRemoteTrack,
  actionDidChangeConnectionStatus,
  actionDidChangeDominantSpeaker,
  actionDidJoinMeeting,
  actionDidReceiveEndpointMessage,
  actionDidReceiveRemotePopupAction,
  actionDidRemoveLocalTrack,
  actionDidRemoveRemoteTrack,
  actionLocalTrackAudioMuteDidChange,
  actionLocalTrackVideoMuteDidChange,
  actionParticipantDidEnterFromLounge,
  actionParticipantDidJoinMeeting,
  actionParticipantDidLeaveMeeting,
  actionRemoteTrackAudioMuteDidChange,
  actionRemoteTrackVideoMuteDidChange,
  actionRequestAdvisorInfo,
  actionRequestParticipantUserInfo,
  actionSendAdvisorInfo,
  actionSendUserInfo,
  actionSetAudioOutput,
  actionSetInputChangeAvailable,
  actionSetIsMeetingPresenter,
  actionSetMeetingId,
  actionSetOutputChangeAvailable,
  actionUpdateMeetingServerUrl,
  actionUpdateMeetingStats,
  actionUpdateRemoteDisplayName,
  actionUpdateRemoteUserMeetingStats
} from '../../redux/actions';
import {
  actionLocalTrackDidStopAsync,
  actionToggleMuteLocalTrackAsync,
  actionUpdateNudgeStatusForClientAsync
} from '../../redux/asyncActions';
import {
  ADVISOR_DID_END_MEETING,
  AUDIO_INPUT_STATE_CHANGE,
  BRIDGE_CHANNEL_OPENED,
  CONFERENCE_DESTROYED,
  CONFERENCE_ERROR,
  CONFERENCE_FAILED,
  CONFERENCE_LEFT,
  CONFERENCE_MAX_USERS,
  CONFERENCE_UNIQUE_ID_SET,
  CONFERENCE_RESTARTED,
  DID_CHANGE_PARTICIPANT_CONN_STATUS,
  DID_ENTER_MEETING_FROM_LOUNGE,
  DID_JOIN_MEETING,
  DTMF_SUPPORT_CHANGED,
  GRACEFUL_SHUTDOWN,
  INCOMPATIBLE_SERVER_VERSIONS,
  KICKED,
  KICKED_OUT_FROM_MEETING,
  LAST_N_ENDPOINTS_CHANGED,
  NO_AUDIO_INPUT,
  PARTICIPANT_KICKED,
  RESERVATION_ERROR,
  STARTED_MUTED,
  START_MUTED_POLICY_CHANGED,
  SUBJECT_CHANGED,
  USER_ROLE_CHANGED,
  USER_STATUS_CHANGED,
  VIDEOBRIDGE_NOT_AVAILABLE,
  LOW_CONNECTION_QUALITY,
  AUDIO_OUTPUT,
  DID_SET_DEVICE_PERMISSION,
  OUTPUT_DEVICES_STORED
} from '../../utils/constants';
import { getMeetingRole } from '../../utils/meeting';
import { getJitsiRegionByRegionAndStore } from '../../utils/stores';
import {
  useHiResVideoParticipantIds,
  useMeetingDebugLogger
} from '../hooks/meeting';
import { SessionContext } from '../VirtualBoutique/SessionContextContainer';
import {
  isBrowserSupportedCockpit,
  isUserOniOS
} from './../../utils/deviceDetector';
import {
  getLoungeComponent,
  getWelcomeVideo
} from './../storeComponentFactory';
import { getConfigByRegion, getInitConfig } from './config';
import tryConnect from './connection';
import { MeetingContext } from './MeetingContext';
import StudioLounge from './StudioLounge';
import {
  createInitialLocalTracks,
  disposeTrackIfNeeded,
  getInitialDevices,
  getInitialEffects,
  getVideoStreamEffects
} from './utils/track';
import Cockpit from './Cockpit';
import Reconnect from './Reconnect';
import {
  BRIDGE_CHANNEL_CLOSED,
  DID_ATTACH_LOCAL_TRACKS,
  DID_CONNECT_TO_SERVER,
  DID_CREATE_LOCAL_TRACKS,
  DID_FAIL_TO_ATTACH_LOCAL_TRACKS,
  DID_FAIL_TO_CONNECT_TO_SERVER,
  DID_FAIL_TO_CREATE_LOCAL_TRACKS,
  DID_FAIL_TO_INIT_JITSI,
  DID_INIT_JITSI,
  DID_SEND_ADVISOR_INFO,
  DID_SEND_USER_INFO,
  LOCAL_TRACK_STOPPED
} from '../../utils/events/meeting';
import Disconnected from './Disconnected';
import GeneralBrowserPrompt from './BrowserPrompt/General';
import MeetingDeviceWarning from './MeetingDeviceWarning';
import { tryGetLocalStorage } from '../../utils/window';
export type JitsiTransport = 'bosh' | 'wss';
export type JitsiConnectionStatus =
  | 'active'
  | 'inactive'
  | 'interrupted'
  | 'restoring';

const CockpitContainer = ({
  storeId,
  vbConfig
}: {
  storeId: string;
  vbConfig: IVirtualBoutiqueConfig;
}) => {
  const router = useRouter();
  const meetingId = (router.query.meeting as string | undefined)?.toLowerCase();
  const role = router.query.role as string | undefined;
  const region = router.query.region as string | undefined;
  const isStudio = role === 'studio';
  const meetingRole = getMeetingRole(role);
  const source = router.query.source as string | undefined;
  const isClientFromNudge =
    source === 'nudge' && role !== 'advisor' && role !== 'studio';
  const dispatch = useDispatch();
  const [permissionStatus, setPermissionStatus] =
    React.useState<IDevicePermission>({
      video: DevicePermissionStatus.WAITING,
      audio: DevicePermissionStatus.WAITING
    });

  const {
    shouldMuteVideo: muteVideoFromSessionSetting,
    shouldMuteAudio: muteAudioFromSessionSetting
  } = React.useContext(SessionContext);

  const [isValidBrowserForCockpit, setIsValidBrowserForCockpit] =
    React.useState(true);

  const meetingDebugLogger = useMeetingDebugLogger();

  const studioVideoHeight = 1440;
  const clientState = useSelector(
    (state: IMainState) => state.clientState || {}
  );
  const meeting = clientState.meeting || {};
  const isReconnecting = React.useMemo(() => {
    const { isReconnect, isReconnected } = meeting?.reconnect || {};
    return isReconnect && isReconnected === false;
  }, [meeting.reconnect]);

  const { isPresenter, bridgeChannelOpened, advisorEndsMeeting } = meeting;
  const enteredMeetingFromLounge =
    meeting.state === ParticipantMeetingState.ENTERED_FROM_LOUNGE;

  const localUser = meeting?.localUser || {};
  const localTracks = localUser?.tracks || [];
  const kickedOut = localUser?.kickedOut;

  const activeDevices = localUser?.activeDevices;
  const isOutputChangeAvailable = localUser?.isOutputChangeAvailable || false;
  const shouldMuteVideo =
    (!isReconnecting &&
      muteVideoFromSessionSetting &&
      meetingRole !== MeetingRole.STUDIO) ||
    Boolean(localUser?.videoMuted);
  const shouldMuteAudio =
    (!isReconnecting && muteAudioFromSessionSetting) ||
    meetingRole === MeetingRole.STUDIO ||
    Boolean(localUser?.audioMuted) ||
    (source === 'medialaunch' && role !== 'advisor');
  const {
    connection,
    setConnection,
    room,
    setRoom,
    onDisconnect,
    networkState
  } = useContext(MeetingContext);

  const publishInitTracks = React.useCallback(
    (room: JitsiMeetingRoom) => {
      const roomLocalTrack = room.getLocalTracks();
      const promises = [];
      for (const track of localTracks) {
        if (roomLocalTrack.indexOf(track) !== -1) continue;
        if (track.isMuted()) {
          promises.push(
            disposeTrackIfNeeded(track).then(() => {
              console.log(
                `debug:: did remove ${track.getType()} local track in first publish`
              );
              dispatch(actionDidRemoveLocalTrack(track));
            })
          );
          continue;
        }
        promises.push(
          room
            .addTrack(track)
            .then(() => {
              console.log(`debug:: did add local ${track.getType()} track`);
              meetingDebugLogger(DID_ATTACH_LOCAL_TRACKS, {
                type: track.getType()
              });
            })
            .catch((e) => {
              console.error(
                `debug:: Failed to add local ${track.getType()}`,
                e
              );
              meetingDebugLogger(DID_FAIL_TO_ATTACH_LOCAL_TRACKS, {
                type: track.getType()
              });
            })
        );
      }
      return Promise.all(promises);
    },
    [localTracks]
  );

  React.useEffect(() => {
    if (bridgeChannelOpened) {
      // TODO: Separate source of mirroring actions from roles
      isPresenter ? advisorInit() : clientInit();
      meetingDebugLogger(BRIDGE_CHANNEL_OPENED);
    }
  }, [bridgeChannelOpened]);

  React.useEffect(() => {
    if (isClientFromNudge && enteredMeetingFromLounge) {
      dispatch(
        actionUpdateNudgeStatusForClientAsync(
          meetingId,
          NudgeStatus.JOINED_WALKTHROUGH
        )
      );
    }
    if (enteredMeetingFromLounge) {
      logEvent(DID_ENTER_MEETING_FROM_LOUNGE, DID_ENTER_MEETING_FROM_LOUNGE, {
        participantId: room?.myUserId(),
        meetingId,
        role: meetingRole
      });
      publishInitTracks(room);
    }
  }, [enteredMeetingFromLounge]);

  const participantsForHighResVideo = useHiResVideoParticipantIds();

  React.useEffect(() => {
    const constraints = participantsForHighResVideo.reduce((acc, id) => {
      acc[id] = {
        maxHeight: 1440
      };
      return acc;
    }, {});
    room?.setReceiverConstraints({
      constraints,
      defaultConstraints: {
        maxHeight: 360
      }
    });
  }, [participantsForHighResVideo]);

  React.useEffect(() => {
    if (room && meeting?.state) {
      room.setLocalParticipantProperty('meetingState', meeting?.state);
    }
  }, [room, meeting?.state]);

  const onTrackAdded = (track) => {
    console.log(
      `onTrackAdded local -  isLocal: ${track.isLocal()}, type - ${track.getType()}, id - ${track.getId()}`
    );
    if (track.isLocal()) {
      return;
    }
    dispatch(actionDidAddRemoteTrack(track));
    const action =
      track.getType() === 'audio'
        ? actionRemoteTrackAudioMuteDidChange
        : actionRemoteTrackVideoMuteDidChange;
    dispatch(action(track.getParticipantId(), track.isMuted()));
  };

  const onTrackRemoved = (track) => {
    console.log(
      `track removed!!! ${track} id - ${track.getId()} local?: ${track.isLocal()}`
    );
    if (track.isLocal()) {
      dispatch(actionDidRemoveLocalTrack(track));
    } else {
      dispatch(actionDidRemoveRemoteTrack(track));
    }
  };

  const advisorInit = () => {
    dispatch(actionRequestParticipantUserInfo());
    dispatch(actionSendAdvisorInfo());
    dispatch(actionAdvisorMeetingInit());
    meetingDebugLogger(DID_SEND_ADVISOR_INFO);
  };

  const clientInit = () => {
    dispatch(actionRequestAdvisorInfo());
    dispatch(actionSendUserInfo(meetingRole));
    meetingDebugLogger(DID_SEND_USER_INFO);
  };

  const onConferenceJoined = () => {
    console.log('conference joined!');
    dispatch(actionDidJoinMeeting(room?.myUserId(), meetingRole, room));
    const resolution = isStudio ? studioVideoHeight : 1080;
    room.setSenderVideoConstraint(resolution);
    logEvent(DID_JOIN_MEETING, DID_JOIN_MEETING, {
      participantId: room?.myUserId(),
      meetingId,
      role: meetingRole
    });
  };

  const onUserJoined = (participantId, user) => {
    console.log(
      `user join - ${participantId}, displayName - ${user.getDisplayName()}`
    );
    dispatch(
      actionUpdateRemoteDisplayName(participantId, user.getDisplayName())
    );
    dispatch(actionParticipantDidJoinMeeting(participantId, user));
    onUserPropertyChanged(user);
  };

  const onUserPropertyChanged = (user) => {
    const remoteMeetingState = user.getProperty('meetingState');
    if (remoteMeetingState) {
      logJitsiEvent('user property changed', {
        displayName: user?.getDisplayName(),
        meetingState: user?.getProperty('meetingState')
      });
    }
    if (remoteMeetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE) {
      dispatch(actionParticipantDidEnterFromLounge(user.getId()));
    }
  };
  const onUserLeft = (id) => {
    console.log(id + ' user left');
    dispatch(actionParticipantDidLeaveMeeting(id));
  };

  const onDisplayNameChanged = (participantId, displayName) => {
    console.log(`onDisplayNameChanged: ${participantId} - ${displayName}`);
    if (participantId !== room?.myUserId()) {
      dispatch(actionUpdateRemoteDisplayName(participantId, displayName));
    }
  };

  const onEndpointMessage = (_, payload) => {
    dispatch(actionDidReceiveEndpointMessage(payload));
  };

  const onTrackMuteChanged = (track) => {
    if (track.isLocal()) {
      return;
    }
    console.log(
      `${track.getType()} track mute changed to muted ${track.isMuted()}, for participant ${track.getParticipantId()}`
    );
    const action =
      track.getType() === 'video'
        ? actionRemoteTrackVideoMuteDidChange(
            track.getParticipantId(),
            track.isMuted()
          )
        : actionRemoteTrackAudioMuteDidChange(
            track.getParticipantId(),
            track.isMuted()
          );
    dispatch(action);
  };

  const onRemoteStatsUpdated = (participantId: string, stats: any) => {
    console.log(
      `onRemoteStatsUpdated for participantId ${participantId}`,
      stats
    );
    dispatch(actionUpdateRemoteUserMeetingStats(participantId, stats));
  };

  const onLocalStatsUpdated = (stats: any) => {
    console.log(`onLocalStatsUpdated`, stats);
    if (stats?.connectionQuality < 10) {
      logJitsiEvent(LOW_CONNECTION_QUALITY, {
        meetingId,
        participantId: room?.myUserId()
      });
    }
    dispatch(actionUpdateMeetingStats(stats));
  };

  const onKicked = () => {
    console.log('kicked from meeting');
    onDisconnect(KICKED_OUT_FROM_MEETING, meetingId);
  };

  const onDominantSpeakerChanged = (id: string) => {
    if (enteredMeetingFromLounge) {
      dispatch(actionDidChangeDominantSpeaker(id));
    }
  };

  const onParticipantStatusChanged = (
    participantId: string,
    status: JitsiConnectionStatus
  ) => {
    dispatch(actionDidChangeConnectionStatus({ participantId, status }));
    logEvent(
      DID_CHANGE_PARTICIPANT_CONN_STATUS,
      DID_CHANGE_PARTICIPANT_CONN_STATUS,
      { participantId, status }
    );
  };

  const onLastNEndpointChange = (
    leavingEndpointIds: string[],
    enteringEndpointIds: string[]
  ) =>
    logJitsiEvent(LAST_N_ENDPOINTS_CHANGED, {
      leavingEndpointIds,
      enteringEndpointIds
    });
  const onSubjectChange = (subject: string) =>
    logJitsiEvent(SUBJECT_CHANGED, { subject });
  const onConferenceLeft = () => logJitsiEvent(CONFERENCE_LEFT);
  const onConferenceUniqueIdSet = (meetingId: string) =>
    logJitsiEvent(CONFERENCE_UNIQUE_ID_SET, { meetingId });
  const onDMTFSupportChanged = (support: boolean) =>
    logJitsiEvent(DTMF_SUPPORT_CHANGED, { support });
  const onUserRoleChanged = (id: string, role: string) =>
    logJitsiEvent(USER_ROLE_CHANGED, { id, role });
  const onUserStatusChanged = (id: string, status: string) =>
    logJitsiEvent(USER_STATUS_CHANGED, { id, status });
  const onConferenceFailed = (errorCode: string) =>
    logJitsiEvent(CONFERENCE_FAILED, { errorCode });
  const onConferenceError = (errorCode: string) =>
    logJitsiEvent(CONFERENCE_ERROR, { errorCode });
  const onUserKicked = (actorParticipant: JitsiParticipant, reason: string) =>
    logJitsiEvent(KICKED, { actorParticipant, reason });
  const onParticipantKicked = (
    actorParticipant: JitsiParticipant,
    kickedParticipant: JitsiParticipant,
    reason: string
  ) =>
    logJitsiEvent(PARTICIPANT_KICKED, {
      actorParticipant,
      kickedParticipant,
      reason
    });
  const onStartMutedPolicyChanged = (policy: {
    audio: boolean;
    video: boolean;
  }) => logJitsiEvent(START_MUTED_POLICY_CHANGED, policy);
  const onStartedMuted = () => logJitsiEvent(STARTED_MUTED);
  const onNoAudioInput = () => logJitsiEvent(NO_AUDIO_INPUT, null);
  const onAudioInputStateChange = () =>
    logJitsiEvent(AUDIO_INPUT_STATE_CHANGE, null);
  const onRestarted = () => logJitsiEvent(CONFERENCE_RESTARTED, null);
  const onVideoBridgeNotAvailable = () =>
    logJitsiEvent(VIDEOBRIDGE_NOT_AVAILABLE, null);
  const onReservationError = () => logJitsiEvent(RESERVATION_ERROR, null);
  const onGracefulShutdown = () => logJitsiEvent(GRACEFUL_SHUTDOWN, null);
  const onJingleFatalError = (error) =>
    logJitsiEvent(INCOMPATIBLE_SERVER_VERSIONS, { error });
  const onConferenceDistroyed = () => logJitsiEvent(CONFERENCE_DESTROYED, null);
  const onConferenceMaxUser = () => logJitsiEvent(CONFERENCE_MAX_USERS, null);

  const onDataChannelOpen = () => {
    meetingDebugLogger(BRIDGE_CHANNEL_OPENED);
    dispatch(actionBridgeChannelOpened());
  };

  const onDataChannelClosed = (ev) => {
    meetingDebugLogger(BRIDGE_CHANNEL_CLOSED, {
      code: ev.code,
      reason: ev.reason
    });
    dispatch(actionBridgeChannelClosed());
  };

  const roomListener = (status: 'on' | 'off') => {
    const JitsiConferenceErrors = JitsiMeetJS.errors.conference;
    const JitsiConferenceEvents = JitsiMeetJS.events.conference;
    const JitsiConnectionQualityEvents = JitsiMeetJS.events.connectionQuality;
    const listeners: {
      [key: string]: Function;
    } = {
      [JitsiConferenceEvents.DATA_CHANNEL_CLOSED]: onDataChannelClosed,
      [JitsiConferenceEvents.DATA_CHANNEL_OPENED]: onDataChannelOpen,
      [JitsiConferenceEvents.PARTICIPANT_PROPERTY_CHANGED]:
        onUserPropertyChanged,
      [JitsiConferenceEvents.TRACK_REMOVED]: onTrackRemoved,
      [JitsiConferenceEvents.TRACK_ADDED]: onTrackAdded,
      [JitsiConferenceEvents.PARTICIPANT_CONN_STATUS_CHANGED]:
        onParticipantStatusChanged,
      [JitsiConferenceEvents.DOMINANT_SPEAKER_CHANGED]:
        onDominantSpeakerChanged,
      [JitsiConferenceEvents.CONFERENCE_JOINED]: onConferenceJoined,
      [JitsiConferenceEvents.USER_JOINED]: onUserJoined,
      [JitsiConferenceEvents.USER_LEFT]: onUserLeft,
      [JitsiConferenceEvents.DISPLAY_NAME_CHANGED]: onDisplayNameChanged,
      [JitsiConferenceEvents.ENDPOINT_MESSAGE_RECEIVED]: onEndpointMessage,
      [JitsiConferenceEvents.TRACK_MUTE_CHANGED]: onTrackMuteChanged,
      [JitsiConnectionQualityEvents.REMOTE_STATS_UPDATED]: onRemoteStatsUpdated,
      [JitsiConnectionQualityEvents.LOCAL_STATS_UPDATED]: onLocalStatsUpdated,
      [JitsiConferenceEvents.LAST_N_ENDPOINTS_CHANGED]: onLastNEndpointChange,
      [JitsiConferenceEvents.SUBJECT_CHANGED]: onSubjectChange,
      [JitsiConferenceEvents.CONFERENCE_LEFT]: onConferenceLeft,
      [JitsiConferenceEvents.CONFERENCE_UNIQUE_ID_SET]: onConferenceUniqueIdSet,
      [JitsiConferenceEvents.DTMF_SUPPORT_CHANGED]: onDMTFSupportChanged,
      [JitsiConferenceEvents.USER_ROLE_CHANGED]: onUserRoleChanged,
      [JitsiConferenceEvents.USER_STATUS_CHANGED]: onUserStatusChanged,
      [JitsiConferenceEvents.CONFERENCE_FAILED]: onConferenceFailed,
      [JitsiConferenceEvents.KICKED]: onUserKicked,
      [JitsiConferenceEvents.PARTICIPANT_KICKED]: onParticipantKicked,
      [JitsiConferenceEvents.START_MUTED_POLICY_CHANGED]:
        onStartMutedPolicyChanged,
      [JitsiConferenceEvents.STARTED_MUTED]: onStartedMuted,
      [JitsiConferenceEvents.NO_AUDIO_INPUT]: onNoAudioInput,
      [JitsiConferenceEvents.AUDIO_INPUT_STATE_CHANGE]: onAudioInputStateChange,
      [JitsiConferenceErrors.CONNECTION_ERROR]: onConferenceError,
      [JitsiConferenceErrors.CONFERENCE_RESTARTED]: onRestarted,
      [JitsiConferenceErrors.VIDEOBRIDGE_NOT_AVAILABLE]:
        onVideoBridgeNotAvailable,
      [JitsiConferenceErrors.RESERVATION_ERROR]: onReservationError,
      [JitsiConferenceErrors.GRACEFUL_SHUTDOWN]: onGracefulShutdown,
      [JitsiConferenceErrors.INCOMPATIBLE_SERVER_VERSIONS]: onJingleFatalError,
      [JitsiConferenceErrors.CONFERENCE_DESTROYED]: onConferenceDistroyed,
      [JitsiConferenceErrors.CONFERENCE_MAX_USERS]: onConferenceMaxUser
    };
    for (const event in listeners) {
      room[status](event, listeners[event]);
    }
  };

  const hasMeeting = !!meeting;
  React.useEffect(() => {
    if (!room || !hasMeeting) return;
    roomListener('on');
    return () => {
      room && roomListener('off');
    };
  }, [room, hasMeeting]);

  React.useEffect(() => {
    if (kickedOut) {
      onKicked();
    }
  }, [kickedOut]);

  const getConnectionTransport = () => {
    if (!boutiquesWithBoshDataChannel.includes(storeId)) {
      return 'wss';
    }
    return router.query.transport === 'wss' ? 'wss' : 'bosh';
  };

  const studioVideoConstraints = {
    video: {
      frameRate: {
        max: 60
      },
      height: {
        ideal: studioVideoHeight,
        max: studioVideoHeight,
        min: 720
      }
    }
  };

  const onDeviceChange = (devices: JitsiDevice[]) => {
    //update permission
    const newPermission = {
      audio: DevicePermissionStatus.REJECTED,
      video: DevicePermissionStatus.REJECTED
    };
    if (
      devices.some((device) => device.kind === 'audioinput' && device.deviceId)
    ) {
      newPermission.audio = DevicePermissionStatus.GRANTED;
    }
    if (
      devices.some((device) => device.kind === 'videoinput' && device.deviceId)
    ) {
      newPermission.video = DevicePermissionStatus.GRANTED;
    }
    setPermissionStatus(newPermission);
    dispatch(actionAddDevicesList(devices));
  };

  const initDeviceList = () => {
    const { mediaDevices } = JitsiMeetJS;
    const hasDevicesList = mediaDevices.isDeviceListAvailable() || false;
    const allowSwitchOutput = mediaDevices.isDeviceChangeAvailable('output');
    const selectedOutputDevices = tryGetLocalStorage(OUTPUT_DEVICES_STORED);

    //FIXME:: Attempt to fix it return false on iOS due to the term not allow use multiple input at once
    const allowSwitchInput =
      mediaDevices.isDeviceChangeAvailable('input') || isUserOniOS() || false;
    const activeAudioOutput = mediaDevices.getAudioOutputDevice() || '';

    if (hasDevicesList && (allowSwitchOutput || allowSwitchInput)) {
      mediaDevices.addEventListener(
        JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
        onDeviceChange
      );

      mediaDevices.enumerateDevices((devices) => {
        const deviceIds = Object.keys(devices).map((key) => {
          return devices[key].deviceId;
        });
        const selectedAudioOutput = deviceIds.includes(selectedOutputDevices) ? selectedOutputDevices : activeAudioOutput;
        dispatch(actionSetAudioOutput(selectedAudioOutput));
        dispatch(actionSetInputChangeAvailable(allowSwitchInput));
        dispatch(actionSetOutputChangeAvailable(allowSwitchOutput));
        onDeviceChange(devices);
      });
    }
  };

  const transport = getConnectionTransport();
  const jitsiRegion = getJitsiRegionByRegionAndStore(region, storeId);
  const jitsiConfig = jitsiRegion
    ? getConfigByRegion(meetingId, transport, jitsiRegion)
    : getInitConfig(meetingId, transport, storeId);
  const initMeeting = async () => {
    try {
      JitsiMeetJS.init(jitsiConfig);
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
      meetingDebugLogger(DID_INIT_JITSI);
    } catch (e) {
      meetingDebugLogger(DID_FAIL_TO_INIT_JITSI, { error: e });
      console.log('error invoking JitsiMeetJS init');
      console.log(e);
    }
  };

  const joinMeeting = async (meetingId: string) => {
    try {
      dispatch(actionSetMeetingId(meetingId));
      initMeeting();
      const requestVideo = !shouldMuteVideo;
      const requestAudio = !shouldMuteAudio;
      const initialDevices = [
        requestVideo && 'video',
        requestAudio && 'audio'
      ].filter(Boolean);
      const devices = getInitialDevices() || {};
      const videoEffect = getInitialEffects(dispatch);
      const effects = await getVideoStreamEffects([videoEffect]);
      const facingMode = isStudio ? 'environment' : 'user';
      const constraints = isStudio ? studioVideoConstraints : undefined;
      const { tryCreateLocalTracks, errors } = createInitialLocalTracks({
        initialDevices,
        ...devices,
        constraints,
        effects,
        facingMode
      });
      const tracks = await tryCreateLocalTracks;
      console.log('debug:: created tracks', tracks, errors);
      meetingDebugLogger(DID_CREATE_LOCAL_TRACKS, {
        error: errors
      });
      dispatch(actionDidAddLocalTrack(tracks));
      const permission = {
        video: DevicePermissionStatus.REJECTED,
        audio: DevicePermissionStatus.REJECTED
      };
      tracks.forEach((t) => {
        t.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => {
          meetingDebugLogger(LOCAL_TRACK_STOPPED, {
            type: t.getType()
          });
          dispatch(actionLocalTrackDidStopAsync(t));
        });
        if (t.isVideoTrack()) {
          permission.video = DevicePermissionStatus.GRANTED;
        } else if (t.isAudioTrack()) {
          permission.audio = DevicePermissionStatus.GRANTED;
        }
      });
      Object.keys(permission).forEach((key) => {
        const action =
          key === 'video'
            ? actionLocalTrackVideoMuteDidChange
            : actionLocalTrackAudioMuteDidChange;
        dispatch(action(permission[key] === DevicePermissionStatus.REJECTED));
      });
      if (Object.keys(errors).length > 0) {
        meetingDebugLogger(DID_FAIL_TO_CREATE_LOCAL_TRACKS, { errors });
      }
      setPermissionStatus(permission);
      meetingDebugLogger(DID_SET_DEVICE_PERMISSION, {
        ...permission
      });
      initDeviceList();
      return tryConnect(jitsiConfig)
        .then((con) => {
          setConnection(con);
          meetingDebugLogger(DID_CONNECT_TO_SERVER);
          dispatch(actionUpdateMeetingServerUrl(jitsiConfig.hosts?.domain));
        })
        .catch(() => {
          meetingDebugLogger(DID_FAIL_TO_CONNECT_TO_SERVER);
        });
    } catch (error) {
      console.log('error joining meeting', error);
    }
  };

  React.useEffect(() => {
    if (activeDevices?.speaker && isOutputChangeAvailable)
      JitsiMeetJS.mediaDevices.setAudioOutputDevice(activeDevices?.speaker);
    //store audio output in window obj for iframe to get initial speaker device
    window[AUDIO_OUTPUT] = activeDevices?.speaker || '';
    //send current speaker to iframe
    dispatch(
      actionDidReceiveRemotePopupAction(
        actionSetAudioOutput(activeDevices?.speaker)
      )
    );
  }, [activeDevices?.speaker]);

  React.useEffect(() => {
    const isValidBrowser =
      navigator?.mediaDevices?.getUserMedia && isBrowserSupportedCockpit();
    setIsValidBrowserForCockpit(isValidBrowser);
  }, []);

  React.useEffect(() => {
    if (!isValidBrowserForCockpit) {
      return;
    }

    dispatch(actionSetIsMeetingPresenter(role === 'advisor'));
    if (!meetingId) {
      return;
    }

    if (isClientFromNudge) {
      dispatch(
        actionUpdateNudgeStatusForClientAsync(
          meetingId,
          NudgeStatus.AWAITING_WALKTHROUGH
        )
      );
    }

    joinMeeting(meetingId);
    const visibilityChangeListener = () => {
      if (!isClientFromNudge) {
        return;
      }
      if (enteredMeetingFromLounge) {
        return;
      }
      if (document.visibilityState === 'visible') {
        dispatch(
          actionUpdateNudgeStatusForClientAsync(
            meetingId,
            NudgeStatus.AWAITING_WALKTHROUGH
          )
        );
      } else {
        dispatch(
          actionUpdateNudgeStatusForClientAsync(
            meetingId,
            NudgeStatus.LEFT_WALKTHROUGH
          )
        );
      }
    };
    document.addEventListener('visibilitychange', visibilityChangeListener);
    return () => {
      document.removeEventListener(
        'visibilitychange',
        visibilityChangeListener
      );
    };
  }, [isValidBrowserForCockpit]);

  const joinRoom = async () => {
    if (!room) {
      const room = connection.initJitsiConference(meetingId, jitsiConfig);
      room.setLocalParticipantProperty(
        'meetingState',
        ParticipantMeetingState.IN_THE_LOUNGE
      );
      room.join();
      window['room'] = room;
      setRoom(room);
    }
  };

  React.useEffect(() => {
    if (advisorEndsMeeting && meetingRole !== MeetingRole.ADVISOR) {
      onDisconnect(ADVISOR_DID_END_MEETING, meetingId);
    }
  }, [advisorEndsMeeting]);

  const toggleMuteTrack = React.useCallback(
    (type: 'video' | 'audio', _ = true) => {
      console.log('debug:: dispatch actionToggleMuteLocalTrackAsync');
      dispatch(actionToggleMuteLocalTrackAsync(type));
    },
    []
  );
  const shouldJoinRoomInstantly =
    meetingRole === MeetingRole.ADVISOR || isReconnecting;
  React.useEffect(() => {
    if (connection && shouldJoinRoomInstantly) joinRoom();
  }, [connection, shouldJoinRoomInstantly]);

  const getLoungeByRole = (role) =>
    role === 'studio' ? (
      <StudioLounge
        config={vbConfig}
        permissionStatus={permissionStatus}
        isBrowserSupported={isValidBrowserForCockpit}
        joinRoom={joinRoom}
      />
    ) : (
      getLoungeComponent(
        vbConfig,
        storeId,
        joinRoom,
        permissionStatus,
        isValidBrowserForCockpit,
        (mute: boolean) => {
          toggleMuteTrack('video', mute);
        }
      )
    );

  const getContent = () => {
    if (meeting.state === ParticipantMeetingState.SHOW_WELCOME_VIDEO) {
      return null;
    }
    if (!isValidBrowserForCockpit) return <GeneralBrowserPrompt />;
    if (networkState?.offerReload)
      return <Disconnected logoUrl={vbConfig.logoUrl || ''} />;
    if (enteredMeetingFromLounge) {
      return <Cockpit storeId={storeId} config={vbConfig} />;
    }
    if (isReconnecting) {
      return <Reconnect />;
    }
    return getLoungeByRole(role);
  };
  const videoFullSize =
    vbConfig.brandId === inspifyTestBrandId ||
    vbConfig.brandId === inspifyBrandId;
  return (
    <>
      {getContent()}
      <MeetingDeviceWarning />
      {vbConfig.meetingVideo?.welcomeVideoUrl &&
        getWelcomeVideo(
          vbConfig.meetingVideo?.welcomeVideoUrl,
          isPresenter && vbConfig.meetingVideo?.allowSkip,
          videoFullSize
        )}
    </>
  );
};

export default CockpitContainer;
