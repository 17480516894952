import React from 'react';
import CountDown from 'react-countdown';
import { assetBaseUrl } from '../../../../config';
import LoubiAirwaysPageContainer from './LoubiAirwaysPageContainer';

const asset = `${assetBaseUrl}/loubiairways/pages/v2`;
const CountDownPage = ({
  endDate,
  children,
  onEnded
}: {
  endDate: Date;
  children?: React.ReactNode;
  onEnded: () => void;
}) => {
  React.useEffect(() => {
    if (!endDate || new Date() >= endDate) {
      onEnded();
    }
  }, []);

  const renderNumber = (num: number, label: string) => {
    let str = `${num}`;
    if (num / 10 < 1) {
      str = `0${num}`;
    }
    return (
      <div className="box noselect">
        <img src={`${asset}/timebox.png`} />
        <div className="timebox">
          <div className="label">{label}</div>
          <div className="number">
            {str[0]}
            {str[1]}
          </div>
        </div>
        <style jsx>{`
          .box {
            display: inline-block;
            text-align: center;
            position: relative;
          }
          .timebox {
            position: absolute;
            font-family: 'AntiqueOlive-Bold', Arial;
            top: 0;
            color: #61b0c1;
            text-align: center;
            left: 20%;
            right: 20%;
            top: 50%;
            transform: translateY(-50%);
          }
          .label {
            margin-bottom: 10%;
          }
          .number {
            border-top: 1px solid #61b0c1;
            font-size: 4em;
          }
          .box img {
            width: 100%;
          }
          .timebox {
            font-size: 20px;
          }
          .box {
            width: 16%;
            margin: 0 2%;
          }
          @media (max-width: 1200px) {
            .timebox {
              font-size: 1.5vw;
            }
          }
          @media (max-width: 990px) {
            .box {
              width: 21%;
              margin: 0 1%;
            }
          }
        `}</style>
      </div>
    );
  };
  const renderer = ({ days, hours, minutes, seconds }) => (
    <div className="timer-container text-center">
      {renderNumber(days, 'DAYS')} {renderNumber(hours, 'HOURS')}{' '}
      {renderNumber(minutes, 'MINUTES')} {renderNumber(seconds, 'SECONDS')}
    </div>
  );

  return (
    <>
      <LoubiAirwaysPageContainer>
        <div className="countdown">
          <CountDown
            zeroPadDays={2}
            zeroPadTime={2}
            date={endDate}
            renderer={renderer}
            onComplete={() => {
              onEnded();
            }}
          />
          {children}
          <style jsx>{`
            .countdown {
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 40vh;
              max-height: 500px;
            }
            @media (orientation: portrait) {
              .countdown {
                max-height: none !important;
                height: 50vh;
              }
            }
          `}</style>
        </div>
      </LoubiAirwaysPageContainer>
    </>
  );
};

export default CountDownPage;
