import {
  getProdHostForRegion,
  getProdHostForStore,
  getStagingHostForRegion,
  getStagingHostForStore,
  isProd,
  JitsiServerRegion
} from '../../config';
import { JitsiTransport } from './CockpitContainer';

const getHostForStore = (isProd: boolean, storeId?: string) => {
  return isProd
    ? getProdHostForStore(storeId)
    : getStagingHostForStore(storeId);
};

const getHostForRegion = (isProd: boolean, region: JitsiServerRegion) => {
  return isProd
    ? getProdHostForRegion(region)
    : getStagingHostForRegion(region);
};

const getMeetingConfig = (host: string) => {
  return {
    hosts: {
      domain: host,
      muc: `conference.${host}`
    },
    bosh: `https://${host}/http-bind`,
    websocket: `wss://${host}/xmpp-websocket`,
    openBridgeChannel: 'websocket',
    e2eping: {
      enabled: false,
      numRequests: 5,
      maxConferenceSize: 200,
      maxMessagesPerSecond: 250
    },
    clientNode: 'http://jitsi.org/jitsimeet',
    testing: {
      p2pTestMode: false,
      capScreenshareBitrate: 0
    },
    p2p: {
      enabled: false,
      enableUnifiedOnChrome: true,
      backToP2PDelay: 5
    },
    enableLayerSuspension: true,
    enableNoAudioDetection: true,
    enableNoisyMicDetection: true,
    enableUnifiedOnChrome: true,
    desktopSharingFrameRate: {
      min: 15,
      max: 30
    },
    videoQuality: {
      disabledCodec: 'H264',
      preferredCodec: 'VP8',
      enforcePreferredCodec: 'VP8',
      maxBitratesVideo: {
        VP8: {
          low: 200000,
          standard: 500000,
          high: 1500000
        },
        VP9: {
          low: 100000,
          standard: 300000,
          high: 1200000
        }
      }
    },
    audioQuality: {
      stereo: true
    },
    desktopSharingFirefoxDisabled: false,
    disableAudioLevels: false,
    channelLastN: 10,
    lastNLimits: {
      5: 10,
      20: 7,
      30: 5,
      60: 3
    },
    startAudioMuted: true,
    startWithAudioMuted: true,
    startVideoMuted: true,
    startWithVideoMuted: true,
    videobackgroundblur: true,
    useStunTurn: true,
    useNewBandwidthAllocationStrategy: true,
    disableSimulcast: false,
    enableWelcomePage: true,
    enableUserRolesBasedOnToken: false,
    analytics: {},
    deploymentInfo: {},
    makeJsonParserHappy: 'even if last key had a trailing comma',
    applicationName: 'INSPIFY Meet',
    confID: `${host}/`
  };
};

const updateConfigByTransport = (
  config: any,
  transport: JitsiTransport,
  meetingId
) => {
  return transport === 'wss'
    ? {
        ...config,
        confID: `${config.confID}${meetingId}`,
        serviceUrl: `${config.websocket}?room=${meetingId}`,
        bosh: undefined
      }
    : {
        ...config,
        confID: `${config.confID}${meetingId}`,
        serviceUrl: `${config.bosh}?room=${meetingId}`,
        websocket: undefined,
        openBridgeChannel: true
      };
};

export const getInitConfig = (
  meetingId: string,
  transport: JitsiTransport,
  storeId?: string
) => {
  const host = getHostForStore(isProd, storeId);
  const config = getMeetingConfig(host);
  return updateConfigByTransport(config, transport, meetingId);
};

export const getConfigByRegion = (
  meetingId: string,
  transport: JitsiTransport,
  region: JitsiServerRegion
) => {
  const host = getHostForRegion(isProd, region);
  const config = getMeetingConfig(host);
  return updateConfigByTransport(config, transport, meetingId);
};

export default getMeetingConfig;
