import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RiShutDownLine } from 'react-icons/ri';

import { useTranslation } from '../../../i18n';
import {
  DID_LEAVE_MEETING,
} from '../../../utils/constants';
import { IMainState } from '../../../interfaces';

import { MeetingContext } from '../MeetingContext';
import LocalVideo from '../MeetingVideos/LocalVideo';

const StudioMeetingControl = () => {
  const { t } = useTranslation();
  const { onDisconnect } = React.useContext(MeetingContext);
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );

  const onStop=() => {
    onDisconnect(DID_LEAVE_MEETING, meeting.meetingId);
  };

  return(
    <Fragment>
      <div className="enlarge-video-container">
        <LocalVideo />
      </div>
      <button 
        className="quit-button" 
        onClick={onStop}>
        <RiShutDownLine />
        <span className="btn-text">{t('vb:stop_streaming')}</span>
      </button>
      <style jsx global>
        {`
          .enlarge-video-container {
            background: #000;
            position: fixed;
            display: flex;
            align-items: center;
            top: 0;
            left: 0;
          }
          .enlarge-video-container,
          .enlarge-video-container .enlarge,
          .enlarge-video-container video {
            width: 100vw !important;
            height: 100vh !important;
          }
          .quit-button {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9999;
            color: rgb(255,113,113);
            border-radius: 5px;
            background: rgba(255,255,255,0.85);
            padding: 5px 10px;
          }
          .btn-text{
            letter-spacing: 2px !important;
            font-size: 0.9em;
            font-weight: 500;
            direction: ltr !important;
            margin:0 4px;
          }
          .quit-button :global(svg) {
            width: auto;
            height: 22px;
          }
        `}
        </style>
    </Fragment>
  );
};

export default StudioMeetingControl;
