import { first } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';
import { getSessionStartEndTime } from '../../../utils/session';
import Placeholder from '../../Placeholder';
import {
  SessionContext,
  SessionLoadingState
} from '../../VirtualBoutique/SessionContextContainer';

const SessionCountDownPage = ({
  children,
  render
}: {
  children: React.ReactNode;
  render: (endDate?: Date, onEnded?: () => void) => JSX.Element;
}) => {
  const { session, sessionLoadingStatus } = React.useContext(SessionContext);
  const [ended, setEnded] = React.useState(false);
  const router = useRouter();

  if (ended) {
    return <>{children}</>;
  }

  if (sessionLoadingStatus === SessionLoadingState.FAILED) {
    return <>{children}</>;
  }

  if (
    router.query.mode !== 'cockpit' ||
    router.query.source !== 'session' ||
    router.query.role === 'advisor'
  ) {
    return <>{children}</>;
  }

  if (sessionLoadingStatus === SessionLoadingState.LOADING) {
    return <Placeholder />;
  }

  if (session?.eventCountdownPage) {
    return render(first(getSessionStartEndTime(session)), () => setEnded(true));
  }
  return <>{children}</>;
};

export default SessionCountDownPage;
