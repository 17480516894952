import React from 'react';
import { NotificationType } from '../../../interfaces';
import NotificationPopup from '../../NotificationPopup';
import { useTranslation } from '../../../i18n';
const MeetingKickedOutNotification = ({ show }: { show: boolean }) => {
  const [showNotification, setShowNotification] = React.useState(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (show) {
      setShowNotification(true);
    }
  }, [show]);
  return (
    <>
      {showNotification && (
        <div className="notification-container">
          <NotificationPopup
            type={NotificationType.WARNING}
            label={t('vb:kicked_out_from_meeting')}
          />
          <style jsx>{`
            .notification-container {
              position: fixed;
              bottom: 5px;
              right: 5px;
              text-align: right;
              z-index: 99999;
            }
          `}</style>
        </div>
      )}
    </>
  );
};
export default MeetingKickedOutNotification;
