import React from 'react';
import { useSelector } from 'react-redux';
import { IMainState, IProduct } from '../../interfaces';
import { BsFillCaretRightFill } from 'react-icons/bs';
import { ClientDetailSection } from './ClientDetail';
import { useTranslation } from '../../i18n';

const ClientDetailWishlist = ({
  title,
  onClick,
  onViewAll
}: {
  title: string;
  onClick: (product: IProduct) => void;
  onViewAll: (
    products: IProduct[],
    title: string,
    type: ClientDetailSection
  ) => void;
}) => {
  const selectedParticipantId = useSelector(
    (state: IMainState) =>
      state.clientState.meeting?.clientDetailPanelParticipantId
  );
  const remoteUsers = useSelector(
    (state: IMainState) => state.clientState.meeting?.remoteUsers
  );
  const products =
    (remoteUsers && remoteUsers[selectedParticipantId]?.wishlistProducts) || [];

  const { t } = useTranslation();

  return (
    <>
      <h4>
        <span>{title}</span>

        {!!products.length && products.length > 3 && (
          <button
            className="btn btn-sm btn-view"
            type="button"
            onClick={() => {
              onViewAll(products, title, ClientDetailSection.WISHLIST);
            }}
          >
            {t('view_all')} <BsFillCaretRightFill />
          </button>
        )}
      </h4>

      <div className="image-container">
        {products.map((p) => (
          <div key={p.id}>
            <img
              onClick={() => onClick(p)}
              src={p.previewImageUrl
                .replace('.jpg', '.png')
                .replace('.jpeg', '.png')}
            />
            <span>{p.modelCode}</span>
          </div>
        ))}
      </div>

      <style jsx>
        {`
          h4 {
            font-size: 1rem;
            margin-top: 20px;
            margin-bottom: 10px;
            letter-spacing: 5px;
            font-weight: 600;
            border-bottom: 1px dotted #777;
            display: flex;
            justify-content: space-between;
          }
          .btn-view {
            color: #999;
            margin-top: -6px;
          }
          :global(.image-container img) {
            height: 80px;
            cursor: pointer;
          }

          :global(.image-container) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            height: 110px;
            overflow: hidden;
          }

          :global(.image-container > div) {
            width: 80px;
            margin-bottom: 10px;
            text-align: center;
          }
          :global(.image-container span) {
            width: 100%;
            display: inline-block;
            text-align: center;
            font-size: 12px;
            letter-spacing: 1px;
            font-weight: 400;
          }

          .items {
            position: relative;
            padding: 30px;
          }
        `}
      </style>
    </>
  );
};

export default ClientDetailWishlist;
