import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../i18n';
import {
  IMainState,
  MeetingRole,
  NotificationType,
  ParticipantMeetingState
} from '../../../interfaces';
import { actionDidAdmitLateComerToMeeting } from '../../../redux/actions';
import { DID_ADMIT_USER_INTO_MEETING } from '../../../utils/constants';
import { getCurrentHost, performNativeNotify } from '../../../utils/window';
import {
  useLocalUserMeetingEntitlement,
  useMeetingDebugLogger
} from '../../hooks/meeting';
import NotificationPopup from '../../NotificationPopup';
import { IconInvite } from '../MeetingControls/MeetingIcons';

const MeetingNewJoinerNotification = () => {
  const dispatch = useDispatch();

  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const [canControlMeeting] = useLocalUserMeetingEntitlement();
  const [shownIds, setShowIds] = React.useState<string[]>([]);
  const [isWindowFocused, setIsWindowFocused] = React.useState(true);
  const remoteUsers = meeting?.remoteUsers || {};

  const pendingUserIds = Object.keys(remoteUsers).filter(
    (participantId) =>
      remoteUsers[participantId].shouldShowVideo === false &&
      !!remoteUsers[participantId].role &&
      remoteUsers[participantId]?.connectionStatus !== 'interrupted'
  );
  const meetingDebugLogger = useMeetingDebugLogger();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
      return;
    }
    if (Notification.permission !== 'denied' && canControlMeeting) {
      Notification.requestPermission();
    }
    const onFocus = () => setIsWindowFocused(true);
    const onBlur = () => setIsWindowFocused(false);
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [canControlMeeting]);

  const showNativeNotificationIfNeeded = (id: string) => {
    if (
      canControlMeeting &&
      !isWindowFocused &&
      !shownIds.includes(id) &&
      ![MeetingRole.STUDIO, MeetingRole.ADVISOR].includes(remoteUsers[id]?.role)
    ) {
      const body = `${remoteUsers[id]?.displayName || t('a_guest')} ${t(
        'has_joined_lounge'
      )}`;
      const notification = performNativeNotify({
        title: `New joiner`,
        body,
        icon: `${getCurrentHost()}/asset/advisor/icon_invite.png`
      });
      notification?.addEventListener?.('click', () => {
        window.focus();
        notification.close();
      });
      setShowIds((ids) => [...ids, id]);
    }
  };

  React.useEffect(() => {
    pendingUserIds.forEach(showNativeNotificationIfNeeded);
    pendingUserIds
      .filter((participantId) => {
        return remoteUsers[participantId]?.role === MeetingRole.STUDIO;
      })
      .forEach((participantId) => {
        dispatch(actionDidAdmitLateComerToMeeting(participantId));
      });
  }, [pendingUserIds]);

  if (
    !canControlMeeting ||
    meeting.state !== ParticipantMeetingState.ENTERED_FROM_LOUNGE ||
    pendingUserIds.length === 0
  )
    return null;

  return (
    <>
      {pendingUserIds.length > 1 && (
        <NotificationPopup
          onCancel={() => {
            pendingUserIds.forEach((id) => {
              dispatch(actionDidAdmitLateComerToMeeting(id));
              meetingDebugLogger(DID_ADMIT_USER_INTO_MEETING, {
                admittedParticipantId: id,
                displayName: remoteUsers[id]?.displayName
              });
            });
          }}
          cancelLabel={t('admit_all')}
          className="admit-all"
          type={NotificationType.SUCCESS}
        />
      )}
      {pendingUserIds
        .filter(
          (participantId) =>
            remoteUsers[participantId]?.role !== MeetingRole.STUDIO &&
            remoteUsers[participantId]?.role !== MeetingRole.ADVISOR
        )
        .map((participantId) => (
          <NotificationPopup
            key={participantId}
            label={`${
              remoteUsers[participantId]?.displayName || t('a_guest')
            } ${t('has_joined_lounge')}`}
            onOk={() => {
              dispatch(actionDidAdmitLateComerToMeeting(participantId));
              meetingDebugLogger(DID_ADMIT_USER_INTO_MEETING, {
                admittedParticipantId: participantId,
                displayName: remoteUsers[participantId]?.displayName
              });
            }}
            okLabel={t('admit')}
            icon={<IconInvite />}
            type={NotificationType.SUCCESS}
            className="admit-user"
          />
        ))}
    </>
  );
};

export default MeetingNewJoinerNotification;
