import {
  actionCloseSSPStorybook,
  actionSetActiveFile,
  actionSetActivePage,
  actionSetFileController
} from './../../redux/actions';
import { AnyAction, Dispatch } from 'redux';
import { isEmbeddedInStreamingStudio } from '.';
import { IFile, ISSPStorybookState, Source } from '../../interfaces';
import {
  actionCloseVBPopup,
  actionOpenSSPStorybook,
  actionOpenVBPopup
} from '../../redux/actions';
import { getCurrentHost } from '../window';
import { first } from 'lodash';
import { handlePresenterEndpointMessage } from '../../components/Meeting/Messaging/Presenter/PresenterCommandReceiver';

export const NATIVE_TO_JS = 'NATIVE_TO_JS';

export enum NativeCommand {
  LAUNCH_PRODUCT_SEARCH = 'LAUNCH_PRODUCT_SEARCH',
  LAUNCH_PRODUCT_COMPARE = 'LAUNCH_PRODUCT_COMPARE',
  LAUNCH_STORY_SEARCH = 'LAUNCH_STORY_SEARCH',
  LAUNCH_STORYBOOK_SEARCH = 'LAUNCH_STORYBOOK_SEARCH',
  LAUNCH_STORYBOOK_VIEWER = 'LAUNCH_STORYBOOK_VIEWER',
  NAVIGATE_STORYBOOK_PAGE = 'NAVIGATE_STORYBOOK_PAGE',
  RELAY_ACTION = 'RELAY_ACTION',
  CLOSE_POPUP = 'CLOSE_POPUP'
}

const launchProductSearch = (
  dispatch: Dispatch<AnyAction>,
  brandId: string,
  storeId: string
) => {
  dispatch(
    actionOpenVBPopup(
      `${getCurrentHost()}/productsearch/${brandId}?source=${
        Source.Showroom
      }&store=${storeId}`,
      true
    )
  );
};

const launchProductCompare = (
  dispatch: Dispatch<AnyAction>,
  brandId: string,
  storeId: string
) => {
  dispatch(
    actionOpenVBPopup(
      `${getCurrentHost()}/productsearch/${brandId}?compare=true&source=${
        Source.Showroom
      }&store=${storeId}`,
      true
    )
  );
};

const launchStorySearch = (
  dispatch: Dispatch<AnyAction>,
  brandId: string,
  storeId: string
) => {
  dispatch(
    actionOpenVBPopup(
      `${getCurrentHost()}/storysearch/${brandId}?source=${
        Source.Showroom
      }&store=${storeId}`,
      true
    )
  );
};

const navigateStorybookPage = (dispatch: Dispatch<AnyAction>, payload: any) => {
  const activePage = payload?.attributes?.activePage || {};
  const { currentPage, nextUrl, prevUrl } = activePage;
  dispatch(actionSetActivePage(currentPage, nextUrl, prevUrl));
};

const launchStorybookSearch = (
  dispatch: Dispatch<AnyAction>,
  payload: ISSPStorybookState
) => {
  const sessionId = payload.sessionId;
  const type = payload.type;
  const brandId = payload.brandId;
  if (sessionId && brandId && type) {
    dispatch(
      actionOpenSSPStorybook({
        sessionId,
        brandId
      })
    );
  }
};

const launchStorybookViewer = (
  dispatch: Dispatch<AnyAction>,
  payload: {
    file: IFile;
    participantId: string;
  }
) => {
  const file = payload.file;
  const participantId = payload.participantId;
  if (file && participantId) {
    dispatch(actionSetActiveFile(file.id));
    dispatch(
      actionSetActivePage({
        id: file.id,
        file: file.title,
        url: first(file.pages),
        setting: first(file.pagesSetting),
        silence: first(file.silences)
      })
    );
    dispatch(actionSetFileController(participantId));
  }
};

const handleRelayAction = (dispatch, payload) => {
  handlePresenterEndpointMessage(payload, dispatch, {}, true);
};

const closePopup = (
  dispatch: Dispatch<AnyAction>,
  type: 'storybook' | 'general'
) => {
  if (type === 'storybook') {
    dispatch(actionCloseSSPStorybook());
    dispatch(actionSetActiveFile(''));
    dispatch(actionSetActivePage(null));
  } else {
    dispatch(actionCloseVBPopup());
  }
};

const initNativeEventListener = (
  dispatch: Dispatch<AnyAction>,
  brandId: string,
  storeId: string
) => {
  if (!isEmbeddedInStreamingStudio()) {
    return;
  }
  const nativeEventListener = (ev?: CustomEvent) => {
    let eventType = ev?.detail;
    let eventPayload;
    if (ev?.detail && typeof ev?.detail === 'object') {
      eventType = ev.detail.name;
      eventPayload = ev.detail.payload;
    }
    console.log(eventType, eventPayload);
    switch (eventType) {
      case NativeCommand.LAUNCH_PRODUCT_SEARCH:
        launchProductSearch(dispatch, brandId, storeId);
        break;
      case NativeCommand.LAUNCH_PRODUCT_COMPARE:
        launchProductCompare(dispatch, brandId, storeId);
        break;
      case NativeCommand.LAUNCH_STORY_SEARCH:
        launchStorySearch(dispatch, brandId, storeId);
        break;
      case NativeCommand.LAUNCH_STORYBOOK_SEARCH:
        launchStorybookSearch(dispatch, eventPayload || {});
        break;
      case NativeCommand.LAUNCH_STORYBOOK_VIEWER:
        launchStorybookViewer(dispatch, eventPayload || {});
        break;
      case NativeCommand.RELAY_ACTION:
        handleRelayAction(dispatch, eventPayload || {});
        break;
      case NativeCommand.NAVIGATE_STORYBOOK_PAGE:
        navigateStorybookPage(dispatch, eventPayload);
        break;
      case NativeCommand.CLOSE_POPUP:
        closePopup(dispatch, eventPayload);
        break;
      default:
        break;
    }
  };

  window.removeEventListener(NATIVE_TO_JS, nativeEventListener);
  window.addEventListener(NATIVE_TO_JS, nativeEventListener);
};

export default initNativeEventListener;
