import { IStoreDevice } from "../interfaces";

export const mapAssociatedDevicesToRegionAndCountry = (associatedDevices: IStoreDevice[]): { [key: string]: string[] } => {
    const regions: { [key: string]: string[] } = {};
    associatedDevices.forEach(device => {
        const region = device.location?.region;
        const country = device.location?.country;
        if (region && regions[region]) {
            !regions[region].includes(country) && regions[region].push(country);
        }
        else regions[region] = [country];
    });
    return regions
}