import { useRouter } from 'next/router';
import React from 'react';
import {
  inspifySiteId,
  inspifyTestingSiteId,
  isChopardWW2021,
  isLoubiAirways,
  iwcWW2021Id,
  loubiFutureSiteId,
  isRDULite,
  rogerDubuisWholeSaleId,
  rogerDubuisRetailId,
  rogerDubuisWW2021Id,
  inspifyGCCSiteId,
  inspifyJPSilkRoadSiteId
} from '../config';
import { IVirtualBoutiqueConfig } from '../interfaces';
import SessionCountDownPage from './Meeting/Session/SessionCountDownPage';
import ChopardWW2021CountDown from './VirtualBoutique/CustomComponent/ChopardWW21CustomComponent/ChopardWW21CountDown';
import IWCWW2021CountDown from './VirtualBoutique/CustomComponent/IWCCustomComponent/IWCWW2021CountDown';
import LoubiCountDownPage from './VirtualBoutique/CustomComponent/LouboutinCustomComponent/CountDownPage';
import RDUWW21CountDown from './VirtualBoutique/CustomComponent/RDUWW21CustomComponent/RDUWW21CountDown';
import MeetingCountDown from './VirtualBoutique/MeetingCountDown';
import LFCountDown from './VirtualBoutique/CustomComponent/LoubiFutureCustomComponent/LFCountDown';

const CountDownContainer = ({
  storeId,
  children,
  config
}: {
  storeId: string;
  children: React.ReactNode;
  config: IVirtualBoutiqueConfig;
}) => {
  const router = useRouter();
  if (router.query.nocountdown) {
    return <>{children}</>;
  }

  return (
    <SessionCountDownPage
      render={(endDate, onEnded) => {
        const darkSiteIds = [
          inspifySiteId,
          inspifyTestingSiteId,
          inspifyGCCSiteId,
          inspifyJPSilkRoadSiteId
        ];
        if (isChopardWW2021(storeId)) {
          return <ChopardWW2021CountDown endDate={endDate} onEnded={onEnded} />;
        }
        if (isLoubiAirways(storeId)) {
          return <LoubiCountDownPage endDate={endDate} onEnded={onEnded} />;
        }

        if (
          isRDULite(storeId) ||
          [
            rogerDubuisWholeSaleId,
            rogerDubuisRetailId,
            rogerDubuisWW2021Id
          ].includes(storeId)
        ) {
          return <RDUWW21CountDown endDate={endDate} onEnded={onEnded} />;
        }
        switch (storeId) {
          case iwcWW2021Id:
            return <IWCWW2021CountDown endDate={endDate} onEnded={onEnded} />;

          case loubiFutureSiteId:
            return (
              <LFCountDown
                endDate={endDate}
                onEnded={onEnded}
                healthCheck={true}
              />
            );
          default:
            return (
              <MeetingCountDown
                endDate={endDate}
                onEnded={onEnded}
                storeId={storeId}
                logoUrl={config?.logoUrl}
                lightLogoUrl={config?.lightLogoUrl}
                dark={darkSiteIds.includes(storeId)}
                backgroundUrl={config?.countdownBg}
                brandId={config?.brandId}
                backgroundUrlMobile={config?.vbLite?.bannerImageUrlMobile}
              />
            );
        }
      }}
    >
      {children}
    </SessionCountDownPage>
  );
};

export default CountDownContainer;
