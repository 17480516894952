import { useDispatch } from 'react-redux';
import { logEvent } from '../../analytics';
import {
  actionUpdateStudioVideoZoomLevel,
  actionUpdateStudioVideoRotateDeg
} from '../../redux/actions';
import {
  DID_CLICK_STUDIO_GRID_BUTTON,
  DID_CLICK_STUDIO_ROTATE_BUTTON,
  DID_CLICK_STUDIO_ZOOM_BUTTON
} from '../../utils/constants';
import MeetingButtonWithIcon from './MeetingControls/MeetingButtonWithIcon';
import { meetingColor } from './../../utils/meeting';
import {
  IconZoomOut,
  IconZoomIn,
  IconRotateLeft,
  IconRotateRight,
  IconGridLine
} from './MeetingControls/MeetingIcons';
import { zIndex } from '../../config';

const AdvisorStudioControl = ({
  studioParticipantId,
  meetingId,
  zoomLevel,
  rotateDeg,
  showGrid,
  toggleShowGrid = () => null
}: {
  studioParticipantId: string;
  meetingId?: string;
  zoomLevel?: number;
  rotateDeg?: number;
  showGrid?: boolean;
  toggleShowGrid?: () => void;
}) => {
  const dispatch = useDispatch();
  const handleClickZoom = (zoom: number) => {
    dispatch(
      actionUpdateStudioVideoZoomLevel(studioParticipantId, zoomLevel + zoom)
    );
    logEvent(DID_CLICK_STUDIO_ZOOM_BUTTON, DID_CLICK_STUDIO_ZOOM_BUTTON, {
      zoomLevel: zoomLevel + zoom,
      meetingId,
      studioParticipantId
    });
  };

  const handleClickRotate = (degree: number) => {
    const updatedDeg = rotateDeg + degree;
    const payloadDeg =
      updatedDeg === 360 || updatedDeg === -360 ? 0 : updatedDeg;
    dispatch(actionUpdateStudioVideoRotateDeg(studioParticipantId, payloadDeg));
    logEvent(DID_CLICK_STUDIO_ROTATE_BUTTON, DID_CLICK_STUDIO_ROTATE_BUTTON, {
      rotateDeg: payloadDeg,
      meetingId,
      studioParticipantId
    });
  };

  const handleClickToggleGrid = () => {
    toggleShowGrid();
    logEvent(DID_CLICK_STUDIO_GRID_BUTTON, DID_CLICK_STUDIO_GRID_BUTTON, {
      showGrid: !showGrid,
      meetingId,
      studioParticipantId
    });
  };

  const zoomInButton = (
    <MeetingButtonWithIcon
      id="zoomIn"
      onClick={() => {
        handleClickZoom(0.5);
      }}
      disabled={zoomLevel === 2}
    >
      <IconZoomIn />
    </MeetingButtonWithIcon>
  );

  const zoomOutButton = (
    <MeetingButtonWithIcon
      id="zoomOut"
      onClick={() => {
        handleClickZoom(-0.5);
      }}
      disabled={zoomLevel === 1}
    >
      <IconZoomOut />
    </MeetingButtonWithIcon>
  );

  const rotateLeftButton = (
    <MeetingButtonWithIcon
      id="rotateLeft"
      onClick={() => {
        handleClickRotate(-90);
      }}
    >
      <IconRotateLeft />
    </MeetingButtonWithIcon>
  );

  const rotateRightButton = (
    <MeetingButtonWithIcon
      id="rotateRight"
      onClick={() => {
        handleClickRotate(90);
      }}
    >
      <IconRotateRight />
    </MeetingButtonWithIcon>
  );

  const gridLineButton = (
    <MeetingButtonWithIcon id="toggleGrid" onClick={handleClickToggleGrid}>
      <IconGridLine fill={showGrid ? meetingColor.success : 'white'} />
    </MeetingButtonWithIcon>
  );

  return (
    <>
      <div
        className="studio-control"
      >
        <div className="studio-control-group">
          {zoomInButton}
          <div className="control-value zoom-value text-center">x{zoomLevel}</div>
          {zoomOutButton}
        </div>
        <div className="studio-control-group">
          {rotateLeftButton}
          <div
            className="control-value rotate-value text-center"
            style={{ paddingLeft: '2px' }}
          >
            {rotateDeg || 0}&deg;
          </div>
          {rotateRightButton}
        </div>
        <div className="studio-control-group">{gridLineButton}</div>
      </div>
      <style jsx>
        {`
          .studio-control {
            background: ${meetingColor.transparentDark2};
            border-radius: 100px;
            position: fixed;
            z-index: ${zIndex.meetingControls};
            right: 10px;
            padding: 5px;
            top: 50%;
            transform: translateY(-50%);
          }

          .studio-control-group {
            border-bottom: 1px solid ${meetingColor.border};
          }
          .studio-control-group:last-child {
            border: none;
          }
          .studio-control :global(.meeting-button) {
            display: block;
            padding: 8px 0;
          }
          .studio-control :global(.meeting-button svg) {
            width: 34px;
            height: auto;
          }

          .control-value {
            font-size: 0.8em;
            color: #fff;
            user-select: none;
          }
        `}
      </style>
    </>
  );
};

export default AdvisorStudioControl;
