import React from 'react';
import {
  IMainState,
  ISessionDetails,
  IVirtualBoutiqueConfig,
  MeetingRole
} from '../../interfaces';
import { headerPropsForVirtualBoutique } from '../../utils/htmlHeader';
import HTMLHeader from '../HTMLHeader';
import { useSelector } from 'react-redux';
import { useLocalUserMeetingEntitlement } from '../hooks/meeting';
import Script from 'next/script';
const HTMLMeetingHeader = ({
  config,
  children,
  session,
  storeId
}: {
  config: IVirtualBoutiqueConfig;
  children?: React.ReactNode;
  session?: ISessionDetails;
  storeId?: string;
}) => {
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );

  const remoteUsers = meeting.remoteUsers || {};

  const waitingGuest = Object.keys(remoteUsers).filter(
    (participantId) =>
      remoteUsers[participantId]?.ready &&
      !remoteUsers[participantId]?.shouldShowVideo &&
      !!remoteUsers[participantId]?.role &&
      remoteUsers[participantId]?.role !== MeetingRole.STUDIO &&
      remoteUsers[participantId]?.connectionStatus !== 'interrupted'
  ).length;

  const [_, isSourceOfMirrorActions] = useLocalUserMeetingEntitlement();

  const headerProps = headerPropsForVirtualBoutique(config, session);

  return (
    <>
      <Script
        src="/libs/jquery-3.5.1.min.js"
        strategy="beforeInteractive"
      ></Script>
      <Script
        src="/libs/lib-jitsi-meet.min.js"
        strategy="beforeInteractive"
      ></Script>
      {!!meeting?.localUser?.participantId && (
        <Script
          src="/libs/initBoomerang.js"
          data-participantId={meeting.localUser.participantId}
          data-meetingId={meeting.meetingId}
          strategy="afterInteractive"
        ></Script>
      )}
      <HTMLHeader
        {...headerProps}
        title={`${
          isSourceOfMirrorActions && waitingGuest
            ? '(' + waitingGuest + ') '
            : ''
        }${headerProps.title}`}
        storeId={storeId}
      >
        {children}
      </HTMLHeader>
    </>
  );
};

export default HTMLMeetingHeader;
