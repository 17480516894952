import React from 'react';
import { useDispatch } from 'react-redux';
import {
  actionDeleteMeetChat,
  actionSendMeetChat
} from '../../../redux/actions';

import { getNow } from '../../../utils/clock';
import { generateV4UUID } from '../../../utils/identityGenerator';
import { logEvent } from '../../../analytics';
import {
  DID_CLICK_CHAT_SEND_MESSAGE,
  DID_CLICK_DELETE_CHAT_MESSAGE,
  DID_QUOTE_CHAT_MESSAGE,
  DID_HIDE_CHAT_PANEL,
  DID_SHOW_CHAT_PANEL
} from '../../../utils/constants';
import {
  ChatParticipant,
  handleChatParticipant,
  IMeetingChat,
  IMeetingChatBody,
  loadChatHistory,
  MessageForBubble
} from './chatUtils';
import { getChatHistoryByMeetingId } from './chatUtils/chatStorage';
import {
  generateParticipantColorIdentifier,
  mapChatMessage
} from './chatUtils/chatMapper';
import { wrapActionToBeMirroring } from '../../../utils/meeting';
import MeetChat from '.';

interface MeetWrapperProps {
  meetingId?: string;
  chat?: IMeetingChat[];
  isPresenter?: boolean;
  participants?: ChatParticipant[];
  children: React.ReactNode;
  myParticipantId?: string;
}

export interface IMeetChatContext {
  messages: MessageForBubble[];
  isPresenter?: boolean;
  openChatPanel?: boolean;
  toggleChatPanel?: (open: boolean) => void;
  scrollTo?: string;
  setScrollTo?: (selector: string) => void;
  participants?: ChatParticipant[];
  myParticipantInfo?: ChatParticipant;
  colorIdentifier?: {
    [key: string]: string;
  };
  unreadMessages?: number;
  sendChat?: (callback?: Function) => void;
  messageBody?: IMeetingChatBody;
  setMessageBody?: (body: IMeetingChatBody) => void;
  quoteMessage?: (message: MessageForBubble) => void;
  quotedMessage: MessageForBubble;
  setSeenMessageLastTime?: (date: Date) => void;
  deleteMessage?: (message: MessageForBubble) => void;
}

export const MeetChatContext = React.createContext<IMeetChatContext>(undefined);

export const MeetChatContextProvider = ({
  meetingId,
  chat,
  isPresenter,
  participants,
  myParticipantId,
  children
}: MeetWrapperProps) => {
  const dispatch = useDispatch();
  const [seenMessageLastTime, setSeenMessageLastTime] = React.useState<Date>(
    getNow()
  );
  const [openChatPanel, setOpenChatPanel] = React.useState(false);
  const recipient = '';
  const [quote, setQuote] = React.useState<string>(undefined);
  const [messageBody, setMessageBody] = React.useState<IMeetingChatBody>({
    text: ''
  });
  const [colorIdentifier, setColorIdentifier] = React.useState<{
    [key: string]: string;
  }>({});

  const [scrollTo, setScrollTo] = React.useState<string>(null);

  const [chatParticipants, setChatParticipants] = React.useState<
    ChatParticipant[]
  >(getChatHistoryByMeetingId(meetingId)?.participants || []);

  const myParticipantInfo = chatParticipants.find(
    (p) => p.participantId === myParticipantId
  );

  const mappedMessages = mapChatMessage(
    chat,
    chatParticipants,
    myParticipantInfo,
    colorIdentifier
  );

  const focusMessageInput = () => {
    document.getElementById('meetChatInputText')?.focus();
  };

  const toggleChatPanel = (open: boolean) => {
    setOpenChatPanel(open);
    setSeenMessageLastTime(open ? null : seenMessageLastTime || getNow());
    if (open) {
      logEvent(DID_SHOW_CHAT_PANEL, DID_SHOW_CHAT_PANEL, { meetingId });
      focusMessageInput;
    } else {
      logEvent(DID_HIDE_CHAT_PANEL, DID_HIDE_CHAT_PANEL, { meetingId });
    }
  };

  const unreadMessages =
    seenMessageLastTime && chat.length
      ? chat?.filter((msg) => new Date(msg.timeStamp) > seenMessageLastTime)
          ?.length
      : 0;

  const sendChat = (callback?: Function) => {
    const payload: IMeetingChat = {
      id: generateV4UUID(),
      from: myParticipantInfo?.identityId,
      to: recipient,
      timeStamp: getNow().toISOString(),
      quote,
      body: messageBody
    };

    wrapActionToBeMirroring(dispatch, actionSendMeetChat(payload), {
      to: recipient
    });

    logEvent(DID_CLICK_CHAT_SEND_MESSAGE, DID_CLICK_CHAT_SEND_MESSAGE, {
      chat: { ...payload, body: { ...payload.body, text: undefined } },
      meetingId
    });

    setScrollTo('chatEndSection');
    setMessageBody({ text: '' });
    setQuote(null);
    callback?.();
  };

  const quoteMessage = (message: MessageForBubble) => {
    if (!message) {
      setQuote(null);
    } else {
      logEvent(DID_QUOTE_CHAT_MESSAGE, DID_QUOTE_CHAT_MESSAGE, {
        messageId: message?.id,
        meetingId
      });
      setQuote(message?.id);
      focusMessageInput();
    }
  };

  const deleteMessage = (message: MessageForBubble) => {
    wrapActionToBeMirroring(dispatch, actionDeleteMeetChat(message.id), {
      to: ''
    });
    logEvent(DID_CLICK_DELETE_CHAT_MESSAGE, DID_CLICK_DELETE_CHAT_MESSAGE, {
      messageId: message.id,
      meetingId
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      setScrollTo(null);
    }, 1000);
  }, [scrollTo]);

  React.useEffect(() => {
    if (participants?.length) {
      setColorIdentifier(
        generateParticipantColorIdentifier(colorIdentifier, participants)
      );
    }
  }, [participants?.length]);

  React.useEffect(() => {
    if (participants?.length) {
      handleChatParticipant(
        participants,
        chatParticipants,
        setChatParticipants,
        dispatch,
        chat,
        meetingId,
        isPresenter
      );
    }
  }, [participants]);

  React.useEffect(() => {
    if (meetingId) {
      loadChatHistory(meetingId, dispatch);
    }
  }, []);

  return (
    <MeetChatContext.Provider
      value={{
        messages: mappedMessages,
        openChatPanel,
        isPresenter,
        toggleChatPanel,
        scrollTo,
        setScrollTo,
        participants: chatParticipants,
        colorIdentifier,
        myParticipantInfo,
        unreadMessages,
        sendChat,
        setMessageBody,
        messageBody,
        quoteMessage,
        quotedMessage: mappedMessages.find((msg) => msg?.id === quote),
        setSeenMessageLastTime,
        deleteMessage
      }}
    >
      {children}
    </MeetChatContext.Provider>
  );
};

const MeetChatContextWrapper = (props: MeetWrapperProps) => (
  <>
    {props.participants ? (
      <MeetChatContextProvider {...props}>
        {props.children}
        <MeetChat />
      </MeetChatContextProvider>
    ) : (
      <>{props.children}</>
    )}
  </>
);
export default MeetChatContextWrapper;
