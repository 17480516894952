import React from 'react';
import { BsX } from 'react-icons/bs';
import { MdSend } from 'react-icons/md';
import { meetingColor } from '../../../utils/meeting';
import ChatBubble from './ChatBubble';
import { isChatMessageValid } from './chatUtils';
import { MeetChatContext } from './MeetChatContext';

const ChatComposer = () => {
  const {
    sendChat,
    messageBody,
    setMessageBody,
    quotedMessage,
    quoteMessage,
    setScrollTo
  } = React.useContext(MeetChatContext);
  const [error, setError] = React.useState('');

  const ref = React.useRef<HTMLTextAreaElement>();

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage();
      e.preventDefault();
      return;
    }
  };

  const sendMessage = () => {
    if (!messageBody?.text?.trim()) return;
    if (isChatMessageValid(messageBody)) {
      sendChat();
    } else {
      setError('Message is too long or contain forbidden characters');
    }
  };
  return (
    <div
      className={`ChatComposer`}
      onClick={() => {
        ref.current.focus();
      }}
    >
      <div className="input-container">
        <textarea
          id="meetChatInputText"
          className="msg-input"
          onChange={(e) => {
            setMessageBody({ ...messageBody, text: e.target.value });
            setError(null);
          }}
          ref={ref}
          value={messageBody?.text || ''}
          onKeyPress={handleKeyPress}
          placeholder="Write your message..."
          onFocus={() => {
            setScrollTo('chatEndSection');
          }}
          style={{
            height: `${messageBody?.text ? ref.current?.scrollHeight : 20}px`
          }}
        />
      </div>
      <div
        className="send-container"
        onClick={() => {
          ref.current?.focus();
          sendMessage();
        }}
      >
        <div className="btn-send">
          <MdSend />
        </div>
      </div>
      {quotedMessage && (
        <div className="quote-container">
          <ChatBubble message={quotedMessage} isQuoted={true} />
          <div
            className="btn cancel-quote"
            onClick={() => quoteMessage(null)}
          >
            <BsX />
          </div>
        </div>
      )}
      {error && <div className="error-container">{error}</div>}
      <style jsx>{`
        .ChatComposer {
          display: flex;
          flex-wrap: wrap;
          padding: 0 5px;
          align-items: stretch;
          flex-flow: wrap-reverse;
          justify-content: space-between;
        }
        :global(.SM) .ChatComposer {
          padding-bottom: 20px;
        }
        .input-container {
          width: calc(100% - 50px);
          display: flex;
          align-items: center;
        }
        .msg-input {
          width: 100%;
          max-height: 80px;
          overflow: auto;
          text-align: left;
          padding: 0 8px;
          border: none;
          resize: none;
          font-size: 16px;
          -webkit-touch-callout: all;
          -webkit-user-select: all;
          -khtml-user-select: all;
          -moz-user-select: all;
          -ms-user-select: all;
          user-select: all;
        }
        textarea::-webkit-scrollbar {
          width: 1px;
          background: none;
        }
        textarea::-webkit-scrollbar-thumb {
          background-color: darkgrey;
        }
        .msg-input:focus {
          outline: none;
          border: none;
        }
        textarea:focus {
          box-shadow: none;
        }
        .send-container {
          height: 100%;
          padding: 5px 0;
        }
        .btn-send {
          background: ${messageBody?.text?.trim() ? '#222' : 'transparent'};
          width: 44px;
          height: 44px;
          display: flex;
          align-items: center;
          border-radius: 100px;
          cursor: pointer;
        }
        .btn-send :global(svg) {
          margin: auto;
          pointer-events: none;
          color: ${messageBody?.text?.trim() ? '#fff' : '#ccc'};
        }
        .quote-container {
          width: 100%;
          padding-top: 5px;
          position: relative;
        }
        .cancel-quote {
          position: absolute;
          top: 0;
          right: 0;
          padding: 2px;
        }
        .error-container {
          font-size: 12px;
          color: ${meetingColor.warning};
          background: #ffe6e6;
          width: 100%;
          border-radius: 4px;
          margin: 5px;
        }
      `}</style>
    </div>
  );
};

export default ChatComposer;
