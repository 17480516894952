import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import {
  AppFeature,
  IMainState,
  IVirtualBoutiqueConfig,
  MeetingNotification,
  MeetingRole
} from '../../interfaces';
import {
  actionAdvisorEndMeeting,
  actionBrowseStorybookInMeeting,
  actionHideMeetingNotification,
  actionKickParticipantFromMeeting,
  actionShowOutro,
  actionTeleportSelectSite,
  actionTogglePresenterMode
} from '../../redux/actions';
import {
  DID_LEAVE_MEETING,
  DID_MUTE_LOCAL_AUDIO,
  DID_MUTE_LOCAL_VIDEO,
  DID_MUTE_LOCAL_VIDEO_ON_SHARE_SCREEN,
  DID_UNMUTE_LOCAL_AUDIO,
  DID_UNMUTE_LOCAL_VIDEO,
  DID_UNMUTE_LOCAL_VIDEO_ON_SHARE_SCREEN
} from '../../utils/constants';
import {
  goToSspHome,
  isEmbeddedInStreamingStudio
} from '../../utils/streamingstudio';
import {
  useLocalUserMeetingEntitlement,
  useMeetingLocalAudio
} from '../hooks/meeting';
import { getOutroComponent } from '../storeComponentFactory';
import AdvisorStudioControl from './AdvisorStudioControl';
import ClientDetailPanel from './ClientDetailPanel';
import MeetingParticipantControls from '../Meeting/Controls/MeetingParticipantControls';
import StudioMeetingControl from '../Meeting/Controls/StudioMeetingControl';
import { MeetingContext } from './MeetingContext';
import StudioVideosPanel from './StudioVideosPanel';
import MeetingControls from './MeetingControls';
import MeetingVideos from './MeetingVideos';
import MeetingLayoutWrapper from './MeetingLayout';
import MeetingNotifications from './MeetingNotifications';
import MeetChatContextWrapper from './Chat/MeetChatContext';
import { mapParticipantsToChatParticipants } from './Chat/chatUtils/chatMapper';
import { _createPresenterStreamEffect } from './PresenterVideo/helpers';
import { syncLocalTrack } from './utils/track';
import { inspifyBrandId, inspifyTestBrandId } from '../../config';
import { tryRemoveFromLocalStorage } from '../../utils/window';
import { actionToggleMuteLocalTrackAsync } from '../../redux/asyncActions';

/* eslint-disable no-var */
declare global {
  var JitsiMeetJS: any;
}
/* eslint-enable no-var */

const Cockpit = ({
  storeId,
  config
}: {
  storeId: string;
  config: IVirtualBoutiqueConfig;
}) => {
  const router = useRouter();
  const meetingId = (router.query.meeting as string | undefined).toLowerCase();
  const dispatch = useDispatch();

  const clientState = useSelector(
    (state: IMainState) => state.clientState || {}
  );

  const meeting = clientState?.meeting;
  const showOutro = clientState?.vb?.showOutro;
  const controlParticipant = meeting?.participantControls;
  const popupVideoPlayWithSound = meeting?.popupState?.videoPlayWithSound;
  const localUserEntitlement = useLocalUserMeetingEntitlement();
  const canControlMeeting = localUserEntitlement?.[0];
  const { restoreAudio, muteAudioAndRestoreLater } = useMeetingLocalAudio();
  const [showStudioGrid, setShowStudioGrid] = React.useState(false);
  const enlargedVideoParticipantId =
    meeting?.layout?.enlargedVideoParticipantId;

  const localTracks = meeting.localUser?.tracks || [];
  const localRole = meeting.localUser?.role;
  const isSharingScreen = meeting.localUser?.isSharingScreen;

  const studioParticipantId = Object.keys(meeting.remoteUsers || {}).find(
    (participantId) =>
      (meeting.remoteUsers || {})[participantId]?.role === MeetingRole.STUDIO
  );
  const shouldShowStudioControl =
    canControlMeeting &&
    studioParticipantId &&
    enlargedVideoParticipantId === studioParticipantId;

  const { room, onDisconnect, setUserName } = React.useContext(MeetingContext);

  const onStop = () => {
    const leaveRoom = () => {
      if (clientState?.teleport?.teleportTo?.store?.id) {
        dispatch(actionTeleportSelectSite(undefined));
      }
      dispatch(actionBrowseStorybookInMeeting(false));
      onDisconnect(DID_LEAVE_MEETING, meetingId);
    };
    if (localRole === MeetingRole.ADVISOR) {
      dispatch(actionAdvisorEndMeeting());
      //TEMP FIX: add delay time to leave so advisor has chance to send message
      setTimeout(() => {
        leaveRoom();
        if (
          isEmbeddedInStreamingStudio() &&
          !getOutroComponent(
            storeId,
            config.meetingVideo?.closingVideoUrl,
            showOutro,
            'MEETING',
            meetingId,
            config.brandId === inspifyTestBrandId ||
              config.brandId === inspifyBrandId
          )
        ) {
          goToSspHome();
        }
      }, 2000);
      tryRemoveFromLocalStorage(meeting?.meetingId + 'overlayImg');
    } else {
      leaveRoom();
      if (
        getOutroComponent(
          storeId,
          config.meetingVideo?.closingVideoUrl,
          showOutro,
          'MEETING',
          meetingId,
          config.brandId === inspifyTestBrandId ||
            config.brandId === inspifyBrandId
        )
      ) {
        dispatch(actionShowOutro(true));
      }
    }
    dispatch(actionHideMeetingNotification(MeetingNotification.LEAVE_MEETING));
  };

  const onToggleAudio = () => {
    const participantId = room?.myUserId();
    const currentMute = meeting?.localUser?.audioMuted;
    if (currentMute) {
      logEvent(DID_UNMUTE_LOCAL_AUDIO, DID_UNMUTE_LOCAL_AUDIO, {
        participantId,
        meetingId
      });
    } else {
      logEvent(DID_MUTE_LOCAL_AUDIO, DID_MUTE_LOCAL_AUDIO, {
        participantId,
        meetingId
      });
    }
    dispatch(actionToggleMuteLocalTrackAsync('audio'));
  };

  const onToggleVideoMute = async () => {
    const participantId = room?.myUserId();
    const currentMute = meeting?.localUser?.videoMuted;
    if (currentMute) {
      logEvent(DID_UNMUTE_LOCAL_VIDEO, DID_UNMUTE_LOCAL_VIDEO, {
        participantId,
        meetingId
      });
    } else {
      logEvent(DID_MUTE_LOCAL_VIDEO, DID_MUTE_LOCAL_VIDEO, {
        participantId,
        meetingId
      });
    }
    dispatch(actionToggleMuteLocalTrackAsync('video'));
  };

  const onTogglePresenterVideo = async () => {
    const participantId = room?.myUserId();
    const _isOnPresenterMode = meeting?.localUser?.isOnPresenterMode;
    const activeCameraDevice = meeting?.localUser?.activeDevices?.camera;
    const videoTrack = localTracks.find((t) => t.getType() === 'video');

    if (_isOnPresenterMode) {
      return videoTrack.setEffect(undefined).then(() => {
        syncLocalTrack(room, dispatch);
        dispatch(actionTogglePresenterMode(false));
        logEvent(
          DID_MUTE_LOCAL_VIDEO_ON_SHARE_SCREEN,
          DID_MUTE_LOCAL_VIDEO_ON_SHARE_SCREEN,
          {
            participantId,
            meetingId
          }
        );
      });
    }
    const { height } = videoTrack.track.getSettings();
    return _createPresenterStreamEffect(height, activeCameraDevice)
      .then((effect) => videoTrack.setEffect(effect))
      .then(() => {
        syncLocalTrack(room, dispatch);
        dispatch(actionTogglePresenterMode(true));
        logEvent(
          DID_UNMUTE_LOCAL_VIDEO_ON_SHARE_SCREEN,
          DID_UNMUTE_LOCAL_VIDEO_ON_SHARE_SCREEN,
          {
            participantId,
            meetingId
          }
        );
      });
  };

  const enableChat = clientState.activeFeatures?.includes(AppFeature.MEET_CHAT);

  const onToggleVideo = isSharingScreen
    ? onTogglePresenterVideo
    : onToggleVideoMute;

  React.useEffect(() => {
    if (popupVideoPlayWithSound) {
      muteAudioAndRestoreLater();
    } else {
      restoreAudio();
    }
  }, [popupVideoPlayWithSound]);

  if (localRole === MeetingRole.STUDIO) {
    return <StudioMeetingControl />;
  }

  return (
    <MeetingLayoutWrapper
      meetingLayout={meeting?.layout}
      logoUrl={config.lightLogoUrl || ''}
      darkUrl={config.logoUrl || ''}
      isPresenter={localUserEntitlement?.[1]}
      primaryColor={config?.theme?.color?.primary}
    >
      <MeetChatContextWrapper
        {...(enableChat
          ? {
              meetingId: meetingId,
              chat: meeting.chat || [],
              participants:
                mapParticipantsToChatParticipants({
                  remoteUsers: meeting.remoteUsers || {},
                  localUser: meeting.localUser || {},
                  advisorParticipantId: meeting.advisorParticipantId,
                  advisorDisplayName: meeting.advisorName,
                  localDisplayName: clientState.user?.alias,
                  myIdentityId: clientState.user?.id
                }) || [],
              myParticipantId: meeting?.localUser?.participantId,
              isPresenter: localUserEntitlement?.[1]
            }
          : {})}
      >
        <MeetingNotifications
          clientState={clientState}
          onToggleAudio={onToggleAudio}
          onToggleVideo={onToggleVideo}
        />

        {controlParticipant && (
          <MeetingParticipantControls
            participantId={controlParticipant}
            setUserName={setUserName}
          />
        )}

        <MeetingVideos meeting={meeting} showStudioGrid={showStudioGrid} />

        <MeetingControls
          onToggleAudio={onToggleAudio}
          onToggleVideo={onToggleVideo}
          meeting={meeting}
          localUserEntitlement={localUserEntitlement}
          config={config}
          storeId={storeId}
          onLeave={onStop}
        />
        <ClientDetailPanel storeId={storeId} meetingId={meetingId} />
      </MeetChatContextWrapper>

      {shouldShowStudioControl && (
        <AdvisorStudioControl
          studioParticipantId={studioParticipantId}
          meetingId={meeting.meetingId}
          zoomLevel={meeting.layout?.studioViewState?.zoomLevel || 1}
          rotateDeg={meeting.layout?.studioViewState?.rotateDeg || 0}
          showGrid={showStudioGrid}
          toggleShowGrid={() => setShowStudioGrid(!showStudioGrid)}
        />
      )}
      {localRole === MeetingRole.ADVISOR && (
        <StudioVideosPanel
          onStop={(participantId) => {
            dispatch(actionKickParticipantFromMeeting({ participantId }));
          }}
        />
      )}
    </MeetingLayoutWrapper>
  );
};

export default Cockpit;
