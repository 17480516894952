import React from 'react';
import { useTranslation } from '../../i18n';
import { Spinner } from 'react-bootstrap';
import {
  DevicePermissionStatus,
  IDevicePermission,
  IVirtualBoutiqueConfig
} from '../../interfaces';
import RotateLandscape from '../VirtualBoutique/Icons/RotateLandscape';
import { isOrientationPortrait } from '../../utils/window';
import { MeetingContext } from './MeetingContext';

const StudioLounge = ({
  config,
  permissionStatus,
  isBrowserSupported,
  joinRoom
}: {
  config: IVirtualBoutiqueConfig;
  permissionStatus?: IDevicePermission;
  isBrowserSupported: boolean;
  joinRoom: () => void;
}) => {
  const [showRotationReminder, setShowRotationReminder] = React.useState(true);
  const { t } = useTranslation();
  const { connection } = React.useContext(MeetingContext);

  const resizeHandler = () => {
    setShowRotationReminder(isOrientationPortrait());
  };

  React.useEffect(() => {
    if (
      connection &&
      permissionStatus.video === DevicePermissionStatus.GRANTED
    ) {
      joinRoom();
    }
  }, [connection, permissionStatus.video]);

  React.useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <div className={`meeting-lounge`}>
      <div className={`content-container full-screen`}>
        <div className="content-inner">
          <img className="logo" src={config.logoUrl} />
          <h2>
            {t('host_lounge_welcome_message', {
              brand: t(`${config.brandName}`)
            })}
            .
          </h2>

          {isBrowserSupported ? (
            <>
              <Spinner animation="border" />
              <p className="reminder">{t('connecting_please_wait')}</p>
            </>
          ) : (
            <p
              className="checking-message text-center"
              style={{ padding: '20px 40px' }}
            >
              <strong>{t('checking_devices')}</strong>
              <span
                style={{
                  fontSize: '0.9em',
                  display: 'block'
                }}
              >
                {' '}
                {t('vb:walkthrough_browser_support.line1')}
                {` `}
                <a
                  style={{ color: 'inherit', textDecoration: 'underline' }}
                  href="https://www.google.com/chrome/"
                  target="_blank"
                >
                  {t('vb:walkthrough_browser_support.line2')}
                </a>
              </span>
            </p>
          )}
          <div className="media-container">
            {permissionStatus.video === DevicePermissionStatus.REJECTED && (
              <p
                className="input-error text-center"
                style={{ padding: '20px 40px', color: 'red' }}
              >
                {t('input_device_error')}
              </p>
            )}
          </div>
        </div>
        {showRotationReminder && (
          <div className="rotation-reminder">
            <RotateLandscape />
            <h5>{t('studio_rotate_landscape')}</h5>
          </div>
        )}
      </div>
      <style jsx>
        {`
          :global(body) {
            overflow: auto !important;
          }
          .meeting-lounge {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: #fff;
            color: #333;
            font-weight: 200;
            z-index: 1000;
          }

          .rotation-reminder {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.85);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          :global(.rotation-reminder svg) {
            height: 150px;
            fill: #fff;
          }

          h5 {
            color: #fff;
            margin: 30px 20px;
          }

          .logo {
            width: 120px;
          }

          h2 {
            margin: 30px 0;
            font-size: 1.25em;
            padding: 0 20px;
          }
          h2 span {
            display: block;
          }
          .content-inner {
            padding: 50px 20px 20px;
            overflow: visible;
          }
          .media-container {
            display: block;
            box-shadow: 0 0 10px #ccc;
            overflow: hidden;
          }

          .reminder {
            margin: 20px 0 0 0;
          }

          .media-container .inner {
            display: flex;
            align-items: center;
            text-align: left;
            flex-wrap: nowrap;
            overflow: hidden;
            background: #f8f8f8;
          }

          .media-container > .media-text p {
            display: none;
          }

          .media-container p {
            margin: 0;
          }

          .inner > :global(div:first-child) {
            width: 20%;
          }

          .inner .media-text {
            width: 80%;
          }

          .media-container :global(video) {
            width: 100%;
            height: auto;
          }

          .media-text {
            padding: 20px;
          }
          .form-group {
            margin: 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dashed #666;
            padding-bottom: 5px;
          }

          .client-note {
            padding: 30px;
          }
          .client-note :global(svg) {
            width: 50px;
            height: auto;
          }

          .client-note a {
            color: #000;
          }
          .btn-success {
            outline: none;
            box-shadow: none;
          }

          :global(.remote-users) {
            display: none;
          }

          :global(.btn-start) {
            margin-top: 40px;
            letter-spacing: 1px;
          }
          .content-container.full-screen {
            min-height: 100vh;
            display: flex;
            align-items: center;
          }

          @media (min-width: 768px) {
            .meeting-lounge {
              background: #000 url(${config.landingImageUrl}) center center
                no-repeat fixed;
              background-size: cover;
            }

            .content-container {
              padding: 40px 0;
            }

            .content-inner {
              background: #fff;
              width: 90%;
              margin: auto;
              border-radius: 20px;
              max-width: 960px;
              padding: 100px;
            }
          }

          @media (min-width: 1023px) {
            .logo {
              width: 200px;
            }
            p {
              font-size: 1.125em;
            }
            h2 {
              font-size: 1.5em;
              line-height: 1.4;
              margin: 40px 0;
            }

            .media-container .inner {
              display: block;
            }
            .media-container .inner :global(div:first-child) {
              width: 100%;
            }
            .media-container .inner p {
              display: none;
            }
            .media-container {
              display: flex;
              align-items: center;
              text-align: left;
              flex-wrap: nowrap;
              background: #f8f8f8;
              border-radius: 10px;
            }
            .media-container > .media-text p {
              display: block;
              margin-bottom: 20px;
            }
            .media-container > :global(div:first-child) {
              width: 50%;
            }

            .media-container > :global(div + div) {
              width: 50%;
            }

            .media-text {
              padding: 0 50px;
            }

            .form-group {
              margin: 40px 0 20px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default StudioLounge;
