import React from 'react';
import ChatBubble from './ChatBubble';
import { MessageForBubble } from './chatUtils';
import { MeetChatContext } from './MeetChatContext';

const ChatBubbleAction = ({
  onClose,
  message,
  isPresenter
}: {
  onClose: () => void;
  message: MessageForBubble;
  isPresenter: boolean;
}) => {
  if (!message) return null;

  const { quoteMessage, deleteMessage } = React.useContext(MeetChatContext);
  return (
    <>
      <div className="backdrop" onClick={onClose} />
      <div className="bubble-action">
        <ChatBubble message={message} isQuoted />
        <div className="actions btn-group">
          <button
            className="btn"
            onClick={() => {
              quoteMessage(message);
              onClose();
            }}
          >
            Quote
          </button>
          {(message.isMine || isPresenter) && (
            <button
              className="btn text-danger"
              onClick={() => {
                deleteMessage(message);
                onClose();
              }}
            >
              Delete
            </button>
          )}
        </div>
      </div>

      <style jsx>{`
        .backdrop {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          display: flex;
          align-items: center;
          background: #fff;
          opacity: 0.5;
        }
        .bubble-action {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          margin: auto;
          width: 250px;
          background: #fff;
          border-radius: 10px;
          padding: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .btn,
        .btn:focus {
          outline: none;
          box-shadow: none;
        }
      `}</style>
    </>
  );
};

export default ChatBubbleAction;
