import React from 'react';
import RemoteVideo from './RemoteVideo';
import { useSelector } from 'react-redux';
import { IMainState, OneThirdLayout } from '../../../interfaces';
import LocalVideo from './LocalVideo';

function OneThirdVideos({ layout }: { layout: OneThirdLayout }) {
  const room = useSelector(
    (state: IMainState) => state.clientState?.meeting?.jitsi
  );
  const presenterId = layout?.presenterId;
  const enlargedId = layout?.enlargedId;
  const renderVideo = (participantId: string) => {
    const isLocalVideo = participantId === room?.myUserId();
    return isLocalVideo ? (
      <LocalVideo />
    ) : (
      <RemoteVideo participantId={participantId} />
    );
  };

  return (
    <>
      <div className="one-third-wrapper fixed-full">
        <div className="presenter">{renderVideo(presenterId)}</div>
        <div className="enlarged">{renderVideo(enlargedId)}</div>
      </div>
      <style jsx global>{`
        .one-third-wrapper {
          display: flex;
          flex-direction: row;
        }
        .one-third-wrapper .presenter {
          flex: 33.333%;
        }
        .one-third-wrapper .enlarged {
          flex: 66.666%;
        }
        .one-third-wrapper .video-thumbnail {
          width: 100%;
          height: 100%;
          margin: 0 !important;
        }
      `}</style>
    </>
  );
}

export default OneThirdVideos;
