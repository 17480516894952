import { throttle } from 'lodash';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StorybookSessionEdit from '../../advisorHub/components/StoryBook/StorybookSessionEdit';
import MeetOnlyBrandStyle from '../../components/BrandStyle/MeetOnlyBrandStyle';
import CountDownContainer from '../../components/CountDownContainer';
import CSRComponentWrapper from '../../components/CSRComponentWrapper';
import DocumentViewerPopup from '../../components/DocumentsViewer/DocumentViewerPopup';
import EyeballTimeLogger from '../../components/EyeballTimeLogger';
import { shouldShowClientMeetingVeil } from '../../components/hooks/meeting';
import CockpitContainer from '../../components/Meeting/CockpitContainer';
import MeetingContextContainer from '../../components/Meeting/MeetingContext';
import MeetingKickedOutNotification from '../../components/Meeting/MeetingNotifications/MeetingKickedOutNotification';
import PreventInteractionVeil from '../../components/Meeting/PreventInteractionVeil';
import StudioDisconnected from '../../components/Meeting/StudioDisconnected';
import PasswordProtection from '../../components/PasswordProtection';
import PixelStreamExperience from '../../components/PixelStream';
import PopupPage from '../../components/PopupPage';
import {
  getOutroComponent,
  getVBLite
} from '../../components/storeComponentFactory';
import FaceBookPixel from '../../components/Tracking/FaceBookPixel';
import VBPageViewLogger from '../../components/VBPageViewLogger';
import VirtualBoutiqueContent from '../../components/VirtualBoutique';
import BoutiqueCss from '../../components/VirtualBoutique/BoutiqueCss';
import CanvasBasedExperience from '../../components/VirtualBoutique/CanvasBasedExperience';
import MainCss from '../../components/VirtualBoutique/MainCss';
import SessionContextContainer from '../../components/VirtualBoutique/SessionContextContainer';
import VBHTMLHeader from '../../components/VirtualBoutique/VBHTMLHeader';
import { inspifyBrandId, inspifyTestBrandId } from '../../config';
import { useTranslation } from 'next-i18next';
import {
  AppFeature,
  IMainState,
  ISessionDetails,
  IStore,
  IVirtualBoutiqueConfig,
  MeetingRole,
  ParticipantMeetingState,
  VirtualBoutiqueMode
} from '../../interfaces';
import {
  actionActivateFeatures,
  actionUpdateMeetingLayout,
  actionUpdateStore,
  actionUpdateVBConfig,
  actionUpdateViewportInfo,
  actionUpdateVirtualBoutiqueMode
} from '../../redux/actions';
import { actionFetchLocalUserAsync } from '../../redux/asyncActions';
import { getSessionById } from '../../services/sessions';
import { getStoreById } from '../../services/stores';
import { getVirtualBoutiqueConfigByStoreId } from '../../services/virtualBoutique';
import { isUserOnMobile, isUserOnMobileOnly } from '../../utils/deviceDetector';
import { setDefaultLanguage } from '../../utils/language';
import { getMeetingLayoutMode, getViewportType } from '../../utils/meeting';
import { PageView } from '../../utils/pageView';
import { getVirtualBoutiqueMode } from '../../utils/stores';
import { isEmbeddedInStreamingStudio } from '../../utils/streamingstudio';
import initNativeEventListener from '../../utils/streamingstudio/nativeToJsEventListener';
import { getViewportSize, mapBrowerLanguageToSBLanguage } from '../../utils/window';
import Error from '../_error';
import VBCustomStyle from './../../components/BrandStyle';
import StreamingStudioStorybook from '../../components/StreamingStudioStorybook';
import { useSspState } from '../../components/hooks/common';
import SSPBoutiqueStyle from '../../components/BrandStyle/SSPBoutiqueStyle';
import { TLanguage } from '../../mappers/polotno';
export const VirtualBoutiqueWrapper = ({
  config,
  store,
  session
}: {
  config: IVirtualBoutiqueConfig | null;
  store: IStore | null;
  session?: ISessionDetails;
}) => {
  const [loadedOnClientSide, setLoadedOnClientSide] = React.useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const language = (router.query.language as string) || undefined;
  const vbMode = getVirtualBoutiqueMode(router.query.mode as string);
  const layoutMode = getMeetingLayoutMode(
    store?.id,
    router.query.layout as string
  );
  const { i18n, ready } = useTranslation();
  const isRtl = i18n?.language === 'ar';
  const isInSSP = useSspState();

  React.useEffect(() => {
    if (ready) {
      i18n
        ?.reloadResources?.(i18n.resolvedLanguage, ['common', 'vb'])
        .catch((e) => {
          console.log('failed to reload language', e);
        });
    }
  }, [ready]);

  React.useEffect(() => {
    window['store'] = store;
    dispatch(actionUpdateVirtualBoutiqueMode(vbMode));
    if (
      vbMode === VirtualBoutiqueMode.COCKPIT &&
      config?.entitlement?.cockpit &&
      router.query.role === 'advisor'
    ) {
      dispatch(actionUpdateMeetingLayout(layoutMode));
      if (!isEmbeddedInStreamingStudio()) {
        dispatch(actionActivateFeatures([AppFeature.MEET_CHAT]));
      }
    }
    dispatch(actionFetchLocalUserAsync(router.query.role as string));
    dispatch(actionUpdateStore(store));
    dispatch(actionUpdateVBConfig(config));
    setLoadedOnClientSide(true);
    setDefaultLanguage(i18n)(language, config?.languages);
    initNativeEventListener(dispatch, config?.brandId, store?.id);
    if (config?.unRealBasedExperience) {
      document.getElementById('__next').classList.add('psExperience');
    }
    const onViewPortResize = throttle(() => {
      const { vh, vw } = getViewportSize();
      const viewportInfo = {
        width: vw,
        height: vh,
        onPhone: isUserOnMobileOnly(),
        onMobile: isUserOnMobile(),
        type: getViewportType({ width: vw, height: vh })
      };
      dispatch(actionUpdateViewportInfo(viewportInfo));
    }, 200);
    onViewPortResize();
    window.addEventListener('resize', onViewPortResize);
    return () => {
      window.removeEventListener('resize', onViewPortResize);
    };
  }, []);

  const [browserLanguage, setBrowerLanguage] = React.useState<TLanguage>('en');
  React.useEffect(() => {
    setBrowerLanguage(mapBrowerLanguageToSBLanguage(navigator.language))
  },[])

  const clientState = useSelector(
    (state: IMainState) => state.clientState || {}
  );
  const meeting = clientState.meeting;
  const vb = clientState.vb;

  const renderVeil = shouldShowClientMeetingVeil();

  const isInCockpitMode =
    vbMode === VirtualBoutiqueMode.COCKPIT && config?.entitlement?.cockpit;

  const renderBoutique = () => {
    if (router.query.role === 'studio' || !ready) return null;
    if (meeting?.state === ParticipantMeetingState.SHOW_WELCOME_VIDEO)
      return null;
    const getVirtualExperience = () => {
      if (config?.canvasBasedExperience) {
        return <CanvasBasedExperience config={config} store={store} />;
      }
      if (config?.unRealBasedExperience) {
        return <PixelStreamExperience config={config} store={store} />;
      }
      return config?.pano ? (
        <VirtualBoutiqueContent
          config={config}
          store={store}
          vbMode={vbMode}
          className={`${i18n.language || 'en'} ${isRtl ? 'isRtl' : ''}`}
        />
      ) : (
        getVBLite(config, store, vbMode)
      );
    };
    const vbContent = (
      <>
        {getVirtualExperience()}
        <MeetingKickedOutNotification show={meeting?.localUser?.kickedOut} />
      </>
    );
    if (vbMode === VirtualBoutiqueMode.COCKPIT) {
      return meeting?.state || config?.unRealBasedExperience ? vbContent : null;
    }
    return vbContent;
  };

  if (!config || !store)
    return <Error hasGetInitialPropsRun={true} err={null} />;
  return (
    <VBPageViewLogger storeId={store.id}>
      <FaceBookPixel brandId={config?.brandId} storeId={store.id}>
        <FaceBookPixel brandId="inspify" storeId={store.id}>
          <EyeballTimeLogger
            tracker={{ view: PageView.VIRTUAL_BOUTIQUE, id: store.id }}
          >
            <VBHTMLHeader
              config={config}
              storeId={store.id}
              isInCockpitMode={isInCockpitMode}
              session={session}
            />
            <SessionContextContainer
              brandId={config?.brandId}
              source={router.query.source as string}
              meetingId={router.query.meeting as string}
              role={router.query.role as string}
            >
              <CountDownContainer storeId={store.id} config={config}>
                <CSRComponentWrapper>{renderBoutique()}</CSRComponentWrapper>
                {renderVeil && (
                  <PreventInteractionVeil
                    shouldBlockClient={
                      config?.entitlement?.walkthroughMirrorScroll
                    }
                    layoutMode={meeting?.layout?.mode}
                    storeId={store.id}
                    overlay={config?.overlay}
                    isOverlayImgVisible={
                      meeting?.isOverlayImgVisible &&
                      meeting?.localUser?.role !== MeetingRole.STUDIO
                    }
                  />
                )}
                {vb?.popup?.open && <PopupPage />}
                {isInSSP && <StreamingStudioStorybook />}
                {clientState?.isBrowseStorybookInMeeting && (
                  <StorybookSessionEdit
                    brandId={config?.brandId}
                    sessionId={meeting?.meetingId}
                  />
                )}
                {isInCockpitMode &&
                  router.query.role === 'studio' &&
                  meeting?.state === ParticipantMeetingState.LEFT_MEETING && (
                    <StudioDisconnected />
                  )}
                {isInCockpitMode &&
                  loadedOnClientSide &&
                  meeting?.state !== ParticipantMeetingState.LEFT_MEETING && (
                    <MeetingContextContainer
                      isPixelStreamingExperience={config?.unRealBasedExperience}
                    >
                      <CockpitContainer storeId={store.id} vbConfig={config} />
                    </MeetingContextContainer>
                  )}

                {isInCockpitMode &&
                  meeting?.state ===
                    ParticipantMeetingState.ENTERED_FROM_LOUNGE &&
                  router.query.role !== 'studio' && (
                    <DocumentViewerPopup meeting={meeting} activateLanguage={browserLanguage}/>
                  )}
                {getOutroComponent(
                  store.id,
                  config?.meetingVideo?.closingVideoUrl,
                  vb?.showOutro,
                  'MEETING',
                  router.query.meeting as string,
                  config?.brandId === inspifyTestBrandId ||
                    config?.brandId === inspifyBrandId,
                  meeting?.advisorEndsMeeting
                )}
                <MainCss isRtl={isRtl} />
                <BoutiqueCss isRtl={isRtl} />
                <VBCustomStyle
                  storeId={
                    clientState?.teleport?.teleportTo?.store?.id || store.id
                  }
                  language={i18n.language}
                />
                {config?.entitlement?.meetOnly && <MeetOnlyBrandStyle />}
                {isInSSP && <SSPBoutiqueStyle />}
              </CountDownContainer>
            </SessionContextContainer>
          </EyeballTimeLogger>
        </FaceBookPixel>
      </FaceBookPixel>
    </VBPageViewLogger>
  );
};

const VirtualBoutique = ({
  config,
  store,
  session
}: {
  config: IVirtualBoutiqueConfig | undefined;
  store: IStore | undefined;
  session: ISessionDetails;
}) =>
  config?.passcode ? (
    <PasswordProtection correctPassword={config?.passcode}>
      <VirtualBoutiqueWrapper config={config} store={store} session={session} />
    </PasswordProtection>
  ) : (
    <VirtualBoutiqueWrapper config={config} store={store} session={session} />
  );

export const getServerSideProps: GetServerSideProps = async (context) => {
  const id: string = context.params.id as string;
  let config = null;
  let store = null;
  let session = null;
  try {
    if (id && id !== 'undefined') {
      config = await getVirtualBoutiqueConfigByStoreId(id);
      store = await getStoreById(id);
      if (['session', 'live_session'].includes(context.query?.source as string)&& context.query?.meeting) {
        session = await getSessionById(context.query?.meeting as string);
        if (context.query?.role !== 'advisor' && session?.preparedStoryBook) {
          delete session.preparedStoryBook;
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
  return {
    props: {
      config,
      store,
      session
    }
  };
};

export default VirtualBoutique;
