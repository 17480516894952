import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoubiAirways, zIndex } from '../../config';
import { IMainState, IOverlay, MeetingLayoutMode } from '../../interfaces';
import { actionDidClickPreventInteractionVeil } from '../../redux/actions';
import RoleIndicator from './RoleIndicator';

const PreventInteractionVeil = ({
  shouldBlockClient,
  layoutMode,
  storeId,
  overlay,
  isOverlayImgVisible
}: {
  shouldBlockClient?: boolean;
  layoutMode?: MeetingLayoutMode;
  storeId?: string;
  overlay?: IOverlay;
  isOverlayImgVisible?: boolean;
}) => {
  const dispatch = useDispatch();
  const isPresentationMode = layoutMode === MeetingLayoutMode.PRESENTATION;
  const shouldHideForPopup = useSelector(
    (state: IMainState) =>
      state.clientState.meeting?.popupState?.hideMeetingVeil
  );

  const getZIndex = () => {
    if (shouldHideForPopup) return zIndex.popupPage - 1;
    if (shouldBlockClient) return zIndex.preventActionVeil;
    return 0;
  };

  const handleVeilClicked = () => {
    dispatch(actionDidClickPreventInteractionVeil());
  };

  return (
    <>
      <div className={`veil`} onClick={handleVeilClicked}>
        {isLoubiAirways(storeId) ? null : <RoleIndicator />}
      </div>
      <style jsx>
        {`
          .veil {
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            height: ${isPresentationMode ? 'calc(100vh - 150px)' : 'auto'};
            bottom: ${isPresentationMode ? '150px' : 0};
            z-index: ${getZIndex()};
            background: ${isOverlayImgVisible
              ? `#000 url(${overlay?.imageUrl}) center no-repeat`
              : 'unset'};
            background-size: cover;
            background-position-x: center;
            background-position-y: center;
          }

          @media (max-width: 940px) {
            .veil {
              left: 0;
              right: 0;
              width: 100vw;
              bottom: auto;
              height: ${isPresentationMode ? '40vh' : '100vh'};
              background-size: cover !important;
              background: ${isOverlayImgVisible
                ? `#000 url(${
                    overlay?.portraitImageUrl || overlay?.imageUrl
                  }) center no-repeat`
                : 'unset'};
            }
          }
        `}
      </style>
    </>
  );
};

export default PreventInteractionVeil;
