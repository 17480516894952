import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import { BiChevronDown } from 'react-icons/bi';
import {
  IMeeting,
  IVirtualBoutiqueConfig,
  MeetingLayoutMode
} from '../../../interfaces';
import {
  actionHideMeetingControls,
  actionToggleRaiseHand,
  actionToggleStatsMode,
  actionUpdateMeetingLayout
} from '../../../redux/actions';
import {
  DID_CLICK_INVITE_CLIENT_TO_MEETING_BUTTON,
  DID_CLICK_RAISE_HAND,
  DID_CLICK_SHOW_FILES,
  DID_CLICK_TOGGLE_STATS_MODE,
  DID_INVITE_CLIENT_TO_MEETING,
  DID_SHARE_PAGE,
  DID_TOGGLE_MEETING_CONTROLS,
  DID_UPDATE_LAYOUT_MODE
} from '../../../utils/constants';

import MainControls from './MainControls';

import { MeetingContext } from '../MeetingContext';
import { performNativeShare } from '../../../utils/window';
import { useTranslation } from '../../../i18n';
import { getSimpleShortenedUrl } from '../../../utils/shareUrl';
import SimpleShareModal from '../../Sharing/SimpleShareModal';
import {
  DID_CLICK_SHARE_STUDIO_URL,
  DID_CLICK_SCAN_QR_BUTTON
} from './../../../utils/constants';
import ScanStudioPopup from './ScanStudioPopup';
import { ViewportContext } from '../MeetingLayout';
import { IconMenuArrow } from './MeetingIcons';
import ToolTipWrapper from '../../Common/ToolTipWrapper';
import MeetingFileList from '../../DocumentsViewer/MeetingFileList';
import { zIndex } from '../../../config';
import { getAdvisorCustomControls } from '../../storeComponentFactory';
import { ConnectStreamingCubePopup } from './ConnectStreamingCubePopup';
import { inspifyBrandId } from '../../../config';
import FullScreenButton from '../../Common/FullScreenButton';
enum ShareType {
  INVITE_CLIENT = 'INVITE_CLIENT',
  STUDIO_SCAN = 'STUDIO_SCAN'
}
const MeetingControls = ({
  onToggleAudio,
  onToggleVideo,
  meeting,
  localUserEntitlement = [false, false, false],
  config,
  storeId,
  onLeave
}: {
  onToggleAudio: () => void;
  onToggleVideo: () => void;
  meeting: IMeeting;
  localUserEntitlement?: boolean[];
  config: IVirtualBoutiqueConfig;
  storeId: string;
  onLeave: () => void;
}) => {
  const meetingId = meeting.meetingId;
  const [canControlMeeting, isPresenter] = localUserEntitlement;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLocalAudioMuted = meeting?.localUser?.audioMuted;
  const isLocalVideoMuted = meeting?.localUser?.videoMuted;
  const { isSmallScreen } = React.useContext(ViewportContext);

  const [showShare, setShowShare] = React.useState<ShareType | undefined>(
    undefined
  );
  const [showScanStudio, setShowScanStudio] = React.useState(false);
  const [showStreamingCube, setShowStreamingCube] = React.useState(false);
  const [showDocument, setShowDocument] = React.useState(false);
  const [clientShareUrl, setClientShareUrl] = React.useState('');
  const [studioShareUrl, setStudioShareUrl] = React.useState('');
  const [showLeavePrompt, setShowLeavePrompt] = React.useState(false);

  const controlsHidden = meeting?.layout?.hideControls;

  const inviteMessage = `${t('vb:invitation_message.line1')} ${t(
    'vb:invitation_message.line2',
    {
      brandName: t(`${config.boutiqueName}`)
    }
  )} ${t('vb:invitation_message.line3')}`;

  const scanMessage = `${config.boutiqueName} - ${t('vb:streaming_studio')}`;

  const onShare: any = (channel = '', type: ShareType) => {
    const isClientType = type === ShareType.INVITE_CLIENT;
    logEvent(
      isClientType ? DID_INVITE_CLIENT_TO_MEETING : DID_SHARE_PAGE,
      channel,
      {
        meetingId,
        meetingUrl: isClientType ? clientShareUrl : studioShareUrl
      }
    );
  };

  const getShareUrl = (url: string, type: ShareType) => {
    const role = type === ShareType.STUDIO_SCAN ? 'studio' : 'client';
    return url
      ?.replace('role=advisor', `role=${role}`)
      ?.replace('role=co_advisor', `role=${role}`);
  };

  const studioPopup = (
    <ToolTipWrapper
      onClose={() => {
        setShowScanStudio(false);
      }}
      width={250}
      distanceFromParent={30}
    >
      <ScanStudioPopup url={studioShareUrl} qrTitle={t('vb:qr_scan_studio')}>
        <div className="btn-container">
          <button
            className="btn btn-sm btn-dark btn-full btnGetUrl"
            style={{ background: '#000' }}
            onClick={() => {
              logEvent(DID_CLICK_SHARE_STUDIO_URL, DID_CLICK_SHARE_STUDIO_URL, {
                studioUrl: studioShareUrl,
                meetingId
              });
              const onNativeShare = () => {
                onShare('native', ShareType.STUDIO_SCAN);
              };
              const onFailedNativeShare = () => {
                setShowShare(ShareType.STUDIO_SCAN);
                setShowScanStudio(false);
              };
              performNativeShare(
                {
                  title: config.boutiqueName,
                  text: scanMessage,
                  url: studioShareUrl
                },
                onNativeShare,
                onFailedNativeShare
              );
            }}
          >
            {t('vb:get_url')}
          </button>
        </div>
      </ScanStudioPopup>
    </ToolTipWrapper>
  );

  const streamingCubePopup = (
    <ToolTipWrapper
      onClose={() => {
        setShowStreamingCube(false);
      }}
      width={870}
      distanceFromParent={30}
      className="streaming-cube-popup"
      hideCloseButton={true}
    >
      <div className="control-menu">
        <ConnectStreamingCubePopup
          brandId={config.brandId}
          onClose={() => {
            setShowStreamingCube(false);
          }}
        />
      </div>
    </ToolTipWrapper>
  );

  const shareModal = (
    <ToolTipWrapper
      onClose={() => setShowShare(undefined)}
      width={250}
      distanceFromParent={30}
    >
      <div className="control-menu text-left">
        <div className="list-group-item device-title d-flex align-items-center">
          <strong>
            {showShare === ShareType.INVITE_CLIENT
              ? t('invite_to_meeting')
              : t('send_studio_url')}
          </strong>
        </div>
        <SimpleShareModal
          show={!!showShare}
          position="bottom"
          url={
            showShare === ShareType.INVITE_CLIENT
              ? clientShareUrl
              : studioShareUrl
          }
          onShare={(channel) => onShare(channel, showShare)}
          title={config.boutiqueName}
          message={
            showShare === ShareType.INVITE_CLIENT ? inviteMessage : scanMessage
          }
          type="menu"
        />
      </div>
    </ToolTipWrapper>
  );

  const documentPopup = (
    <ToolTipWrapper
      onClose={() => setShowDocument(false)}
      parentId="openDocument"
      width={259}
      distanceFromParent={30}
    >
      <div className="control-menu text-left">
        <MeetingFileList
          onSelectFile={() => {
            setShowDocument(false);
          }}
          onOpenSBPopup={() => {
            setShowDocument(false);
          }}
        />
      </div>
    </ToolTipWrapper>
  );

  const leavePromptPopup = (
    <ToolTipWrapper
      parentId="meetingEndButton"
      onClose={() => setShowLeavePrompt(false)}
      width={250}
      distanceFromParent={30}
    >
      <div className="control-menu">
        <div className="list-group-item device-title text-left">
          <strong>{t('are_you_sure')}</strong>
        </div>
        <button
          className="list-group-item list-group-item-action text-danger"
          onClick={onLeave}
        >
          {t('stop_meeting')}
        </button>
        <button
          className="list-group-item list-group-item-action text-success"
          onClick={() => setShowLeavePrompt(false)}
        >
          {t('continue_meeting')}
        </button>
      </div>
    </ToolTipWrapper>
  );

  const toggleHideMenu = (hide: boolean) => {
    dispatch(actionHideMeetingControls(hide));
    logEvent(DID_TOGGLE_MEETING_CONTROLS, DID_TOGGLE_MEETING_CONTROLS, {
      open: !hide
    });
  };

  const onToggleStatsMode = () => {
    dispatch(actionToggleStatsMode());
    logEvent(DID_CLICK_TOGGLE_STATS_MODE, DID_CLICK_TOGGLE_STATS_MODE, {
      meetingId
    });
  };

  React.useEffect(() => {
    if (canControlMeeting) {
      const clientUrl = getShareUrl(location.href, ShareType.INVITE_CLIENT);
      const studioUrl = getShareUrl(location.href, ShareType.STUDIO_SCAN);

      if (getSimpleShortenedUrl instanceof Function) {
        getSimpleShortenedUrl(clientUrl)
          .then((shortenedClientUrl) => setClientShareUrl(shortenedClientUrl))
          .catch(() => setClientShareUrl(clientUrl));

        getSimpleShortenedUrl(studioUrl)
          .then((shortenedStudioUrl) => setStudioShareUrl(shortenedStudioUrl))
          .catch(() => setStudioShareUrl(studioUrl));
      } else {
        setClientShareUrl(clientUrl);
        setStudioShareUrl(studioUrl);
      }
    }

    if (isSmallScreen && canControlMeeting) {
      toggleHideMenu(true);
    } else {
      toggleHideMenu(false);
    }
  }, [canControlMeeting, isSmallScreen]);

  const { room } = React.useContext(MeetingContext);

  const onCloseMenu = () => {
    toggleHideMenu(true);
  };

  const mainControl = (
    <MainControls
      advisorCustomControls={getAdvisorCustomControls({
        storeId,
        brandId: config.brandId,
        onCloseMenu,
        meetingState: meeting
      })}
      config={config}
      meeting={meeting}
      isSmallScreen={isSmallScreen}
      localUserEntitlement={localUserEntitlement}
      isLocalAudioMuted={isLocalAudioMuted}
      isLocalVideoMuted={isLocalVideoMuted}
      onClickAudio={onToggleAudio}
      onClickVideo={onToggleVideo}
      onStop={() => setShowLeavePrompt(true)}
      onToggleRaiseHand={() => {
        const participantId = room?.myUserId();
        dispatch(actionToggleRaiseHand(participantId));
        logEvent(DID_CLICK_RAISE_HAND, DID_CLICK_RAISE_HAND, {
          participantId,
          raise: !meeting?.raiseHand?.participantIds?.includes(participantId)
        });
      }}
      onClickInvite={() => {
        logEvent(
          DID_CLICK_INVITE_CLIENT_TO_MEETING_BUTTON,
          DID_CLICK_INVITE_CLIENT_TO_MEETING_BUTTON,
          { meetingId }
        );
        const onNativeShare = () => {
          onShare('native', ShareType.INVITE_CLIENT);
        };
        const onFailedNativeShare = () => {
          setShowShare(ShareType.INVITE_CLIENT);
        };
        performNativeShare(
          {
            title: config.boutiqueName,
            text: inviteMessage,
            url: clientShareUrl
          },
          onNativeShare,
          onFailedNativeShare
        );
      }}
      onClickScanStudio={() => {
        logEvent(DID_CLICK_SCAN_QR_BUTTON, DID_CLICK_SCAN_QR_BUTTON, {
          qrContent: studioShareUrl
        });
        setShowScanStudio(!showScanStudio);
      }}
      onClickLayoutButton={() => {
        const mode =
          meeting?.layout?.mode === MeetingLayoutMode.TILE
            ? MeetingLayoutMode.NORMAL
            : MeetingLayoutMode.TILE;
        dispatch(actionUpdateMeetingLayout(mode));
        logEvent(DID_UPDATE_LAYOUT_MODE, DID_UPDATE_LAYOUT_MODE, {
          mode
        });
      }}
      onOpenDocument={() => {
        logEvent(DID_CLICK_SHOW_FILES, DID_CLICK_SHOW_FILES, {
          meetingId: meeting.meetingId
        });
        setShowDocument(true);
      }}
      onToggleStatsMode={
        config?.brandId === inspifyBrandId ? onToggleStatsMode : null
      }
      onCloseMenu={onCloseMenu}
      onClickConnectStudio={() => {
        setShowStreamingCube(true);
      }}
    />
  );

  const toggleHideButton = (
    <div
      className="control-hide-button"
      onClick={() => {
        toggleHideMenu(!controlsHidden);
      }}
    >
      {isSmallScreen && isPresenter ? (
        <IconMenuArrow fill="currentColor" />
      ) : (
        <BiChevronDown color="currentColor" />
      )}
      <style jsx>{`
        .control-hide-button {
          cursor: pointer;
          color: white;
        }
      `}</style>
    </div>
  );

  const toggleFullScreen = (
    <FullScreenButton className="meet-fullscreen" size={35} />
  );
  return (
    <>
      <div className={`meeting-controls ${controlsHidden ? 'hide' : 'show'}`}>
        <div className="controls-wrapper">
          {mainControl}
          {toggleFullScreen}
          {toggleHideButton}
        </div>
      </div>
      {showDocument && documentPopup}
      {showLeavePrompt && leavePromptPopup}
      {canControlMeeting && (
        <>
          {showShare && shareModal}
          {showScanStudio && studioPopup}
          {showStreamingCube && streamingCubePopup}
        </>
      )}
      <style jsx>{`
        .meeting-controls {
          z-index: ${zIndex.meetingControls};
          position: fixed;
        }
        @media (max-width: 1199px) {
          :global(#ToolTipWrapper.streaming-cube-popup) {
            width: 600px !important;
          }
        }
      `}</style>
    </>
  );
};

export default MeetingControls;
