import React from 'react';
import { logPageView } from '../analytics';
import { setNewIdentityIfNeeded } from '../utils/identity';
import { PageView } from '../utils/pageView';
import { createNewSessionIfNeeded } from '../utils/analyticsSession';
import GA from './Tracking/GA';

const VBPageViewLogger = (props: {
  children: React.ReactNode;
  storeId?: string;
}) => {
  React.useEffect(() => {
    setNewIdentityIfNeeded();
    createNewSessionIfNeeded(props.storeId);
    logPageView(props.storeId, PageView.VIRTUAL_BOUTIQUE);
  }, []);

  return <GA storeId={props.storeId}>{props.children}</GA>;
};

export default VBPageViewLogger;
