import { JitsiLocalTrack } from './../../../interfaces/index';
import { MEDIA_TYPE } from '../utils/track';
import JitsiStreamPresenterEffect from './JitsiPresenterStreamEffect';

let localPresenterVideo: JitsiLocalTrack;

export const _createPresenterStreamEffect = async (
  height: number = null,
  cameraDeviceId: string = undefined
) => {
  if (!localPresenterVideo) {
    try {
      localPresenterVideo = await createLocalPresenterTrack(
        { cameraDeviceId },
        height
      );
    } catch (err) {
      console.error('Fail to create a camera track for presenter', err);
      return;
    }
  }
  try {
    const effect = await createPresenterEffect(localPresenterVideo.stream);

    return effect;
  } catch (err) {
    console.error('Failed to create the presenter effect', err);
  }
};

export function createPresenterEffect(stream: MediaStream) {
  if (
    !MediaStreamTrack.prototype.getSettings &&
    !MediaStreamTrack.prototype.getConstraints
  ) {
    return Promise.reject(
      new Error('JitsiStreamPresenterEffect not supported!')
    );
  }

  return Promise.resolve(new JitsiStreamPresenterEffect(stream));
}

export const createLocalPresenterTrack = async (options, desktopHeight) => {
  const { cameraDeviceId } = options;

  // compute the constraints of the camera track based on the resolution
  // of the desktop screen that is being shared.
  const cameraHeights = [180, 270, 360, 540, 720];
  const proportion = 5;
  const result = cameraHeights.find(
    (height) => desktopHeight / proportion < height
  );
  const constraints = {
    video: {
      aspectRatio: 4 / 3,
      height: {
        ideal: result
      }
    }
  };
  const [videoTrack] = await JitsiMeetJS.createLocalTracks({
    cameraDeviceId,
    constraints,
    devices: ['video']
  });

  videoTrack.type = MEDIA_TYPE.PRESENTER;

  return videoTrack;
};
