import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { IMainState } from '../../interfaces';
import { actionHideClientDetailPanel } from '../../redux/actions';
import { DID_HIDE_MEETING_CLIENT_DETAILS_PANEL } from '../../utils/constants';
import ClientDetail from './ClientDetail';

const ClientDetailPanel = ({
  storeId,
  meetingId
}: {
  storeId: string;
  meetingId: string;
}) => {
  const dispatch = useDispatch();

  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const showPanel = meeting?.showClientDetailPanel;
  return (
    <>
      <div className={`client-panel ${showPanel ? 'show' : ''}`}>
        <button
          className={`close`}
          type="button"
          onClick={() => {
            dispatch(actionHideClientDetailPanel());
            logEvent(
              DID_HIDE_MEETING_CLIENT_DETAILS_PANEL,
              DID_HIDE_MEETING_CLIENT_DETAILS_PANEL,
              { meetingId }
            );
          }}
        >
          &times;
        </button>
        <ClientDetail storeId={storeId} meetingId={meetingId} />
      </div>
      <style jsx global>
        {`
          .client-panel {
            background: rgba(0, 0, 0, 0.9);
            position: fixed;
            top: 0;
            bottom: 0;
            left: -350px;
            width: 350px;
            z-index:2002
          }

          .client-panel.show {
            transform: translateX(350px);
          }

          .client-panel .close {
            position: absolute;
            right: 5px;
            top: 0;
            color: #ccc;
            background-color: transparent;
            padding: 0;
            width: 45px;
            height: 45px;
            font-size: 24px;
            cursor: pointer;
            z-index: 10;
          }
        `}
      </style>
    </>
  );
};

export default ClientDetailPanel;
