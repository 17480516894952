import { MDGlobalSpec } from './MDGlobal';

const portraitSize = (totalVideos: number): [string, string] => {
  if (totalVideos < 3) return ['100%', '100%'];
  if (totalVideos === 3) return ['100%', '50%'];
  if (totalVideos === 4) return ['50%', '50%'];
  if (totalVideos <= 6) return ['50%', '33.33%'];
  if (totalVideos <= 8) return ['50%', '25%'];
  if (totalVideos === 9) return ['33.33%', '33.33%'];
  if (totalVideos <= 12) return ['33.33%', '25%'];
  return ['33.33%', '25vh'];
};

const landscapeSize = (
  totalVideos: number
): [string, string, string, string] => {
  if (totalVideos < 3) return ['100%', '100%', 'none', 'none'];
  if (totalVideos === 3) return ['50%', '100%', 'none', 'none'];
  if (totalVideos === 4) return ['50%', '50%', '85vh', 'none'];
  if (totalVideos <= 6) return ['33.33%', '50%', '85vh', 'none'];
  if (totalVideos <= 8) return ['25%', '50%', '85vh', 'none'];
  if (totalVideos === 9) return ['33.33%', '33.33%', '57vh', 'none'];
  if (totalVideos <= 12) return ['25%', '33.33%', '57vh', 'none'];
  if (totalVideos <= 16) return ['25%', '25%', '45vh', 'none'];
  return ['25%', '25vh', '45vh', 'none'];
};

const localPortrait = (totalVideos: number) => {
  if (totalVideos < 4 && totalVideos !== 1) return ['140px', '80px'];
  return portraitSize(totalVideos);
};

const localLandscape = (totalVideos: number) => {
  if (totalVideos < 4 && totalVideos !== 1)
    return ['140px', '80px', 'none', 'none'];
  return landscapeSize(totalVideos);
};

const MDTile = ({ totalVideos }: { totalVideos: number }) => (
  <>
    <style jsx global>{`
      .tile-mode.MD #participant-wrapper {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: ${MDGlobalSpec.logoSectionHeight}px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
      .in-meeting.tile-mode.MD #local-wrapper .video-thumbnail video {
        border-radius: 5px;
      }

      .in-meeting.tile-mode.MD #local-wrapper {
        display: flex;
        position: ${totalVideos < 4 && totalVideos !== 1 ? 'fixed' : 'initial'};
        left: ${totalVideos < 4 && totalVideos !== 1 ? '10px' : 'auto'};
        top: ${totalVideos < 4 && totalVideos !== 1
          ? `${MDGlobalSpec.logoSectionHeight + 10}px`
          : 'auto'};
      }

      .tile-mode.MD #local-wrapper .video-thumbnail {
        display: block;
        border-radius: 5px;
        border: ${totalVideos < 4 && totalVideos !== 1
          ? 'none'
          : '0.5px solid #000'};
        width: 100%;
        height: 100%;
      }
      .tile-mode.MD .video-thumbnail,
      .tile-mode.MD .video-thumbnail video {
        border-radius: 5px;
      }

      .tile-mode.MDPortrait .video-thumbnail {
        width: ${portraitSize(totalVideos)[0]};
        height: ${portraitSize(totalVideos)[1]};
      }
      .in-meeting.tile-mode.MDPortrait #local-wrapper {
        width: ${localPortrait(totalVideos)[0]};
        height: ${localPortrait(totalVideos)[1]};
      }
      .tile-mode.MD #videos-wrapper {
        min-width: 100%;
        min-height: 100%;
      }
      .tile-mode.MDLandscape #videos-wrapper {
        text-align: center;
      }
      .tile-mode.MDLandscape .video-thumbnail {
        width: ${landscapeSize(totalVideos)[0]};
        height: ${landscapeSize(totalVideos)[1]};
        max-width: ${landscapeSize(totalVideos)[2]};
      }
      .in-meeting.tile-mode.MDLandscape #local-wrapper {
        width: ${localLandscape(totalVideos)[0]};
        height: ${localLandscape(totalVideos)[1]};
        max-width: ${localLandscape(totalVideos)[2]};
      }
      .tile-mode.MD .remote-video-container .display-name {
        font-size: 1.6em;
        padding: 11px 16px;
      }

      .tile-mode.MD #local-wrapper .video-thumbnail .display-name {
        font-size: ${totalVideos < 4 && totalVideos !== 1 ? '1em' : '1.6em'};
        padding: ${totalVideos < 4 && totalVideos !== 1 ? '5px' : '11px 16px'};
      }
    `}</style>
  </>
);
export default MDTile;
