import React from 'react';
import { useTranslation } from '../../../i18n';
import { meetingColor } from '../../../utils/meeting';
import MeetingButtonWithIcon from '../MeetingControls/MeetingButtonWithIcon';
import { IconChat } from '../MeetingControls/MeetingIcons';
import { MeetChatContext } from './MeetChatContext';

const LiveChatButton = ({ callback }: { callback?: () => void }) => {
  const { openChatPanel, toggleChatPanel, unreadMessages } =
    React.useContext(MeetChatContext) || {};
  const { t } = useTranslation();
  if (!toggleChatPanel) return null;

  return (
    <div className="live-chat-button">
      <MeetingButtonWithIcon
        onClick={() => {
          toggleChatPanel(!openChatPanel);
          callback?.();
        }}
      >
        <IconChat />
        <span>{t('live_chat')}</span>
      </MeetingButtonWithIcon>
      {!!unreadMessages && !openChatPanel && (
        <div className="unreadMessages">{unreadMessages}</div>
      )}
      <style jsx>{`
        .live-chat-button {
          position: relative;
        }
        .unreadMessages {
          position: absolute;
          border-radius: 50px;
          background: ${meetingColor.warning};
          line-height: 18px;
          font-size: 13px;
          color: #fff;
          padding: 0 6px;
          top: -3px;
          right: 9px;
          pointer-events: none;
        }
      `}</style>
    </div>
  );
};

export default LiveChatButton;
