import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getSessionById } from '../../../clientSideServices/sessions';
import { IMainState, ISessionDetails } from '../../../interfaces';

import { saveSession } from '../../clientSideServices/session';
const queryClient = new QueryClient();
import SBContextContainer from './SBContextContainer';
import StoryBook from '.';
import { zIndex } from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { actionBrowseStorybookInMeeting } from '../../../redux/actions';
import { Spinner } from 'react-bootstrap';

const StorybookSessionEdit = ({
  brandId,
  sessionId
}: {
  brandId: string;
  sessionId: string;
}) => {
  const dispatch = useDispatch();
  const [session, setSession] = React.useState<ISessionDetails>();
  const isBrowseStorybookInMeeting = useSelector(
    (state: IMainState) => state.clientState?.isBrowseStorybookInMeeting
  );
  React.useEffect(() => {
    getSessionById(sessionId)
      .then((result) => {
        setSession(result.data);
      })
      .catch((e) => console.error(e));
  }, []);

  const handleAddStorybooks = (ids: string[]) => {
    setSession({ ...session, storybooks: ids });
    saveSession({
      ...session,
      storybooks: ids,
      preparedStoryBook: []
    }).then(() => dispatch(actionBrowseStorybookInMeeting(false)));
  };

  const storybookPopup = (
    <StoryBook
      onClose={() => dispatch(actionBrowseStorybookInMeeting(false))}
      onSelect={handleAddStorybooks}
      selectedStorybooks={session?.storybooks || []}
      layoutMode={{
        popupMode: true,
        selectMode: true,
        previewMode: 'default'
      }}
      brandId={brandId}
    />
  );
  return (
    <div className="StorybookSessionEdit popup-page-content">
      <QueryClientProvider client={queryClient}>
        <SBContextContainer brandId={brandId}>
          {session || isBrowseStorybookInMeeting ? (
            storybookPopup
          ) : (
            <div className="load-container">
              <Spinner animation="border" color="white" />
            </div>
          )}
        </SBContextContainer>
      </QueryClientProvider>
      <style jsx>
        {`
          .StorybookSessionEdit {
            position: fixed;
            z-index: ${zIndex.popupPage + zIndex.preventActionVeil};
          }
          .load-container {
            margin-top: 100px;
            color: white;
          }
        `}
      </style>
      <style jsx global>{`
        .PopupContainer {
          padding-top: 0 !important;
        }
        .btn-group .btn-round {
          border-radius: 0;
        }
        .btn-group .btn-round:first-child {
          border-radius: 100px 0 0 100px;
        }

        .btn-group .btn-round:last-child {
          border-radius: 0 100px 100px 0;
        }

        .btn-round {
          border-radius: 100px;
          border: 1px solid rgba(0, 0, 0, 0.5);
          font-weight: 500;
        }

        .input-round {
          border-radius: 100px;
        }
      `}</style>
    </div>
  );
};

export default StorybookSessionEdit;
