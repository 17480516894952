import React, { useState } from 'react';
import { first, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { BsPlus } from 'react-icons/bs';
import {
  actionRequestToBePresenter,
  actionSetActiveFile,
  actionSetFileController
} from '../../redux/actions';

import {
  DID_LOGIN_HUB_IN_MEETING,
  DID_SEARCH_FOR_STORYBOOK,
  DID_SELECT_FILE,
  DID_SELECT_STORYBOOK
} from '../../utils/constants';
import { useFileViewerState } from '../hooks/fileViewer';
import {
  SessionContext,
  SessionLoadingState
} from '../VirtualBoutique/SessionContextContainer';
import { actionSetActivePage } from './../../redux/actions';
import { Spinner } from 'react-bootstrap';
import {
  IconStorybookMeeting
} from '../Meeting/MeetingControls/MeetingIcons';
import { IMainState } from '../../interfaces';
import {
  useLocalUserMeetingEntitlement,
  useMeetingDebugLogger
} from '../hooks/meeting';
import { actionBrowseStorybookInMeetingAsync } from '../../redux/asyncActions';
import { mapBrowerLanguageToSBLanguage } from '../../utils/window';
import { getLanguaImageFromJSON } from '../../advisorHub/utils/storybook';

type ImageGenre = 'landscape' | 'portrait';

const MeetingFileList = ({
  onSelectFile = () => null,
  onOpenSBPopup = () => null,
}: {
  onSelectFile?: () => void;
  onOpenSBPopup?: () => void;
}) => {
  const dispatch = useDispatch();

  const meeting = useSelector(
    (state: IMainState) => state.clientState?.meeting
  );

  const [_, isSourceOfMirrorActions] = useLocalUserMeetingEntitlement();
  const logger = useMeetingDebugLogger();

  const { session, sessionLoadingStatus, loadSession } =
    React.useContext(SessionContext);
  const filesState = useFileViewerState();
  const files = filesState.files?.filter((file) => !file?.hideInMenu);
  const fileToDisplay =
    files?.map((f) => ({ title: f.title, thumnail: f.thumbnails?.[0], type: f.type, id: f.id })) || [];
  const isLoading = sessionLoadingStatus === SessionLoadingState.LOADING;

  React.useEffect(() => {
    if (session && isSourceOfMirrorActions) {
      loadSession();
    }
  }, []);

  const getThumbnail = (string = "") => {
    let thumbnail = string 
    if (thumbnail.endsWith("index.m3u8")) {
      thumbnail = thumbnail.replace("index.m3u8", "preview.0000000.jpg")
    }
    return thumbnail
  }

  const handlePresenterSelectFile = (
    fileId: string,
    name: string,
    type: 'storybook' | 'document'
  ) => {
    dispatch(actionSetActiveFile(fileId));
    onSelectFile();
    const file = files.find((f) => f.id === fileId);
    if (first(file?.pages)) {
      dispatch(
        actionSetActivePage({
          id: fileId,
          file: name,
          url: first(file?.pages),
          setting: first(file?.pagesSetting),
          silence: first(file?.silences)
        })
      );
    }
    dispatch(actionSetFileController(meeting.localUser?.participantId));
    const event = type === 'storybook' ? DID_SELECT_STORYBOOK : DID_SELECT_FILE;
    logEvent(event, event, {
      id: fileId,
      title: name
    });
  };

  const handleSelectFile = (fileId: string, name, type) => {
    const file = files.find((f) => f.id === fileId);
    if (first(file?.pages)) {
      dispatch(actionRequestToBePresenter(file));
    }
    const event = type === 'storybook' ? DID_SELECT_STORYBOOK : DID_SELECT_FILE;
    logEvent(event, event, {
      id: fileId,
      title: name,
      action: 'request'
    });
  };

  const FileItem = ({
    name,
    id,
    type,
    thumbnail,
  }: {
    name: string;
    id: string;
    type: 'storybook' | 'document',
    thumbnail?: string;
  }) => {
    const [imageGenre, setImageGenre] = useState<ImageGenre>('landscape');

    return (
      <button
        type="button"
        className="storybook-file-item list-group-item list-group-item-action"
        style={{
          wordBreak: 'break-all',
          flexWrap: 'wrap'
        }}
        onClick={() => {
          const handler = isSourceOfMirrorActions
            ? handlePresenterSelectFile
            : handleSelectFile;
          handler(id, name, type);
        }}
      >
        <div className="storybook-thumbnail">
          <img
            src={getThumbnail(thumbnail)}
            className={imageGenre}
            alt="storybook thumbnail"
            onLoad={async (e) => {
              const image = e.target as HTMLImageElement;
              const isLandscape = thumbnail.endsWith('index.m3u8')
                ? 'portrait'
                : image.naturalWidth > image.naturalHeight;
              setImageGenre(() => (isLandscape ? 'landscape' : 'portrait'));
            }}
          />
        </div>
        <span className="storybook-title">{name}</span>
        <style jsx>{`
          .storybook-file-item {
            display: flex;
            border: none;
          }
          .storybook-thumbnail {
            width: 130px !important;
            height: 80px;
            overflow: hidden;
            text-align: center;
            background: #f9f9f9;
          }
          .storybook-title {
            flex: 1;
            margin-left: 10px;
            font-size: 13px;
            color: #fff;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          img.landscape {
            height: 100%;
            width: auto;
          }
          img.portrait {
            height: auto;
            width: 100%;
          }
        `}</style>
      </button>
    );
  };

  const fileList = (
    <>
      {isLoading && (
        <div className="list-group-item list-group-item list-group-item-action refetch">
          <div style={{ color: '#666', fontSize: '12px' }}>
            <Spinner animation="border" color="white" size="sm" /> Checking for
            the update...
          </div>
        </div>
      )}
      {!isEmpty(fileToDisplay) && (
        <div className="storybook-display">
          <div className="storybook-title">
            <IconStorybookMeeting />
            <label>Storybooks</label>
          </div>
          <div className="storybook-list">
            {fileToDisplay?.map((file) => {
              const language = mapBrowerLanguageToSBLanguage(navigator.language);
              const thumbnailUrl = getLanguaImageFromJSON(file.thumnail, language);
              return (
                <FileItem
                  name={file.title}
                  key={file.id}
                  id={file.id}
                  type={file.type}
                  thumbnail={thumbnailUrl}
                />
              )
            })}
          </div>
        </div>
      )}
      <style jsx>{`
        .storybook-display {
          color: #fff;
        }
        .storybook-title {
          display: flex;
          justify-content: flex-start;
          font-weight: 600;
          padding: 10px;
          border-bottom: 0.5px solid #606060;
        }
        .storybook-title label {
          margin: 0;
          margin-left: 10px;
          font-size: 13px;
        }
        .storybook-list {
          max-height: 60vh;
          overflow: auto;
        }
      `}</style>
    </>
  );

  const emptyList = (
    <div className="text-center empty-wrapper" style={{ color: '#666' }}>
      {isLoading ? (
        <Spinner animation="border" color="white" />
      ) : (
        <p className="text-danger not-available">Document not available</p>
      )}
    </div>
  );

  const handleOnOpenStorybook = () => {
    onOpenSBPopup();
    const onSuccess = () =>
      logger(DID_SEARCH_FOR_STORYBOOK, DID_SEARCH_FOR_STORYBOOK);
    const onPopupOpen = () =>
      logger(DID_LOGIN_HUB_IN_MEETING, DID_LOGIN_HUB_IN_MEETING);
    dispatch(actionBrowseStorybookInMeetingAsync(onPopupOpen, onSuccess));
  };
  return (
    <>
      {fileToDisplay?.length ? fileList : emptyList}
      {
        <button
          type="button"
          className="list-group-item list-group-item list-group-item-action open-sb"
          style={{
            wordBreak: 'break-all',
            flexWrap: 'wrap'
          }}
          onClick={handleOnOpenStorybook}
        >
          <BsPlus />
          <span style={{ flex: 1, fontWeight: 'bold' }}>Open storybooks</span>
        </button>
      }
      <style jsx global>{`
        #ToolTipWrapper {
          width: 547px !important;
          max-width: 90vw !important;
        }
        .close-container {
          margin-right: 20px;
          margin-top: 10px;
        }
        .close-container btn {
          font-size: 20px;
        }
      `}</style>
    </>
  );
};

export default MeetingFileList;
