import React from 'react';
import { BsX } from 'react-icons/bs';
import { FaCrown } from 'react-icons/fa';
import { useTranslation } from '../../../i18n';
import { MeetingRole } from '../../../interfaces';
import { meetingColor } from '../../../utils/meeting';
import { getTwoLetterFromName } from '../../../utils/string';
import { ChatParticipant } from './chatUtils';

const ChatAvatar = ({
  name,
  color = '#b7b7b7',
  status,
  isHost
}: {
  name: string;
  color?: string;
  status?: 'online' | 'offline';
  isHost?: boolean;
}) => (
  <div className="ChatAvatar" style={{ background: color }}>
    {name}
    {isHost && <FaCrown />}
    {status && <span />}
    <style jsx>{`
      .ChatAvatar {
        color: #fff;
        border-radius: 100px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-family: Helvetica;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.2px;
        margin: 0 1px;
        position: relative;
      }
      .ChatAvatar :global(svg) {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -9%);
        width: 10px;
        height: auto;
      }
      span {
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 10px;
        background: ${status === 'offline' ? '#ccc' : meetingColor.success};
        border: 1px solid #fff;
        display: block;
        width: 10px;
        height: 10px;
      }
    `}</style>
  </div>
);

const ChatHeader = ({
  onClose,
  participants,
  colorIdentifier
}: {
  onClose: () => void;
  participants: ChatParticipant[];
  colorIdentifier?: { [key: string]: string };
}) => {
  const limit = 5;
  const onlineParticipants = participants.filter((p) => p.status === 'online');
  const limitAvatar = (
    <ChatAvatar name={`+${onlineParticipants.length - limit}`} />
  );
  const { t } = useTranslation();
  return (
    <div className="ChatHeader">
      <div className="title-container">
        <div className="avatar-container">
          {onlineParticipants?.map(
            (p, i) =>
              i < limit && (
                <ChatAvatar
                  key={p.participantId}
                  name={getTwoLetterFromName(p.displayName)}
                  color={colorIdentifier[p.identityId]}
                  isHost={p.role === MeetingRole.ADVISOR}
                />
              )
          )}
          {onlineParticipants.length > limit && limitAvatar}
        </div>
        <h2>{t('live_chat')}</h2>
      </div>
      <button className="btn" onClick={onClose}>
        <BsX size={30} color="#666" />
      </button>
      <style jsx>{`
        .btn,
        .btn:hover,
        .btn:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
        .btn {
          padding: 0;
          line-height: 1;
        }
        .ChatHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
          z-index: 2;
          position: relative;
        }
        h2 {
          font-size: 16px;
          text-align: left;
          margin: 0;
          padding-left: 5px;
          font-weight: bold;
        }
        .avatar-container {
          display: flex;
        }
        .title-container {
          display: flex;
          align-items: center;
        }
      `}</style>
    </div>
  );
};
export default ChatHeader;
