import React from 'react';
import CountDown from 'react-countdown';
import { isOnBoardingBrandByBrandId } from '../../advisorHub/utils/hubPagesRoute';
import { tiffanyUsNycId } from '../../config';
import { useTranslation } from '../../i18n';
import VBCustomStyle from '../BrandStyle';
import SessionHealthCheckButton from '../Meeting/HealthCheck/SessionHealthCheckButton';

const MeetingCountDown = ({
  endDate,
  onEnded,
  logoUrl,
  lightLogoUrl,
  storeId,
  dark,
  backgroundUrl,
  brandId,
  backgroundUrlMobile
}: {
  endDate: Date;
  onEnded: () => void;
  logoUrl?: string;
  lightLogoUrl?: string;
  storeId?: string;
  dark?: boolean;
  backgroundUrl?: string;
  brandId?: string;
  backgroundUrlMobile?: string;
}) => {
  const { t, i18n } = useTranslation();
  React.useEffect(() => {
    if (!endDate || new Date() >= endDate) {
      onEnded();
    }
  }, []);
  const isOnBoarding = isOnBoardingBrandByBrandId(brandId);
  const getBackgroundUrl = (isMobile?: boolean) => {
    if (backgroundUrlMobile && isOnBoarding && isMobile)
      return backgroundUrlMobile;

    if (backgroundUrl) return backgroundUrl;

    if (storeId === tiffanyUsNycId) {
      return 'https://panos2.inspify.io/virtual_boutique/67dc311c-1bde-439b-8fb0-ee9c988da0b1/tiffany_countdown.jpg';
    }
    return dark
      ? '/asset/countdown/countdown-background-dark.jpg'
      : '/asset/countdown/countdown-background.jpg';
  };
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="countdown-container">
        <div className="pill-container">
          <div className="pill">
            <div className="countdown-number">{days}</div>
          </div>
          <p className="countdown-pill-label">{t('days')}</p>
        </div>
        <div className="pill-container">
          <div className="pill">
            <div className="countdown-number">{hours}</div>
          </div>
          <p className="countdown-pill-label">{t('hours')}</p>
        </div>
        <div className="pill-container">
          <div className="pill">
            <div className="countdown-number">{minutes}</div>
          </div>
          <p className="countdown-pill-label">{t('minutes')}</p>
        </div>
        <div className="pill-container">
          <div className="pill">
            <div className="countdown-number">{seconds}</div>
          </div>
          <p className="countdown-pill-label">{t('seconds')}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="countdown brand-font">
        <div className={`${isOnBoarding ? 'countdown-block' : ''}`}>
          {(logoUrl || lightLogoUrl) && (
            <img
              className="logo"
              alt="Brand Logo"
              src={dark ? lightLogoUrl : logoUrl}
            />
          )}
          <div>
            <h1 className="heading">{t('session_countdown')}</h1>
          </div>
          <CountDown
            className="time-left"
            zeroPadDays={2}
            zeroPadTime={2}
            date={endDate}
            onComplete={() => {
              onEnded();
            }}
            renderer={renderer}
          />
        </div>
        <SessionHealthCheckButton dark={!dark} />
        <style jsx global>
          {`
            .countdown-block {
              background: white;
              border-radius: 10px;
              padding: 40px;
            }
            .countdown-container {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .pill {
              background: url(${dark
                ? '/asset/countdown/timer-bg-dark.png'
                : '/asset/countdown/timer-bg.png'});
              background-repeat: no-repeat;
              background-size: cover;
              margin: 0 25px;
              width: 200px;
              height: 260px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .countdown-number {
              font-style: normal;
              font-weight: 900;
              font-size: 7rem;
              text-align: center;
              color: ${dark ? '#fff' : '#000'};
            }
            .countdown-pill-label {
              margin: 10px 0;
              font-style: normal;
              font-size: 1rem;
              color: ${dark ? '#fff' : '#000'};
              font-weight: 200;
              text-transform: uppercase;
            }
            @media (min-width: 720px) and (min-height: 520px) {
              .heading {
                font-size: 1.3rem !important;
              }
            }
            @media (max-width: 998px) {
              .pill {
                width: 120px;
                height: 156px;
                margin: 0 15px;
              }

              .countdown-number {
                font-size: 3.5rem;
                padding-top: 10px;
              }
              .countdown-pill-label {
                font-size: 0.5rem;
              }
            }

            @media (max-width: 768px) {
              .pill {
                width: 100px;
                height: 130px;
                margin: 0 15px;
              }

              .countdown-number {
                font-size: 3.5rem;
                padding-top: 10px;
              }
              .countdown-pill-label {
                font-size: 0.5rem;
              }
            }

            @media (max-width: 520px) {
              .pill {
                width: 80px;
                height: 100px;
                margin: 0 5px;
              }

              .countdown-number {
                font-size: 2rem;
                padding-top: 10px;
              }
            }

            @media only screen and (max-height: 480px) and (orientation: landscape) {
              .pill {
                margin: 0 5px;
              }

              .countdown-number {
                font-size: 2rem;
                padding-top: 10px;
              }
            }

            @media only screen and (max-height: 420px) and (orientation: landscape) {
              .pill {
                width: 60px;
                height: 78px;
                margin: 0 10px;
              }

              .countdown-number {
                font-size: 2rem;
                padding-top: 10px;
              }
            }
          `}
        </style>
        <style jsx>{`
          .countdown {
            background: url(${getBackgroundUrl()});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;
            text-align: center;
            color: #000;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
          h1 {
            font-size: 2.3rem;
            margin-bottom: 50px;
            font-style: normal;
            font-weight: bold;
            text-align: center;
            color: ${dark ? '#ffffff' : '#000000'};
          }

          .logo {
            width: 200px;
            top: 50px;
            margin-bottom: 50px;
          }

          :global(.time-left) {
            font-size: 5rem;
            font-weight: 600;
            letter-spacing: 20px;
          }

          button {
            position: fixed;
            bottom: 20px;
            right: 20px;
            background: transparent;
            border: none;
          }

          :global(.toggle-mute svg) {
            width: 40px;
            height: 40px;
          }

          @media (max-width: 768px) {
            h1 {
              font-size: 1.5rem;
            }

            :global(.time-left) {
              font-size: 2.5rem;
              font-weight: 600;
              letter-spacing: 10px;
            }
          }

          @media (max-width: 480px) {
            h1 {
              font-size: 1rem;
              margin-bottom: 20px;
            }

            .logo {
              width: 140px;
              top: 120px;
              position: ${isOnBoarding ? 'unset' : 'absolute'};
            }
            .countdown {
              background: url(${getBackgroundUrl(true)});
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }

          @media only screen and (max-height: 480px) and (orientation: landscape) {
            .countdown {
              padding-top: 50px;
              justify-content: initial;
            }
            h1 {
              font-size: 1.3rem;
              margin-bottom: 15px;
            }

            .logo {
              width: 140px;
              margin-bottom: 15px;
            }
          }

          @media only screen and (max-height: 380px) and (orientation: landscape) {
            .countdown {
              padding-top: 5px;
              justify-content: initial;
            }
            .countdown-block {
              padding: 5px 40px;
            }
          }
        `}</style>
        <VBCustomStyle storeId={storeId} language={i18n.language} />
      </div>
    </>
  );
};

export default MeetingCountDown;
