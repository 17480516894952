import React from 'react';
import ChatWindow from './ChatWindow';
import { MeetChatContext } from './MeetChatContext';

const MeetChat = () => {
  const { openChatPanel } = React.useContext(MeetChatContext);
  return openChatPanel ? <ChatWindow /> : null;
};

export default MeetChat;
