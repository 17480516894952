import React from 'react';
import CountDown from 'react-countdown';
import { GoMute, GoUnmute } from 'react-icons/go';
import { logEvent } from '../../../../analytics';
import {
  DID_MUTE_BACKGROUND_SOUND,
  DID_UNMUTE_BACKGROUND_SOUND
} from '../../../../utils/constants';
import SessionHealthCheckButton from '../../../Meeting/HealthCheck/SessionHealthCheckButton';

const IWCWW2021CountDown = ({
  endDate,
  onEnded
}: {
  endDate: Date;
  onEnded: () => void;
}) => {
  const [muted, setMuted] = React.useState(true);
  const audioRef = React.createRef<HTMLAudioElement>();
  const backgroundMusicUrl =
    'https://panos2.inspify.io/virtual_boutique/c3f011ac-c437-4fd6-8c3d-6b6128f3139a/countdown_bg_music_v1.mp3';
  React.useEffect(() => {
    if (!endDate || new Date() >= endDate) {
      onEnded();
    }
  }, []);

  React.useEffect(() => {
    if (!muted) {
      audioRef.current?.play();
    }
  }, [muted]);

  return (
    <>
      <div className="countdown">
        <h1>THE PREVIEW WILL START IN</h1>
        <CountDown
          className="time-left"
          zeroPadDays={2}
          zeroPadTime={2}
          date={endDate}
          onComplete={() => {
            onEnded();
          }}
        />
        <audio ref={audioRef} loop autoPlay muted={muted}>
          <source src={backgroundMusicUrl} type="audio/mpeg" />
        </audio>
        <SessionHealthCheckButton />
        <button
          className="toggle-mute"
          onClick={() => {
            if (muted) {
              setMuted(false);
              logEvent(DID_UNMUTE_BACKGROUND_SOUND);
            } else {
              setMuted(true);
              logEvent(DID_MUTE_BACKGROUND_SOUND);
            }
          }}
        >
          {muted ? <GoUnmute color="#fff" /> : <GoMute color="#fff" />}
        </button>
        <style jsx>{`
          .countdown {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100vh;
            text-align: center;
            background: #000;
            color: #fff;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
          h1 {
            font-size: 3.5rem;
            letter-spacing: 10px;
            margin-bottom: 40px;
          }
          :global(.time-left) {
            font-size: 5rem;
            font-weight: 600;
            letter-spacing: 20px;
          }

          button {
            position: fixed;
            bottom: 20px;
            right: 20px;
            background: transparent;
            border: none;
          }

          :global(.toggle-mute svg) {
            width: 40px;
            height: 40px;
          }

          @media (max-width: 768px) {
            h1 {
              font-size: 1.5rem;
              letter-spacing: 5px;
              margin-bottom: 20px;
            }

            :global(.time-left) {
              font-size: 2.5rem;
              font-weight: 600;
              letter-spacing: 10px;
            }
          }
        `}</style>
      </div>
    </>
  );
};

export default IWCWW2021CountDown;
