import React from 'react';
import { zIndex } from '../../../config';
import ChatComposer from './ChatComposer';
import ChatList from './ChatList';
import { MeetChatContext } from './MeetChatContext';
import { MDLandscapeNormalSpec } from '../MeetingLayout/MDLandscapeNormal';
import ChatHeader from './ChatHeader';

let timeOut;

const ChatWindow = () => {
  const { toggleChatPanel, participants, colorIdentifier } =
    React.useContext(MeetChatContext);
  const [closing, setClosing] = React.useState(false);

  React.useEffect(() => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    if (closing) {
      timeOut = setTimeout(() => {
        toggleChatPanel(false);
        setClosing(false);
      }, 300);
    }
  }, [closing]);

  const chatBox = (
    <div className={`ChatWindow ${closing ? 'closing' : ''}`}>
      <div className="header-container">
        <ChatHeader
          onClose={() => setClosing(true)}
          participants={participants}
          colorIdentifier={colorIdentifier}
        />
      </div>
      <div className="list-container">
        <ChatList />
      </div>
      <div className="composer-container">
        <ChatComposer />
      </div>
      <style jsx>{`
        .ChatWindow {
          position: fixed;
          z-index: ${zIndex.chatWindow};
          display: flex;
          flex-direction: column;
          flex-flow: column;
          align-content: stretch;
          border-radius: 10px 10px 0 0;
          overflow: hidden;
          animation: slide-up 0.3s ease-out both;
        }
        .ChatWindow.closing {
          animation: slide-down 0.3s ease-out both;
        }

        :global(.SM) .ChatWindow {
          border-radius: 0;
          right: 0;
          top: 0;
          left: 0;
          bottom: 0;
          width: auto;
          height: auto;
        }
        :global(.MD) .ChatWindow {
          height: 70vh;
          max-height: 500px;
          bottom: 0;
          width: 320px;
          left: 20px;
        }
        :global(.MD.MDLandscape.normal-mode) .ChatWindow {
          left: ${MDLandscapeNormalSpec.contentArea.left + 20}px;
        }
        .list-container {
          background: #f3f3f3;
        }
        .header-container,
        .composer-container {
          background: #fff;
        }

        .list-container {
          height: 100%;
          position: relative;
        }
        .composer-container {
          border-top: 1px solid #efefef;
        }

        @keyframes slide-down {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(100%);
          }
        }
        @keyframes slide-up {
          0% {
            transform: translateY(100%);
          }
          100% {
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );

  return chatBox;
};

export default ChatWindow;
