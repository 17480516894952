import React from 'react';
import { IFile, IMainState, ISessionPayload } from '../../interfaces';
import MeetingFileListV2 from '../DocumentsViewer/MeetingFileListV2';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionCloseSSPStorybook,
  actionDidLoadFileList,
  actionSetActiveFile,
  actionSetActivePage,
  actionSetFileController
} from '../../redux/actions';
import { first } from 'lodash';
import { DID_SELECT_FILE, DID_SELECT_STORYBOOK } from '../../utils/constants';
import { logEvent } from '../../analytics';
import {
  getSessionAttachedFiles,
  getSessionStorybookFiles
} from '../../utils/session';
import { getSessionById } from '../../clientSideServices/sessions';
import { Spinner } from 'react-bootstrap';
import { getSspControls } from '../../utils/window';
function StorybookSearchPopup() {
  const [session, setSession] = React.useState({} as ISessionPayload);
  const [loading, setLoading] = React.useState(false);
  const clientState = useSelector((state: IMainState) => state.clientState);
  const sessionId = clientState.vb?.sspStorybook?.sessionId;
  const dispatch = useDispatch();

  const loadSession = () => {
    setLoading(true);
    getSessionById(sessionId)
      .then((response) => {
        setSession(response.data);
      })
      .catch((_) => {
        console.log('can not find session id: ', sessionId);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    loadSession();
  }, []);

  const allFiles = React.useMemo(() => {
    const storybookFiles = getSessionStorybookFiles(session);
    const attachedFiles = getSessionAttachedFiles(session);
    return [...storybookFiles, ...attachedFiles];
  }, [session]);

  React.useEffect(() => {
    dispatch(actionDidLoadFileList({ files: allFiles }));
  }, [allFiles]);

  const onClose = () => {
    dispatch(actionCloseSSPStorybook());
    getSspControls()?.closeDocumentViewer?.();
  };

  const handleSelect = (fileId: string, type: 'storybook' | 'document') => {
    dispatch(actionSetActiveFile(fileId));
    const file = allFiles.find((f) => f.id === fileId);
    const name = file.title;
    dispatch(actionSetFileController(undefined));
    if (first(file?.pages)) {
      dispatch(
        actionSetActivePage({
          id: fileId,
          file: name,
          url: first(file?.pages),
          setting: first(file?.pagesSetting),
          silence: first(file?.silences)
        })
      );
    }
    const event = type === 'storybook' ? DID_SELECT_STORYBOOK : DID_SELECT_FILE;
    logEvent(event, event, {
      id: fileId,
      title: name
    });
  };

  const onOpenFile = (file: IFile) => {
    handleSelect(file.id, file.type);
  };

  if (loading)
    return (
      <div className="loading-container">
        <Spinner animation="border" variant="light" />
        <style jsx>{`
          .loading-container {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
      </div>
    );
  return (
    <>
      <MeetingFileListV2
        files={allFiles}
        onOpenFile={onOpenFile}
        onClose={onClose}
        onReload={loadSession}
      />

      <style jsx>{`
        .document-wrapper {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: white;
        }
      `}</style>
    </>
  );
}

export default StorybookSearchPopup;
