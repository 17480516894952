import React from 'react';
import { useSelector } from 'react-redux';
import { IMainState } from '../../../interfaces';
import DeviceWarning from './DeviceWarning';
import { zIndex } from '../../../config';
import { useStateRef } from '../../hooks/common';

function MeetingDeviceWarning({ timeout = 5000 }: { timeout?: number }) {
  const warning = useSelector(
    (state: IMainState) => state?.clientState?.meeting?.deviceWarning
  );
  const [list, setList, listRef] = useStateRef([]);
  React.useEffect(() => {
    if (warning) {
      const newList = [...list, warning];
      setList(newList);
    }
  }, [warning]);

  const removeItem = (id) => {
    const newList = listRef.current.filter((item) => item.id !== id);
    setList(newList);
  };
  return (
    <div className="device-warning">
      {list.map((item) => {
        return (
          <div className="device-warning-item" key={item.id}>
            <DeviceWarning
              message={item}
              timeout={timeout}
              onClose={removeItem}
            />
          </div>
        );
      })}
      <style jsx>{`
        .device-warning {
          position: fixed;
          bottom: 100px;
          left: 0;
          z-index: ${zIndex.deviceWarning};
          display: flex;
          gap: 5px;
          flex-direction: column;
        }
      `}</style>
    </div>
  );
}

export default MeetingDeviceWarning;
