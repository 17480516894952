import React from 'react';
import { BsSearch, BsX } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { sendInvitationToStoreDevice } from '../../../clientSideServices/store';
import { IMainState, IStoreDevice, IMeeting } from '../../../interfaces';
import { actionShowStudioJoinButton } from '../../../redux/actions';
import { actionGetStreamingStudioForBrandAsync } from '../../../redux/asyncActions';
import {
  DID_CLICK_INVITE_STORE_DEVICE_TO_MEETING_BUTTON,
  DID_FAIL_TO_INVITE_STORE_DEVICE_TO_MEETING,
  DID_INVITE_STORE_DEVICE_TO_MEETING
} from '../../../utils/constants';
import { BsChevronLeft } from 'react-icons/bs';
import { mapAssociatedDevicesToRegionAndCountry } from '../../../mappers/devices';
import { useTranslation } from '../../../i18n';
export enum StoreDeviceInvitationStatus {
  INITIAL = 'INITIAL',
  SENDING = 'SENDING',
  SENT = 'SENT',
  FAILED = 'FAILED'
}

export const ConnectingPopup = ({
  device,
  meeting,
  userName,
  onCancel
}: {
  device: IStoreDevice;
  meeting: IMeeting;
  userName: string;
  onCancel: () => void;
}) => {
  const [invitationStatus, setInvitationStatus] =
    React.useState<StoreDeviceInvitationStatus>(
      StoreDeviceInvitationStatus.INITIAL
    );
  const dispatch = useDispatch();
  const serverUrl = meeting.serverUrl || '';
  const btnExitRef = React.useRef<HTMLButtonElement>();
  const connectDevice = (device: IStoreDevice) => {
    logEvent(
      DID_CLICK_INVITE_STORE_DEVICE_TO_MEETING_BUTTON,
      DID_CLICK_INVITE_STORE_DEVICE_TO_MEETING_BUTTON,
      {
        deviceId: device.deviceId,
        meetingId: meeting.meetingId
      }
    );
    setInvitationStatus(StoreDeviceInvitationStatus.SENDING);
    sendInvitationToStoreDevice(
      device.deviceId,
      meeting.meetingId,
      `https://${serverUrl}`,
      userName
    )
      .then(() => {
        setInvitationStatus(StoreDeviceInvitationStatus.SENT);
        logEvent(
          DID_INVITE_STORE_DEVICE_TO_MEETING,
          DID_INVITE_STORE_DEVICE_TO_MEETING,
          {
            deviceId: device.deviceId,
            meetingId: meeting.meetingId
          }
        );
        setTimeout(() => {
          dispatch(actionShowStudioJoinButton(false));
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        setInvitationStatus(StoreDeviceInvitationStatus.FAILED);
        logEvent(
          DID_FAIL_TO_INVITE_STORE_DEVICE_TO_MEETING,
          DID_FAIL_TO_INVITE_STORE_DEVICE_TO_MEETING,
          {
            deviceId: device.deviceId,
            meetingId: meeting.meetingId,
            error
          }
        );
      });
  };

  React.useEffect(() => {
    connectDevice(device);
  }, []);

  React.useEffect(() => {
    if (invitationStatus === StoreDeviceInvitationStatus.SENT) {
      setTimeout(() => {
        btnExitRef?.current?.click();
      }, 3000);
    }
  }, [invitationStatus]);

  const getStatusText = () => {
    switch (invitationStatus) {
      case StoreDeviceInvitationStatus.INITIAL:
        return 'Ready to call';
      case StoreDeviceInvitationStatus.SENDING:
        return 'Calling...';
      case StoreDeviceInvitationStatus.SENT:
        return 'Called';
      case StoreDeviceInvitationStatus.FAILED:
        return 'Failed to connect';
    }
  };

  return (
    <>
      <div className="cube-connecting">
        <h4>{device?.name}</h4>
        <span className="mt-4 mb-4 status">{getStatusText()}</span>
        <div className="btn-wrapper d-flex justify-content-center">
          {invitationStatus === StoreDeviceInvitationStatus.FAILED && (
            <>
              <button
                className="btn mr-3"
                onClick={(e) => {
                  e.nativeEvent.stopImmediatePropagation();
                  connectDevice(device);
                }}
              >
                Retry
              </button>
              <button
                className="btn"
                onClick={(e) => {
                  e.nativeEvent.stopImmediatePropagation();
                  onCancel();
                }}
              >
                Cancel
              </button>
            </>
          )}
          <button
            className="btn btn-exist"
            ref={btnExitRef}
            onClick={() => {
              onCancel();
            }}
          >
            Exit
          </button>
        </div>
      </div>
      <style>
        {`
        .btn-exist {
          display: none;
        }
        .cube-connecting .status {
          font-size: 1.1rem;
          font-weight: 500;
        }
        .cube-connecting .btn {
          border-radius: 40px;
        }
        .cube-connecting {
          color: white;
          padding: 40px 20px;
          display: flex;
          flex-direction: column;
        }
        .cube-connecting .btn {
          color: white;
          border: 0.5px white solid;
        }`}
      </style>
    </>
  );
};

export const ConnectStreamingCubePopup = ({
  brandId,
  onClose
}: {
  brandId: string;
  onClose: () => void;
}) => {
  const [region, setRegion] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [selectedDevice, setSelectedDevice] =
    React.useState<IStoreDevice>(null);
  const [showCubes, setShowCubes] = React.useState(false);
  const [keywords, setKeywords] = React.useState('');
  const dispatch = useDispatch();
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const userName = useSelector(
    (state: IMainState) => state.clientState.user?.alias
  );
  const { t } = useTranslation();
  const associatedDevices = meeting.associatedDevices || [];

  React.useEffect(() => {
    dispatch(actionGetStreamingStudioForBrandAsync(brandId));
  }, []);

  React.useEffect(() => {
    if (country || keywords) {
      setShowCubes(true);
    }
  }, [country, keywords]);

  React.useEffect(() => {
    setCountry('');
  }, [region]);

  const clearSearch = () => setKeywords('');

  const filteredAssociatedDevices = React.useMemo(
    () =>
      associatedDevices.filter((device) => {
        const matchKeywords =
          !keywords ||
          device.name?.toLowerCase?.().includes(keywords.toLocaleLowerCase());
        const matchRegion = !region || device?.location.region === region;
        const matchCountry = !country || device?.location?.country === country;
        return matchKeywords && matchRegion && matchCountry;
      }),
    [associatedDevices, region, country, keywords]
  );

  const sortDeviceByStatus = (
    deviceOne: IStoreDevice,
    deviceTwo: IStoreDevice
  ) => {
    if (deviceOne.status === deviceTwo.status) {
      return deviceOne.name?.localeCompare(deviceTwo.name) || 1;
    }
    return deviceOne.status === 'ONLINE' ? -1 : 1;
  };

  const regions = React.useMemo(
    () => mapAssociatedDevicesToRegionAndCountry(associatedDevices || []) || {},
    [associatedDevices]
  );

  const allCountries = Object.keys(regions).reduce((countries, key) => {
    return [...countries, ...regions[key]];
  }, []);

  if (selectedDevice)
    return (
      <ConnectingPopup
        device={selectedDevice}
        meeting={meeting}
        userName={userName}
        onCancel={() => setSelectedDevice(null)}
      />
    );
  return (
    <>
      <div className="ConnectStreamingCubePopup">
        <div className="head d-flex justify-content-between align-items-center mb-3 mt-3">
          <h5 className="text-left mb-0">
            {t('find_streaming_cube_by_location')}
          </h5>
          <div className="btn btn-close" onClick={onClose}>
            <BsX size={24} />
          </div>
        </div>
        <div className="search position-relative mb-3">
          <label htmlFor="searchCubes">
            <BsSearch size={16} />
          </label>
          <input
            id="searchCubes"
            type="text"
            className="form-control"
            placeholder="Search Streaming Cube"
            value={keywords}
            autoComplete="off"
            onChange={(e) => setKeywords(e.target.value)}
          />
          <button className="btn" onClick={clearSearch}>
            <BsX />
          </button>
        </div>
        <div className="list row">
          <div className="col-xl-3 col-md-6 col-6 region-list">
            <span className="title">Region</span>
            <ul className="region">
              <li
                className={region === '' ? 'selected' : ''}
                onClick={() => setRegion('')}
              >
                <span>All</span>
              </li>
              {Object.keys(regions).map((_region) => (
                <li
                  className={region === _region ? 'selected' : ''}
                  key={_region}
                  onClick={() => {
                    setRegion(_region);
                  }}
                >
                  <span>{_region}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-xl-3 col-md-6 col-6 country-list">
            <span className="title">Country</span>
            <ul className="country">
              {(region ? regions?.[region] : allCountries).map((coun) => (
                <li
                  key={coun}
                  className={coun === country ? 'selected' : ''}
                  onClick={() => {
                    setCountry(coun);
                  }}
                >
                  <span>{coun}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-3 back">
            <button
              className="btn btn-sm back-button"
              onClick={() => setShowCubes(false)}
            >
              <BsChevronLeft />
              <span className="ml-2">Back</span>
            </button>
          </div>
          <div className="col-xl-6 col-md-9 col-9 cube-list">
            <span className="title">Streaming Cubes</span>
            <ul className="cubes">
              {!filteredAssociatedDevices?.length ? (
                <li className="not-found">
                  <span>Streaming Cube Not Found</span>
                </li>
              ) : (
                filteredAssociatedDevices
                  ?.sort(sortDeviceByStatus)
                  .map((device) => (
                    <li key={device.deviceId}>
                      <button
                        disabled={device.status !== 'ONLINE'}
                        onClick={(e) => {
                          e.nativeEvent.stopImmediatePropagation();
                          setSelectedDevice(device);
                        }}
                        className="list-group-item list-group-item-action text-left"
                      >
                        {device.name}
                      </button>
                    </li>
                  ))
              )}
            </ul>
          </div>
        </div>
      </div>
      <style jsx>{`
        .ConnectStreamingCubePopup {
          color: #fff;
          display: flex;
          flex-direction: column;
        }
        .btn-close {
          color: #fff;
        }
        .search input {
          background: transparent;
          border-radius: 100px;
          padding: 0 50px;
          color: #fff;
        }

        .search label,
        .search .btn {
          line-height: 16px;
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
        }
        .search .btn {
          display: ${keywords?.trim() ? 'block' : 'none'};
          left: unset;
          right: 0;
          color: white;
        }
        span.title {
          display: block;
          color: #747a80;
          margin-bottom: 0.7rem;
        }
        .list {
          display: flex;
          justify-content: space-between;
        }
        .list > * {
          text-align: left;
        }
        .list > div {
          border-right: 1px solid #212529;
        }
        .list > div:last-child {
          border-right: unset;
        }
        ul {
          list-style: none;
          padding: 0;
          text-align: left;
          height: 500px;
          max-height: 50vh;
          overflow: auto;
        }
        ul::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
          background-color: #000;
        }

        ul::-webkit-scrollbar {
          width: 5px;
          background-color: #000;
        }

        ul::-webkit-scrollbar-thumb {
          background-color: #fff;
          border: 2px solid #636363;
        }

        ul li {
          border-bottom: 1px solid #212529;
          padding: 10px 0;
          cursor: pointer;
        }

        li span,
        li button {
          font-size: 0.9rem;
          font-weight: 500;
          padding: 0;
          padding-left: 10px;
          display: block;
        }
        li.not-found {
          color: #747a80;
          border-bottom: unset !important;
          cursor: default !important;
        }
        li.selected span {
          background: #636363;
          border-radius: 50px;
        }

        .back {
          display: none;
        }

        @media (max-width: ${1199}px) {
          .country-list,
          .region-list {
            display: ${!showCubes ? 'block' : 'none'};
          }
          .list > div:nth-child(2) {
            border-right: unset;
          }
          .back,
          .cube-list {
            display: ${showCubes ? 'block' : 'none'};
          }
          .back-button {
            color: white;
            display: flex;
            align-items: center;
          }
        }
      `}</style>
    </>
  );
};
