import { takeRight, uniqBy } from 'lodash';
import { AnyAction, Dispatch } from 'redux';
import { logEvent } from '../../../../analytics';
import { MeetingRole } from '../../../../interfaces';
import { actionLoadMeetChat } from '../../../../redux/actions';
import { DID_LOAD_CHAT_HISTORY } from '../../../../utils/constants';
import { getIdentityId } from '../../../../utils/identity';
import { wrapActionToBeMirroring } from '../../../../utils/meeting';
import { isStringExceedMaxByteLimit } from '../../../../utils/string';
import { isValidMessage } from '../../../VirtualBoutique/Appointment/inputValidator';
import {
  getChatHistoryByMeetingId,
  saveParticipantsToLocalStorage
} from './chatStorage';

export enum IMeetingChatStatus {
  SENT = 'SENT',
  PENDING = 'PENDING',
  READ = 'READ'
}
export interface IMeetingChatBody {
  text: string;
  payload?: AnyAction;
}
export interface IMeetingChat {
  id: string;
  from: string;
  timeStamp: string;
  to?: string;
  quote?: string;
  status?: IMeetingChatStatus;
  body: IMeetingChatBody;
}

export interface MessageForBubble {
  id: string;
  senderName: string;
  time: string;
  text: string;
  isFromAdvisor?: boolean;
  isMine?: boolean;
  isHeader?: boolean;
  colorIdentifier?: string;
  status?: 'offline' | 'online';
  quote?: string;
}

export interface ChatParticipant {
  participantId: string;
  identityId: string;
  displayName?: string;
  role?: MeetingRole;
  status?: 'offline' | 'online';
}

const getOfflineParticipants = (
  newParticipants: ChatParticipant[],
  previousParticipants: ChatParticipant[]
): ChatParticipant[] => {
  const missingParticipants = previousParticipants?.filter(
    (p) => !newParticipants?.map((pA) => pA?.identityId).includes(p?.identityId)
  );

  return missingParticipants.map((p) => ({ ...p, status: 'offline' }));
};

const getNewComerIds = (
  newParticipants: ChatParticipant[],
  previousParticipants: ChatParticipant[]
): string[] => {
  const onlineParticipantIds = previousParticipants
    .filter((p) => p.status !== 'offline')
    .map((p) => p.identityId);

  const newComer = newParticipants.filter(
    (p) => !onlineParticipantIds.includes(p.identityId)
  );
  return newComer.map((n) => n.participantId);
};

const getChatToSync = (
  chat: IMeetingChat[],
  to: string,
  advisorId: string,
  isPresenter: boolean
) => {
  let chatToSync = [];
  const myPublicAndPrivateChat = chat.filter(
    (msg) => msg.to === '' || msg.to === to
  );
  if (isPresenter) {
    chatToSync = myPublicAndPrivateChat;
  } else {
    if (advisorId === to) {
      chatToSync = myPublicAndPrivateChat;
    } else {
      chatToSync = getChatByFromTo(chat, undefined, to);
    }
  }
  return takeRight(chatToSync, 20);
};

const handleSyncChatForNewComers = (param: {
  newComerIds: string[];
  chat: IMeetingChat[];
  dispatch: Dispatch;
  advisorId?: string;
  isPresenter?: boolean;
}) => {
  const myChat = getChatByFromTo(param.chat, getIdentityId());
  if (!myChat.length) return;

  param.newComerIds.forEach((to) => {
    const chatToSync = getChatToSync(
      param.chat,
      to,
      param.advisorId,
      param.isPresenter
    );
    wrapActionToBeMirroring(param.dispatch, actionLoadMeetChat(chatToSync), {
      to,
      mirrorOnly: true
    });
  });
};

export const handleChatParticipant = (
  newParticipants: ChatParticipant[],
  previousParticipants: ChatParticipant[],
  setChatParticipants: (p: ChatParticipant[]) => void,
  dispatch: Dispatch,
  chat: IMeetingChat[],
  meetingId: string,
  isPresenter?: boolean
) => {
  const offlineParticipants = getOfflineParticipants(
    newParticipants,
    previousParticipants
  );
  const updatedParticipants = uniqBy(
    [...newParticipants, ...offlineParticipants],
    (p) => p?.identityId
  );
  setChatParticipants(updatedParticipants);
  saveParticipantsToLocalStorage(updatedParticipants, meetingId);
  const newComerIds = getNewComerIds(newParticipants, previousParticipants);

  if (newComerIds.length && chat.length) {
    handleSyncChatForNewComers({
      dispatch,
      chat,
      newComerIds,
      advisorId: newParticipants.find((p) => p.role === MeetingRole.ADVISOR)
        ?.participantId,
      isPresenter
    });
  }
};

export const getChatByFromTo = (
  chat: IMeetingChat[],
  from?: string,
  to?: string
) => {
  const hasFrom = from !== undefined;
  const hasTo = to !== undefined;
  if (hasFrom && hasTo) {
    return chat.filter((msg) => msg.from === from && msg.to === to);
  }
  if (hasFrom && !hasTo) {
    return chat.filter((msg) => msg.from === from);
  }
  if (!hasFrom && hasTo) {
    return chat.filter((msg) => msg.to === to);
  }

  return chat;
};

export const loadChatHistory = (meetingId: string, dispatch: Dispatch) => {
  const chatHistory = getChatHistoryByMeetingId(meetingId);
  if (chatHistory?.chat?.length) {
    dispatch(actionLoadMeetChat(chatHistory.chat));

    const myPublicChat = getChatByFromTo(chatHistory.chat, getIdentityId(), '');

    wrapActionToBeMirroring(dispatch, actionLoadMeetChat(myPublicChat), {
      to: '',
      mirrorOnly: true
    });

    logEvent(DID_LOAD_CHAT_HISTORY, DID_LOAD_CHAT_HISTORY, { meetingId });
  }
};

export const isChatMessageValid = (message: IMeetingChatBody) => {
  const isExceedByteLimit = isStringExceedMaxByteLimit(
    JSON.stringify(message),
    3000
  );
  const isValidText = isValidMessage(message.text);
  return !isExceedByteLimit && isValidText;
};
