import React from 'react';
import { useSelector } from 'react-redux';
import { IMainState, MeetingLayoutMode, MeetingRole } from '../../interfaces';
import RemoteVideo from './MeetingVideos/RemoteVideo';
import { logEvent } from '../../analytics';
import { DID_CLICK_SHOW_STUDIO_VIDEO_PANEL } from '../../utils/constants';
import MeetingButtonWithIcon from './MeetingControls/MeetingButtonWithIcon';
import { IconStudio } from './MeetingControls/MeetingIcons';
import { meetingColor } from '../../utils/meeting';
import { zIndex } from '../../config';
import { SMGlobalSpec } from './MeetingLayout/SMGlobal';
import { MDGlobalSpec } from './MeetingLayout/MDGlobal';
import { MDPortraitNormalSpec } from './MeetingLayout/MDPortraitNormal';
import { SMPortraitNormalSpec } from './MeetingLayout/SMPortraitNormal';

const StudioVideosPanel = ({
  onStop
}: {
  onStop: (studioId: string) => void;
}) => {
  const [show, setShow] = React.useState(false);
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const isTileLayout = meeting?.layout?.mode === MeetingLayoutMode.TILE;
  const isShowingClientDetail = meeting?.showClientDetailPanel;

  const remoteUsers = meeting?.remoteUsers || {};
  const studios = Object.keys(remoteUsers).filter(
    (participantId) =>
      remoteUsers[participantId]?.shouldShowVideo &&
      remoteUsers[participantId]?.role === MeetingRole.STUDIO
  );

  const handleShow = () => {
    setShow(!show);
    logEvent(
      DID_CLICK_SHOW_STUDIO_VIDEO_PANEL,
      DID_CLICK_SHOW_STUDIO_VIDEO_PANEL
    );
  };

  if (!studios?.length) return null;
  return (
    <div className={`StudioVideosPanel ${show ? 'show' : ''}`}>
      <MeetingButtonWithIcon className="toggle-button" onClick={handleShow}>
        <IconStudio />
      </MeetingButtonWithIcon>
      <div
        className={`studio-container ${isTileLayout ? ' tile-mode' : ''}${
          isShowingClientDetail ? ' has-panel' : ''
        } `}
      >
        <div className="scroll-container">
          {studios.map((participantId) => (
            <React.Fragment key={participantId}>
              <RemoteVideo participantId={participantId} />
              <button
                className="btn btn-sm btn-disconnect"
                onClick={() => onStop(participantId)}
              >
                DISCONNECT
              </button>
            </React.Fragment>
          ))}
        </div>
      </div>
      <style jsx global>{`
        .has-enlarged .StudioVideosPanel {
          display: none !important;
        }
        .SM .StudioVideosPanel {
          top: ${SMGlobalSpec.logoSectionHeight}px;
        }
        .MD .StudioVideosPanel {
          top: ${MDGlobalSpec.logoSectionHeight}px;
        }
        .SM .StudioVideosPanel .video-thumbnail {
          width: 120px !important;
          height: 120px !important;
        }
        .MD .StudioVideosPanel .video-thumbnail {
          width: 200px !important;
          height: 200px !important;
        }
        .SMLandscape .StudioVideosPanel,
        .MDLandscape .StudioVideosPanel {
          bottom: 0 !important;
        }
        .SMPortrait .StudioVideosPanel {
          bottom: ${SMPortraitNormalSpec.contentArea.bottom}px;
        }
        .MDPortrait .StudioVideosPanel {
          bottom: ${MDPortraitNormalSpec.contentArea.bottom}px;
        }
      `}</style>
      <style jsx>{`
        .StudioVideosPanel {
          position: fixed;
          transform: translateX(100%);
          right: 0;
          display: flex;
          align-items: center;
          transition: all 0.3s ease-in-out;
          animation: initial-show 2s ease-in-out both;
          z-index: ${zIndex.meetingControls - 2};
        }
        .StudioVideosPanel.show {
          transform: translateX(0%);
        }

        .StudioVideosPanel .studio-container {
          overflow: auto;
          padding: 10px;
          padding-top: 0;
          height: 100%;
          display: flex;
          align-items: center;
          background: ${meetingColor.transparentDark};
        }
        .scroll-container {
          margin: auto;
        }
        .StudioVideosPanel :global(.video-thumbnail) {
          margin: 0 !important;
          padding: 0 !important;
          display: block;
          margin-top: 10px !important;
        }

        .StudioVideosPanel :global(.indicators-container) {
          display: none !important;
        }
        .StudioVideosPanel :global(.toggle-button) {
          background: ${meetingColor.transparentDark};
          padding: 5px;
          border-radius: 5px 0 0 5px;
          margin-right: 0;
          margin-left: -43px;
        }

        .StudioVideosPanel :global(.toggle-button svg) {
          width: 34px;
          height: auto;
        }

        .btn-disconnect {
          border: 1px solid ${meetingColor.warning};
          color: ${meetingColor.warning};
          margin-top: 1px;
          width: 100%;
          border-radius: 5px;
        }
      `}</style>
    </div>
  );
};

export default StudioVideosPanel;
