import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../analytics';
import { useTranslation } from '../../i18n';
import { IMainState, IProduct, Source } from '../../interfaces';
import {
  actionFetchParticipantInterestedProductsAsync,
  actionFetchParticipantWishlistAsync
} from '../../redux/asyncActions';
import {
  DID_SELECT_PRODUCT_IN_MEETING_CLIENT_DETAILS_PANEL,
  DID_VIEW_ALL_PRODUCTS_IN_MEETING_CLIENT_DETAILS_PANEL
} from '../../utils/constants';
import ClientDetailInterestedProducts from './ClientDetailInterestedProducts';
import ClientDetailProductsPanel from './ClientDetailProductsPanel';
import ClientDetailWishlist from './ClientDetailWishlist';
import PrivatePopup from './PrivatePopup';

export enum ClientDetailSection {
  WISHLIST = 'WISHLIST',
  INTERESTED_PRODUCTS = 'INTERESTED_PRODUCTS',
  PURCHASED_PRODUCTS = 'PURCHASED_PRODUCTS'
}

const ClientDetail = ({
  storeId,
  meetingId
}: {
  storeId: string;
  meetingId: string;
}) => {
  const videoRef = React.useRef();
  const [showPopup, setShowPopup] = React.useState(false);
  const [popupUrl, setPopupUrl] = React.useState<string>();
  const [productsPanel, setProductsPanel] = React.useState<{
    title?: string;
    products?: IProduct[];
    type?: ClientDetailSection;
  }>({});

  const dispatch = useDispatch();
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const remoteUsers = meeting?.remoteUsers || {};
  const participantId = meeting?.clientDetailPanelParticipantId;
  const showPanel = meeting?.showClientDetailPanel;
  const participantRemoteTracks = remoteUsers[participantId]?.tracks || [];
  const identityId = remoteUsers[participantId]?.identityId;

  const handleSelectProduct = (
    product: IProduct,
    type: ClientDetailSection
  ) => {
    setPopupUrl(
      `${location.protocol}//${location.host}/products/${product.id}?source=${Source.Showroom}&store=${storeId}`
    );
    setShowPopup(true);
    logEvent(
      DID_SELECT_PRODUCT_IN_MEETING_CLIENT_DETAILS_PANEL,
      DID_SELECT_PRODUCT_IN_MEETING_CLIENT_DETAILS_PANEL,
      { productId: product.id, meetingId, type }
    );
  };

  const handleViewAll = (
    products: IProduct[],
    title: string,
    type: ClientDetailSection
  ) => {
    setProductsPanel({ title, products, type });
    logEvent(
      DID_VIEW_ALL_PRODUCTS_IN_MEETING_CLIENT_DETAILS_PANEL,
      DID_VIEW_ALL_PRODUCTS_IN_MEETING_CLIENT_DETAILS_PANEL,
      { meetingId, type }
    );
  };
  React.useEffect(() => {
    if (meeting?.showClientDetailPanel) {
      dispatch(
        actionFetchParticipantWishlistAsync(participantId, identityId, storeId)
      );
      dispatch(
        actionFetchParticipantInterestedProductsAsync(
          participantId,
          identityId,
          storeId
        )
      );
    }
  }, [meeting?.showClientDetailPanel]);

  React.useEffect(() => {
    if (!showPanel) setProductsPanel({});
    for (let i = 0; i < participantRemoteTracks.length; i++) {
      if (
        participantRemoteTracks[i].getType() === 'video' &&
        videoRef.current
      ) {
        console.log('attaching remote track in detail panel');
        participantRemoteTracks[i].attach(videoRef.current);
      }
    }
  }, [participantId, showPanel]);

  const { t } = useTranslation();

  return (
    <div className="client-panel-container">
      <div className="content">
        {remoteUsers[participantId]?.displayName && (
          <h3>{remoteUsers[participantId]?.displayName}</h3>
        )}

        <ClientDetailWishlist
          title={t('wishlist').toUpperCase()}
          onClick={(product) => {
            handleSelectProduct(product, ClientDetailSection.WISHLIST);
          }}
          onViewAll={handleViewAll}
        />

        <ClientDetailInterestedProducts
          title={t('interested_in').toUpperCase()}
          onClick={(product) => {
            handleSelectProduct(
              product,
              ClientDetailSection.INTERESTED_PRODUCTS
            );
          }}
          onViewAll={handleViewAll}
        />

        {productsPanel?.products && (
          <ClientDetailProductsPanel
            title={productsPanel.title}
            products={productsPanel.products}
            onClick={(product) => {
              handleSelectProduct(product, productsPanel.type);
            }}
            onClose={() => setProductsPanel({})}
          />
        )}
      </div>

      <video ref={videoRef} autoPlay playsInline />
      <PrivatePopup
        show={showPopup}
        url={popupUrl}
        onClose={() => setShowPopup(false)}
      />
      <style jsx>
        {`
          .client-panel-container {
            color: #ccc;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            z-index:1999;
          }

          .content {
            padding: 30px;
            overflow-y: scroll;
            flex-grow: 1;
            -ms-overflow-style: none; /* Internet Explorer 10+ */
            scrollbar-width: none; /* Firefox */
          }

          .content::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }

          video {
            max-height: 300px;
            width: 100%;
          }

          h3 {
            font-size: 2em;
            font-weight: 300;
            margin: 10px 0px;
            color: #999;
        }
          }

          p {
            font-size: 18px;
            margin: 0;
          }
        `}
      </style>
    </div>
  );
};

export default ClientDetail;
