import React from 'react';
import { useDispatch } from 'react-redux';
import {
  ClientState,
  MeetingNotification,
  NotificationType
} from '../../../interfaces';
import {
  actionHideMeetingNotification,
  actionShowMeetingNotification
} from './../../../redux/actions';
import { useTranslation } from '../../../i18n';
import NotificationPopup from '../../NotificationPopup';
import {
  IconCameraOff,
  IconInvisible,
  IconMicrophoneOff
} from '../MeetingControls/MeetingIcons';
import { getLocalAudioTrack, getLocalVideoTrack } from '../utils/track';
import { onMeetingOverlay } from '../../../utils/meeting';

interface Props {
  clientState: ClientState;
  onToggleVideo: () => void;
  onToggleAudio: () => void;
}

const MeetingAlert = ({ clientState, onToggleVideo, onToggleAudio }: Props) => {
  const dispatch = useDispatch();
  const meetingState = clientState?.meeting;
  const isPlayingVideo = clientState?.fileViewer?.playerControl?.play;
  const notifications = meetingState?.notifications;
  const toggleNotification = (
    notification: MeetingNotification,
    show?: boolean
  ) => {
    if (show) {
      dispatch(actionShowMeetingNotification(notification));
    } else {
      dispatch(actionHideMeetingNotification(notification));
    }
  };

  React.useEffect(() => {
    toggleNotification(
      MeetingNotification.MUTED_VIDEO,
      meetingState?.localUser?.videoMuted
    );
  }, [meetingState?.localUser?.videoMuted]);

  React.useEffect(() => {
    toggleNotification(
      MeetingNotification.MUTED_AUDIO,
      meetingState?.localUser?.audioMuted
    );
  }, [meetingState?.localUser?.audioMuted]);

  React.useEffect(() => {
    const tracks = meetingState?.localUser?.tracks;
    const devices = meetingState?.localUser?.availableDevices || [];
    const hasCam = devices.some((device) => device.kind === 'videoinput');
    const hasMic = devices.some((device) => device.kind === 'audioinput');
    toggleNotification(
      MeetingNotification.VIDEO_DISCONNECTED,
      !getLocalVideoTrack(tracks) && !hasCam
    );
    toggleNotification(
      MeetingNotification.AUDIO_DISCONNECTED,
      !getLocalAudioTrack(tracks) && !hasMic
    );
  }, [
    meetingState?.localUser?.tracks,
    meetingState?.localUser?.availableDevices
  ]);

  const { t } = useTranslation();

  const [
    showMuteAudio,
    showDisconnectAudio,
    showMuteVideo,
    showDisconnectVideo
  ] = [
    notifications?.includes(MeetingNotification.MUTED_AUDIO),
    notifications?.includes(MeetingNotification.AUDIO_DISCONNECTED),
    notifications?.includes(MeetingNotification.MUTED_VIDEO),
    notifications?.includes(MeetingNotification.VIDEO_DISCONNECTED)
  ];

  return (
    <>
      {clientState?.meeting?.isOverlayImgVisible &&
        meetingState?.isPresenter && (
          <NotificationPopup
            type={NotificationType.INFO}
            label={'Overlay is visible to client'}
            okLabel="Hide"
            onOk={() => onMeetingOverlay(dispatch, clientState?.meeting, false)}
            autoHide={false}
            icon={<IconInvisible />}
          />
        )}
      {showMuteAudio && !showDisconnectAudio && (
        <NotificationPopup
          type={NotificationType.WARNING}
          label={isPlayingVideo ? t('mic_muted_by_video') : t('mic_muted')}
          okLabel={t('unmute')}
          onOk={() => {
            onToggleAudio();
            toggleNotification(MeetingNotification.MUTED_AUDIO);
          }}
          autoHide={true}
          icon={<IconMicrophoneOff />}
        />
      )}

      {showDisconnectAudio && (
        <NotificationPopup
          type={NotificationType.WARNING}
          label={t('mic_disconnect')}
          icon={<IconMicrophoneOff />}
        />
      )}

      {showMuteVideo && !showDisconnectVideo && (
        <NotificationPopup
          type={NotificationType.WARNING}
          label={t('cam_muted')}
          okLabel={t('switch_on')}
          onOk={() => {
            onToggleVideo();
            toggleNotification(MeetingNotification.MUTED_VIDEO);
          }}
          autoHide={true}
          icon={<IconCameraOff />}
        />
      )}

      {showDisconnectVideo && (
        <NotificationPopup
          type={NotificationType.WARNING}
          label={t('cam_disconnect')}
          icon={<IconCameraOff />}
        />
      )}
    </>
  );
};

export default MeetingAlert;
