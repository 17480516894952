import React from 'react';
import { logEvent } from '../../../analytics';
import {
  COPY_LINK_FAILED,
  COPY_LINK_SUCCESSFUL
} from '../../../utils/constants';
import { getBrowserInfo } from '../../../utils/deviceDetector';
import { MdDone, MdError } from 'react-icons/md';

function GeneralBrowserPrompt() {
  const assetsUrl = 'https://assets.inspify.com/lounge';
  const meetLogoUrl = `${assetsUrl}/meet-logo-white.png`;
  const backgroundUrl = `${assetsUrl}/handshake-white.png`;
  const browserInfo = getBrowserInfo();
  const browserName = browserInfo.getBrowserName();
  const browserVersion = browserInfo.getBrowserVersion();
  const osVersion = browserInfo.getOSVersion();
  const osName = browserInfo.getOSName();
  const [copied, setCopied] = React.useState(false);
  const [copyError, setCopyError] = React.useState(false);
  const meetingUrl = location.href;
  const listSupportedBrowsers = [
    { name: 'Chrome', version: '72.0.0' },
    { name: 'Firefox', version: '68.0.0' },
    { name: 'Safari', version: '14.0.0' },
    { name: 'Edge', version: '79.0.0' }
  ];
  const onCopyError = (e = {}) => {
    setCopyError(true);
    logEvent(COPY_LINK_FAILED, COPY_LINK_FAILED, {
      urlToCopy: meetingUrl,
      error: e
    });
  };
  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(meetingUrl)
        .then(() => {
          setCopied(true);
          logEvent(COPY_LINK_SUCCESSFUL, COPY_LINK_SUCCESSFUL, {
            urlToCopy: meetingUrl
          });
        })
        .catch(onCopyError);
    } else {
      onCopyError('Navigator clipboard unavailable');
    }
  };

  React.useEffect(() => {
    let timeoutId;
    if (copied) {
      timeoutId = setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [copied]);

  React.useEffect(() => {
    let timeoutId;
    if (copyError) {
      timeoutId = setTimeout(() => {
        setCopyError(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [copyError]);

  return (
    <>
      <div className="browsers-prompt">
        <div className="content">
          <div className="logo">
            <img src={meetLogoUrl} alt="logo" />
          </div>
          <div className="texts">
            <span className="warn">
              <b>
                {browserName} {browserVersion}
              </b>{' '}
              on{' '}
              <b>
                {osName} {osVersion}
              </b>{' '}
              is currently not supported.
            </span>
            <span className="prompt">
              Please copy and paste the Meet link on a supported browser
            </span>
          </div>
          <table className="supported-browsers table-sm">
            <thead>
              <tr>
                <th scope="col" className="text-left">
                  Browser
                </th>
                <th scope="col" className="text-right">
                  Version
                </th>
              </tr>
            </thead>
            <tbody>
              {listSupportedBrowsers.map((browser, index) => (
                <tr key={index}>
                  <td className="text-left">{browser.name}</td>
                  <td className="text-right">{browser.version}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="action">
            <button className="btn btn-copy" onClick={handleCopy}>
              Copy Link
            </button>
          </div>
        </div>
        {copied && (
          <div className="alert copy-success">
            <MdDone color="lightgreen" size={24} />
            <span>Copied the link</span>
          </div>
        )}
        {copyError && (
          <span className="alert copy-error">
            <MdError color="darkred" size={24} />
            <span>
              Failed to copy! <br /> Please copy the link manually from the
              address bar
            </span>
          </span>
        )}
        <div className="gradient background"></div>
        <div className="image background"></div>
      </div>
      <style jsx>{`
        .browsers-prompt {
          position: relative;
          height: 100vh;
          z-index: 3;
          display: flex;
          justify-content: center;
          color: #fff;
        }
        .texts .prompt {
          color: #8d8d8d;
        }
        .warn {
          font-weight: 400;
          font-size: 1.05rem;
        }
        .content {
          padding: 0 20px;
          position: relative;
          z-index: 2;
          margin-top: 100px;
          padding-bottom: 50px;
        }

        .alert {
          position: fixed;
          width: 400px;
          max-width: 95vw;
          padding: 10px;
          top: 10px;
          z-index: 10;
          background-color: #fff;
          animation: swipe 0.6s ease-in-out;
        }
        @keyframes swipe {
          0% {
            top: -100%;
          }
          50% {
            top: -90%;
          }
          90% {
            top: 30px;
          }
          100% {
            top: 20px;
          }
        }
        .copy-error {
          color: darkred;
        }
        .copy-error :global(svg) {
          margin-right: 10px;
        }
        .copy-success span {
          margin-left: 10px;
          color: black;
        }
        .texts {
          display: flex;
          flex-direction: column;
        }
        .supported-browsers {
          color: white;
          margin: 20px auto;
          width: 300px;
          max-width: 95vw !important;
          border-collapse: collapse;
        }

        .supported-browsers thead {
          color: #8d8d8d;
        }
        .supported-browsers thead th {
          font-weight: 400;
        }
        .supported-browsers th:first-child {
          text-align: left;
        }
        .action {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
        .supported-browsers tr td {
          border-bottom: 1px solid #8d8d8d;
        }
        .btn-copy {
          background: #8f6599;
          color: white;
          border-radius: 30px;
        }
        .background {
          z-index: -1;
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
        .gradient.background {
          background: radial-gradient(
            123.89% 123.89% at 50% 123.89%,
            #0c2831 0.24%,
            #000000 100%
          );
          transform: rotate(180deg);
        }
        .image.background {
          background-image: url(${backgroundUrl});
          background-repeat: no-repeat;
          mix-blend-mode: screen;
          background-position-x: center;
          background-position-y: bottom;
        }
        @media (orientation: portrait) and (max-width: 400px) {
          .content {
            margin-top: 20px;
          }
        }
        @media only screen and (max-width: 768px) {
          .image.background {
            background-size: 160%;
            bottom: 40px;
            background-position-y: bottom;
          }
        }

        @media only screen and (max-height: 700px) {
          .image.background {
            background-size: 100%;
          }
        }

        @media (orientation: portrait) and (max-height: 540px) {
          .image.background {
            width: 50%;
          }
          .content {
            padding: 20px !important;
            margin-top: 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 50px;
          }
          .content .logo {
            grid-row-start: 1;
            grid-row-end: 2;
          }

          .content .texts {
            grid-column-start: 1;
          }
          .content .supported-browsers {
            grid-column-start: 2;
            grid-row-start: 1;
            grid-row-end: 3;
          }
          .content .action {
            grid-column-start: 2;
          }
        }
        @media (orientation: landscape) and (max-height: 900px) and (min-width: 1024px) {
          .image.background {
            background-size: 70%;
            bottom: 20px;
            background-position-y: bottom;
          }
        }
        @media (orientation: landscape) and (max-height: 700px) {
          .content {
            margin-top: 20px;
          }
          .image.background {
            opacity: 0.6;
          }
        }

        @media (orientation: landscape) and (max-height: 500px) {
          .image.background {
            width: 50%;
            display: block;
          }
          .content {
            padding: 20px !important;
            margin-top: 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 50px;
          }
          .content .logo {
            grid-row-start: 1;
            grid-row-end: 2;
          }

          .content .texts {
            grid-column-start: 1;
          }
          .content .supported-browsers {
            grid-column-start: 2;
            grid-row-start: 1;
            grid-row-end: 3;
          }
          .content .action {
            grid-column-start: 2;
          }
        }
        @media (orientation: landscape) and (max-height: 380px) {
          .image.background {
            opacity: 0.6;
          }
        }
        @media (orientation: portrait) and (max-height: 730px) {
          .image.background {
            opacity: 0.6;
          }
        }
      `}</style>
    </>
  );
}

export default GeneralBrowserPrompt;
