const MeetOnlyBrandStyle = () => {
  return (
    <style jsx global>{`
      .vb-lite.vb-page-wrapper {
        background-size: cover !important;
        background-position: center !important;
      }

      .vb-lite .options-container,
      .meeting-controls .connect-streaming-studio,
      #connectStudio,
      #searchProduct,
      #compareProduct,
      #searchStories {
        display: none !important;
      }
    `}</style>
  );
};

export default MeetOnlyBrandStyle;
