import { sortBy } from 'lodash';
import { IMeetingLocalUser, MeetingRole } from '../../../../interfaces';
import { getFormattedClock } from '../../../../utils/clock';
import { getIdentityId } from '../../../../utils/identity';
import { IMeetingChat, ChatParticipant, MessageForBubble } from './index';

const mapMeetingChatMessagesForBubble = (
  message: IMeetingChat,
  participants: ChatParticipant[],
  myParticipantInfo: ChatParticipant,
  colorIdentifier: { [key: string]: string },
  previousMessage: IMeetingChat
): MessageForBubble => {
  const isMine = message.from === myParticipantInfo?.identityId;
  const sender = participants.find((user) => user?.identityId === message.from);
  const color = colorIdentifier[sender?.identityId];
  const senderName = sender?.displayName || (color ? 'Guest' : 'Offline Guest');
  const isFromAdvisor = sender?.role === MeetingRole.ADVISOR;

  return {
    id: message.id,
    senderName,
    time: getFormattedClock(message.timeStamp),
    text: message?.body?.text,
    isMine,
    isFromAdvisor,
    isHeader: previousMessage ? message.from !== previousMessage.from : true,
    colorIdentifier: color || '#a9a9a9',
    status: sender?.status,
    quote: message.quote
  };
};

export const mapChatMessage = (
  chat: IMeetingChat[],
  participants: ChatParticipant[],
  myParticipantInfo: ChatParticipant,
  colorIdentifier: { [key: string]: string }
) => {
  const sortedChat = sortBy(chat, (msg) => msg.timeStamp);
  const mapped = sortedChat?.map((message, index) =>
    mapMeetingChatMessagesForBubble(
      message,
      participants,
      myParticipantInfo,
      colorIdentifier,
      sortedChat[index - 1]
    )
  );
  return mapped;
};

export const generateParticipantColorIdentifier = (
  currentColors: { [key: string]: string },
  chatParticipants: ChatParticipant[]
): { [key: string]: string } => {
  const copy = { ...currentColors };
  chatParticipants.forEach(({ identityId, role }) => {
    if (!currentColors[identityId]) {
      copy[identityId] =
        role === MeetingRole.ADVISOR
          ? '#000'
          : `hsl(${Math.floor(Math.random() * 360)}, 64%, 40%)`;
    }
  });
  return copy;
};

export const mapParticipantsToChatParticipants = ({
  remoteUsers,
  localUser,
  advisorParticipantId,
  advisorDisplayName,
  localDisplayName,
  myIdentityId
}: {
  remoteUsers: any;
  localUser: IMeetingLocalUser;
  advisorParticipantId: string;
  advisorDisplayName: string;
  localDisplayName: string;
  myIdentityId: string;
}): ChatParticipant[] => {
  const remoteParticipants: ChatParticipant[] = Object.keys(remoteUsers || {}).map(
    (participantId) => {
      const { displayName, role, shouldShowVideo } =
        remoteUsers[participantId] || {};
      if (
        role !== MeetingRole.STUDIO &&
        shouldShowVideo &&
        remoteUsers[participantId]?.identityId
      ) {
        return {
          participantId,
          identityId: remoteUsers[participantId]?.identityId,
          displayName:
            (participantId === advisorParticipantId
              ? displayName || advisorDisplayName || 'Host'
              : displayName) || 'Unknown',
          role,
          status: 'online'
        };
      }
    }
  );

  const localParticipant: ChatParticipant = {
    displayName: localDisplayName || 'You',
    participantId: localUser.participantId,
    identityId: myIdentityId || getIdentityId(),
    role: localUser.role,
    status: 'online'
  };

  return [...remoteParticipants, localParticipant].filter(
    (user) => user?.identityId
  );
};
