import React from 'react';
import { ProgressBar, Spinner } from 'react-bootstrap';
import { BiErrorCircle } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { zIndex } from '../../config';
import { IMainState, IReconnectState } from '../../interfaces';
import { MEETING_RECONNECT_STATE } from '../../utils/constants';
import { trySetLocalStorage } from '../../utils/window';
import { useStateRef } from '../hooks/common';
import PoweredByInspify from '../Legal/PoweredByInspify';

const AUTO_RECONNECT_TIME = 5;
function Disconnected({ logoUrl }: { logoUrl: string }) {
  const [imageError, setImageError] = React.useState(false);
  const [isReconnecting, setIsReconnecting, isReconnectingRef] =
    useStateRef(false);
  const clientState =
    useSelector((state: IMainState) => state?.clientState) || {};
  const meeting = clientState.meeting;
  const onReconnect = () => {
    if (!isReconnectingRef.current) window.location.reload();
    setIsReconnecting(true);
  };

  const currentState: IReconnectState = React.useMemo(
    () => ({
      layout: meeting?.layout,
      id: meeting?.meetingId,
      ts: Date.now(),
      displayName: clientState?.user?.alias,
      meetingState: meeting?.state,
      videoMuted: meeting?.localUser?.videoMuted || false,
      audioMuted: meeting?.localUser?.audioMuted || false,
      vBackground: meeting?.localUser?.videoEffect?.effect,
      participantId: meeting?.localUser?.participantId
    }),
    [meeting, clientState?.user?.alias]
  );

  React.useEffect(() => {
    trySetLocalStorage(MEETING_RECONNECT_STATE, JSON.stringify(currentState));
    const countdown = setTimeout(onReconnect, 5000);
    return () => {
      clearTimeout(countdown);
    };
  }, []);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <>
      <div className="Disconnected">
        <div className="content-inner">
          <div className="logo">
            {!imageError ? (
              <img src={logoUrl} alt="logo" onError={handleImageError} />
            ) : (
              <BiErrorCircle size={50} />
            )}
          </div>
          <h2>You have been disconnected</h2>
          <span>
            Reconnect automatically in
            {` ${AUTO_RECONNECT_TIME} seconds`}
          </span>
          <div className="count-down">
            <ProgressBar color="black" min={0} max={AUTO_RECONNECT_TIME} />
          </div>
          <button onClick={onReconnect} className="btn btn-dark btn-round">
            {isReconnecting ? (
              <Spinner size="sm" animation="border" />
            ) : (
              'Reconnect now'
            )}
          </button>
        </div>
        <div className="powered-by-wrapper">
          <PoweredByInspify color="light" position="center center" />
        </div>
      </div>
      <style jsx>
        {`
          .Disconnected {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 20px;
            display: flex;
            position: fixed;
            background: #000;
            align-items: center;
            justify-content: center;
            z-index: ${zIndex.disconnectedPopup};
          }
          .powered-by-wrapper {
            position: absolute;
            bottom: 15px;
            left: 0;
            right: 0;
          }
          .powered-by-wrapper :global(.powered-by-inspify) {
            height: 35px;
          }
          .content-inner {
            width: 700px;
            padding: 65px 20px;
            max-width: 100%;
            background: #fff;
            border-radius: 5px;
          }
          .logo {
            color: #000;
          }
          .logo img {
            height: 50px;
            max-width: 100%;
          }
          h2 {
            color: black;
            font-size: 1.3em;
            margin: 30px 0px 7px 0px;
          }
          span {
            font-weight: 500;
            font-size: 1.2em;
          }
          .count-down {
            margin: 30px auto;
            max-width: 300px;
          }
          .btn-dark {
            background: #000;
          }
          .btn-round {
            border-radius: 50px;
          }
          :global(.progress) {
            background: #e5e5e5;
            height: 7px;
            border-radius: 20px;
          }
          :global(.progress-bar) {
            background: #000;
            animation: countdown linear ${AUTO_RECONNECT_TIME}s forwards;
          }
          @keyframes countdown {
            from {
              width: 0%;
            }
            to {
              width: 100%;
            }
          }
          @media (min-width: 1024px) {
            h2 {
              font-size: 1.4em;
            }
            .powered-by-wrapper {
              bottom: 30px;
            }
            .powered-by-wrapper :global(.powered-by-inspify) {
              height: 50px;
            }
          }
        `}
      </style>
    </>
  );
}

export default Disconnected;
