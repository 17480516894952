import React from 'react';
import { meetingColor } from '../../../utils/meeting';
import { QRCode } from './../../QRCode';

const ScanStudioPopup = ({
  url,
  children = null,
  qrTitle
}: {
  url: string;
  children?: React.ReactNode;
  qrTitle: string;
}) => {
  return (
    <div className="ScanStudioPopup">
      <div className="panel-container">
        <div className="qr-container">
          <QRCode value={url} size={150} title={qrTitle} />
          {children}
        </div>
      </div>
      <style jsx>{`
        .ScanStudioPopup {
          padding-top: 10px;
          padding-bottom: 10px;
          border-radius: 10px;
          background: ${meetingColor.transparentDark};
        }

        .ScanStudioPopup :global(.btnGetUrl) {
          width: 100%;
          margin-top: 10px;
        }
        .qr-container {
          padding: 0 10px;
        }
        .panel-container {
          color: #fff;
        }
        .panel-container :global(.store-device-title) {
          color: #fff !important;
        }
        .panel-container :global(.qr-panel) {
          box-shadow: none;
          border-radius: 5px;
          width: 100%;
          padding-bottom: 10px;
        }
      `}</style>
    </div>
  );
};

export default ScanStudioPopup;
