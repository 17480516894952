import { Transition } from 'react-transition-group';
import { zIndex } from '../../config';

const PrivatePopup = ({
  show,
  url,
  onClose
}: {
  show: boolean;
  url: string;
  onClose: () => void;
}) => {
  return (
    <Transition in={show} timeout={300} mountOnEnter unmountOnExit>
      {(state) => (
        <div
          className={`private-popup-page ${state}`}
          onClick={() => {
            onClose();
          }}
        >
          <div className="private-popup-page-content">
            <button
              className={`close`}
              type="button"
              onClick={() => {
                onClose();
              }}
            >
              &times;
            </button>
            <iframe src={url} className="iframe-container" id="content-popup" />
          </div>
          <style jsx>
            {`
              .private-popup-page {
                position: fixed;
                z-index: ${zIndex.popupPage};
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                height: 100vh;
                width: 100vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.8);
                transition: all 0.3s ease-in-out;
              }

              .private-popup-page-content {
                position: relative;
                margin: auto;
                display: block;
                text-align: center;
                overflow: hidden;
                width:100%;
              }

              .private-popup-page .close {
                position: absolute;
                right: 5px;
                top: 0;
                color: #a0a0a0;
                background-color: transparent;
                padding: 0;
                width: 45px;
                height: 45px;
                font-size: 24px;
              }

              .private-popup-page .iframe-container {
                width: 100%;
                height: 83vh;
                border: none;
                border-radius: 5px;
                background: #fff;
              }

              .private-popup-page.entering {
                opacity: 0;
              }
              .private-popup-page.entered {
                opacity: 1;
              }
              .private-popup-page.exiting {
                opacity: 0;
              }
              .private-popup-page.exited {
                opacity: 0;
              }

              @media (min-width: 768px) {
                .private-popup-page .iframe-container {
                  width: 80vw;
                  height: 80vh;
                }
                .private-popup-page-content{
                  width:80vw;
                }
                .private-popup-page .close {
                  right: 10px;
                  width: 50px;
                  height: 50px;
                }
              }

              @media (max-height: 800px) and (min-width: 768px) {
                .private-popup-page .iframe-container {
                  height: 98vh;
                }
              }
            `}
          </style>
        </div>
      )}
    </Transition>
  );
};

export default PrivatePopup;
