import React from 'react';
import { iwcUaeDubaiBoutiqueId } from '../../config';
import { headerPropsForVirtualBoutique } from '../../utils/htmlHeader';
import HTMLHeader from '../HTMLHeader';
import HTMLMeetingHeader from '../Meeting/HTMLMeetingHeader';
import { ISessionDetails, IVirtualBoutiqueConfig } from './../../interfaces';
import Script from 'next/script';
const VBHTMLHeader = ({
  config,
  storeId,
  isInCockpitMode,
  session
}: {
  config: IVirtualBoutiqueConfig;
  storeId: string;
  isInCockpitMode?: boolean;
  session?: ISessionDetails;
}) => {
  const baseScript = (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap"
        rel="stylesheet"
      />
      <Script
        src={config.pano?.jsLibUrl || '/libs/tour.js'}
        strategy="beforeInteractive"
      ></Script>
      <Script
        src={'/libs/hls_0.14.16/hls.min.js'}
        strategy="beforeInteractive"
      ></Script>
      {config.entitlement?.friendsShopping && (
        <Script
          src="/libs/external_api.js"
          strategy="beforeInteractive"
        ></Script>
      )}
      {storeId === iwcUaeDubaiBoutiqueId && (
        <Script
          src="https://cdn.checkout.com/js/framesv2.min.js"
          strategy="beforeInteractive"
        ></Script>
      )}
    </>
  );

  return (
    <>
      {baseScript}
      {isInCockpitMode ? (
        <HTMLMeetingHeader
          config={config}
          session={session}
          storeId={storeId}
        />
      ) : (
        <HTMLHeader
          {...headerPropsForVirtualBoutique(config)}
          storeId={storeId}
        />
      )}
    </>
  );
};

export default VBHTMLHeader;
