import React from 'react';
import { BiCheck, BiX } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../../analytics';
import { useTranslation } from '../../../i18n';
import {
  IMainState,
  MeetingLayoutMode,
  MeetingRole
} from '../../../interfaces';
import {
  actionAddLayoutPresenter,
  actionHideClientDetailPanel,
  actionKickParticipantFromMeeting,
  actionMuteParticipantAudioTrack,
  actionOpenParticipantControls,
  actionRemoveLayoutPresenter,
  actionRequestParticipantUserInfo,
  actionShowClientDetailPanel,
  actionToggleEnlargeVideo,
  actionToggleParticipantVisibility,
  actionTogglePinParticipant,
  actionTransferPresenterRole,
  actionUnmuteParticipantAudioTrack
} from '../../../redux/actions';
import {
  CANCELED_TO_KICK_PARTICIPANT,
  DID_ADD_PARTICIPANT_AS_PRESENTER,
  DID_CLICK_KICK_PARTICIPANT,
  DID_ENLARGE_VIDEO,
  DID_HIDE_MEETING_STREAMING_STUDIO_FULL_SCREEN,
  DID_KICK_PARTICIPANT,
  DID_MUTE_PARTICIPANT,
  DID_PIN_PARTICIPANT,
  DID_REMOVE_PARTICIPANT_AS_PRESENTER,
  DID_SHOW_MEETING_CLIENT_DETAILS_PANEL,
  DID_SHOW_MEETING_STREAMING_STUDIO_FULL_SCREEN,
  DID_TOGGLE_PARTICIPANT_ACTIONS_MENU,
  DID_TOGGLE_PARTICIPANT_VISIBILITY,
  DID_TRANSFER_PRESENTER_ROLE,
  DID_UNMUTE_PARTICIPANT,
  DID_UNPIN_PARTICIPANT
} from '../../../utils/constants';
import { meetingColor } from '../../../utils/meeting';
import ToolTipWrapper from '../../Common/ToolTipWrapper';
import {
  IconEdit,
  IconEnlarge,
  IconInsights,
  IconInvisible,
  IconMicrophoneOff,
  IconMicrophoneOn,
  IconPin,
  IconPresenter,
  IconProductCompare,
  IconRemove,
  IconVisible
} from '../MeetingControls/MeetingIcons';

const MeetingParticipantControls = ({
  participantId,
  setUserName
}: {
  participantId: string;
  setUserName: (name: string) => void;
}) => {
  const dispatch = useDispatch();
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const myDisplayName = useSelector(
    (state: IMainState) => state.clientState.user?.alias
  );
  const meetingId = meeting.meetingId;
  const isLocalUser = participantId === meeting.localUser?.participantId;
  const meetingLayout = meeting?.layout;
  const remoteUsers = meeting?.remoteUsers || {};
  const participantInfo = isLocalUser
    ? meeting.localUser || {}
    : (meeting.remoteUsers || {})[participantId] || {};

  const localUserRole = meeting.localUser?.role;
  const remoteUserRole = remoteUsers[participantId]?.role;

  const role = isLocalUser ? localUserRole : remoteUserRole;
  const isRemoteStudio = role === MeetingRole.STUDIO;
  const isParticipantPresenter =
    meetingLayout?.presenterIds?.includes(participantId);
  const isEnlarged =
    meetingLayout?.enlargedVideoParticipantId === participantId;

  const handleClose = () => {
    dispatch(actionOpenParticipantControls(null));
    logEvent(
      DID_TOGGLE_PARTICIPANT_ACTIONS_MENU,
      DID_TOGGLE_PARTICIPANT_ACTIONS_MENU,
      {
        participantId,
        open: false
      }
    );
  };

  const [kickConfirmation, setKickConfirmation] = React.useState(false);
  const [showNameInput, setShowNameInput] = React.useState(false);

  const onTogglePresenter = () => {
    if (isParticipantPresenter) {
      dispatch(actionRemoveLayoutPresenter(participantId));
      logEvent(
        DID_REMOVE_PARTICIPANT_AS_PRESENTER,
        DID_REMOVE_PARTICIPANT_AS_PRESENTER,
        {
          participantId,
          meetingId,
          role
        }
      );
    } else {
      dispatch(actionAddLayoutPresenter(participantId));
      logEvent(
        DID_ADD_PARTICIPANT_AS_PRESENTER,
        DID_ADD_PARTICIPANT_AS_PRESENTER,
        {
          participantId,
          meetingId,
          role
        }
      );
    }
  };

  const onToggleEnlarge = () => {
    handleClose();
    const shouldEnlarge =
      meetingLayout?.enlargedVideoParticipantId !== participantId;
    dispatch(
      actionToggleEnlargeVideo({
        participantId,
        shouldEnlarge
      })
    );

    logEvent(DID_ENLARGE_VIDEO, DID_ENLARGE_VIDEO, {
      participantId,
      meetingId,
      isEnlarged: shouldEnlarge
    });

    if (isRemoteStudio) {
      const event = isEnlarged
        ? DID_HIDE_MEETING_STREAMING_STUDIO_FULL_SCREEN
        : DID_SHOW_MEETING_STREAMING_STUDIO_FULL_SCREEN;
      logEvent(event, event, { meetingId, participantId });
    }
  };

  const onViewClientInfo = () => {
    handleClose();
    if (meeting.showClientDetailPanel) {
      dispatch(actionHideClientDetailPanel());
      return;
    }
    dispatch(actionShowClientDetailPanel(participantId));

    if (!meeting.remoteUsers[participantId]?.identityId) {
      dispatch(actionRequestParticipantUserInfo());
    }
    logEvent(
      DID_SHOW_MEETING_CLIENT_DETAILS_PANEL,
      DID_SHOW_MEETING_CLIENT_DETAILS_PANEL,
      { selectedParticipant: participantId, meetingId, role }
    );
  };

  const logKickParticipant = (event) => {
    logEvent(event, event, {
      participantId,
      meetingId,
      role
    });
  };

  const onKickParticipant = () => {
    handleClose();
    dispatch(actionKickParticipantFromMeeting({ participantId }));
    logKickParticipant(DID_KICK_PARTICIPANT);
  };

  const isParticipantCurrentlyInvisible =
    !!meetingLayout.invisibleParticipantIds?.includes(participantId);

  const onToggleVisibility = () => {
    dispatch(
      actionToggleParticipantVisibility({
        participantId,
        toggleToVisible: isParticipantCurrentlyInvisible
      })
    );
    logEvent(
      DID_TOGGLE_PARTICIPANT_VISIBILITY,
      DID_TOGGLE_PARTICIPANT_VISIBILITY,
      {
        participantId,
        meetingId,
        role,
        toggleToVisible: isParticipantCurrentlyInvisible
      }
    );
  };

  const onClickTransferPresenterRole = () => {
    dispatch(actionTransferPresenterRole({ participantId }));
    logEvent(DID_TRANSFER_PRESENTER_ROLE, DID_TRANSFER_PRESENTER_ROLE, {
      meetingId,
      participantId,
      role
    });
  };

  const muteParticipant = () => {
    dispatch(actionMuteParticipantAudioTrack({ participantId }));
    logEvent(DID_MUTE_PARTICIPANT, DID_MUTE_PARTICIPANT, {
      participantId,
      meetingId
    });
  };

  const unmuteParticipant = () => {
    dispatch(actionUnmuteParticipantAudioTrack({ participantId }));
    logEvent(DID_UNMUTE_PARTICIPANT, DID_UNMUTE_PARTICIPANT, {
      participantId,
      meetingId
    });
  };

  const shouldShowViewUserInfo =
    !isLocalUser &&
    role === MeetingRole.CLIENT &&
    meetingLayout?.mode !== MeetingLayoutMode.PRESENTATION &&
    localUserRole === MeetingRole.ADVISOR;

  const shouldShowKickButton =
    !isLocalUser &&
    !isRemoteStudio &&
    role !== MeetingRole.ADVISOR &&
    meeting.advisorParticipantId !== participantId &&
    !(isLocalUser && role === MeetingRole.CLIENT);

  const isPinned = (meeting.layout?.pinnedParticipantIds || []).includes(
    participantId
  );

  const shouldShowMuteButton =
    !isLocalUser &&
    !isRemoteStudio &&
    meeting.advisorParticipantId !== participantId;

  const onPin = () => {
    if (isPinned) {
      logEvent(DID_UNPIN_PARTICIPANT, DID_UNPIN_PARTICIPANT, { participantId });
    } else {
      logEvent(DID_PIN_PARTICIPANT, DID_PIN_PARTICIPANT, { participantId });
    }
    dispatch(actionTogglePinParticipant(participantId));
    handleClose();
  };

  const { t } = useTranslation();

  const displayName = isLocalUser
    ? myDisplayName || t('enter_your_name')
    : remoteUsers?.[participantId]?.displayName || 'Guest';

  const displayNameRef: React.RefObject<HTMLInputElement> = React.useRef();

  const handleChangeName = () => {
    const name = displayNameRef.current?.value;
    const hasInput = name && name.trim().length > 0;
    if (hasInput && name !== displayName) {
      setUserName(name);
      displayNameRef.current.value = '';
    }
    setShowNameInput(false);
    handleClose();
  };

  const editNameComponent = (
    <div className="form">
      <div className="form-group">
        <input
          type="text"
          autoFocus
          ref={displayNameRef}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              handleChangeName();
            }
          }}
          placeholder={myDisplayName || ''}
          className="form-control"
        />

        <div className="btn-group">
          <button
            className="btn btn-outline-dark text-success"
            onClick={handleChangeName}
          >
            <BiCheck />
          </button>
          {myDisplayName && (
            <button
              className="btn btn-outline-dark text-danger"
              onClick={() => setShowNameInput(false)}
            >
              <BiX />
            </button>
          )}
        </div>
      </div>
      <style jsx>{`
        .btn-group {
          width: 100%;
          margin-top: 3px;
        }
      `}</style>
    </div>
  );

  return (
    <ToolTipWrapper
      onClose={handleClose}
      parentId={!isEnlarged ? `video-${participantId}` : undefined}
      width={250}
    >
      <div
        className={`ClientControls control-menu text-left ${
          isEnlarged ? 'enlarge-mode' : ''
        }`}
        id="client-controls-popup"
      >
        {!isRemoteStudio && (
          <>
            <div className="actions">
              <div className="inner">
                <div
                  className="list-group-item device-title align-items-center userAlias"
                >
                  <strong>{displayName}</strong>
                </div>

                <button
                  onClick={() => {
                    if (isLocalUser) {
                      setShowNameInput(true);
                    }
                  }}
                  className={`list-group-item list-group-item-action editName ${
                    isLocalUser && myDisplayName && !showNameInput
                      ? 'show'
                      : 'hide'
                  }`}
                >
                  <IconEdit /> {t('edit_my_name')}
                </button>

                {isLocalUser &&
                  (showNameInput || !myDisplayName) &&
                  editNameComponent}

                {!isParticipantCurrentlyInvisible &&
                  !(isLocalUser && role === MeetingRole.CLIENT) && (
                    <button
                      className="list-group-item list-group-item-action enlargeVideo"
                      onClick={onToggleEnlarge}
                    >
                      <IconEnlarge /> {t('vb:toggle_enlarge')}
                    </button>
                  )}

                {!isRemoteStudio &&
                  !(isLocalUser && role === MeetingRole.CLIENT) &&
                  meetingLayout?.mode !== MeetingLayoutMode.TILE &&
                  participantId !== meetingLayout?.enlargedVideoParticipantId &&
                  !isParticipantCurrentlyInvisible && (
                    <button
                      className={`list-group-item list-group-item-action setAsPresenter ${
                        isParticipantPresenter && 'text-danger'
                      }`}
                      onClick={() => {
                        handleClose();
                        onTogglePresenter();
                      }}
                    >
                      <IconPresenter
                        fill={
                          isParticipantPresenter
                            ? meetingColor.warning
                            : 'white'
                        }
                      />
                      {isParticipantPresenter
                        ? t('vb:remove_from_presenter')
                        : t('vb:set_as_presenter')}
                    </button>
                  )}

                {!isEnlarged && shouldShowKickButton && (
                  <button
                    className={`list-group-item list-group-item-action pinParticipant ${
                      isPinned && 'text-danger'
                    }`}
                    onClick={onPin}
                  >
                    <IconPin fill={isPinned ? meetingColor.warning : 'white'} />
                    {isPinned ? 'Unpin' : 'Pin'}
                  </button>
                )}

                {shouldShowViewUserInfo && (
                  <button
                    className="list-group-item list-group-item-action viewUserInfo"
                    onClick={onViewClientInfo}
                  >
                    <IconInsights />
                    {t('vb:view_user_info')}
                  </button>
                )}

                {shouldShowMuteButton && (
                  <button
                    className={`list-group-item list-group-item-action muteParticipantAudio ${
                      participantInfo.audioMuted && 'text-success'
                    }`}
                    onClick={() => {
                      participantInfo.audioMuted
                        ? unmuteParticipant()
                        : muteParticipant();
                    }}
                  >
                    {participantInfo.audioMuted ? (
                      <IconMicrophoneOff fill={meetingColor.success} />
                    ) : (
                      <IconMicrophoneOn />
                    )}
                    {participantInfo.audioMuted
                      ? t('vb:unmute_audio')
                      : t('vb:mute_audio')}
                  </button>
                )}

                {!isLocalUser &&
                  (role === MeetingRole.CO_ADVISOR ||
                    role === MeetingRole.ADVISOR) && (
                    <button
                      className="list-group-item list-group-item-action transferPresenterRole"
                      onClick={onClickTransferPresenterRole}
                    >
                      <IconProductCompare />
                      {t('vb:transfer_presenter_role')}
                    </button>
                  )}

                {!isRemoteStudio &&
                  role !== MeetingRole.ADVISOR &&
                  !(isLocalUser && role === MeetingRole.CLIENT) &&
                  !isEnlarged && (
                    <button
                      className={`list-group-item list-group-item-action toggleVisibility ${
                        isParticipantCurrentlyInvisible && 'text-success'
                      }`}
                      onClick={onToggleVisibility}
                    >
                      {isParticipantCurrentlyInvisible ? (
                        <IconInvisible fill={meetingColor.success} />
                      ) : (
                        <IconVisible />
                      )}
                      {isParticipantCurrentlyInvisible
                        ? t('vb:make_visible')
                        : t('vb:make_invisible')}
                    </button>
                  )}

                {shouldShowKickButton && (
                  <>
                    <div
                      className={`list-group-item list-group-item-action action-group ${
                        kickConfirmation ? 'show' : 'hide'
                      }`}
                    >
                      <button
                        className="text-danger kickParticipant"
                        onClick={onKickParticipant}
                      >
                        {t('remove')}
                      </button>
                      <button
                        className="text-success kickCanceled"
                        onClick={() => {
                          setKickConfirmation(false);
                          logKickParticipant(CANCELED_TO_KICK_PARTICIPANT);
                        }}
                      >
                        {t('keep')}
                      </button>
                    </div>

                    <button
                      className={`list-group-item list-group-item-action text-danger willKickParticipant ${
                        kickConfirmation ? 'hide' : 'show'
                      }`}
                      onClick={() => {
                        setKickConfirmation(true);
                        logKickParticipant(DID_CLICK_KICK_PARTICIPANT);
                      }}
                    >
                      <IconRemove fill={meetingColor.warning} />
                      {t('vb:remove_from_meeting')}
                    </button>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <style jsx>{`
          .hide {
            display: none !important;
          }
          .action-group {
            display: flex;
            justify-content: space-between;
          }
          .action-group button {
            background: none;
            border: none;
          }
          .device-title {
            cursor: pointer;
          }
          .device-title > * {
            pointer-events: none;
          }
          .device-title span {
            margin-left: 5px;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 40px;
            color: #ccc;
          }
        `}</style>
      </div>
    </ToolTipWrapper>
  );
};

export default MeetingParticipantControls;
