import React from 'react';
import { logEvent } from '../../../analytics';
import { allBrands } from '../../../config';
import { DID_CLICK_ON_HEALTH_CHECK_BUTTON } from '../../../utils/constants';
import { SessionContext } from '../../VirtualBoutique/SessionContextContainer';

const getCurrentHost = () => {
  if (typeof window !== 'undefined') {
    return window.location.host;
  }
  return '';
};

const SessionHealthCheckButton = ({ dark }: { dark?: boolean }) => {
  const { session } = React.useContext(SessionContext);
  const brandId = session?.brand;
  const region = session?.region || '';
  const urlHost =
    allBrands.find((config) => config.id === brandId)?.host || getCurrentHost();

  return urlHost ? (
    <>
      <a
        className={`${dark && 'dark'}`}
        onClick={() => {
          logEvent(DID_CLICK_ON_HEALTH_CHECK_BUTTON);
        }}
        href={`https://${urlHost}/meeting/healthcheck?transport=wss&region=${region}`}
        target="_blank"
      >
        Click to ensure your device is ready for the session
      </a>
      <style jsx>
        {`
          a {
            font-size: 0.8rem;
            color: #fff;
            padding: 10px 15px;
            border: 1px solid #fff;
            border-radius: 5px;
            position: fixed;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            text-transform: uppercase;
            text-align: center;
          }

          a.dark {
            color: #000000;
            border-color: #000000;
          }

          a:hover {
            text-decoration: none;
          }

          @media (max-width: 768px) {
            a {
              font-size: 12px;
              width: 60vw;
            }
          }
        `}
      </style>
    </>
  ) : null;
};

export default SessionHealthCheckButton;
