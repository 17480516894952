import React from 'react';
import { useSelector } from 'react-redux';
import {
  IMainState,
  IMeetingLocalUser,
  IMeetingRemoteUsers,
  MeetingLayoutMode,
  MeetingLayout,
  MeetingRole
} from '../../../interfaces';
import LocalVideo from './LocalVideo';
import { MeetingContext } from '../MeetingContext';
import RemoteVideo from './RemoteVideo';
import { zIndex } from '../../../config';
import MDTile from '../MeetingLayout/MDTile';
import SMTile from './../MeetingLayout/SMTile';
import MDPortraitNormal from '../MeetingLayout/MDPortraitNormal';
import SMPortraitNormal from './../MeetingLayout/SMPortraitNormal';
import { ViewportContext } from '../MeetingLayout';

const VideosContainer = ({
  meetingLayout = {},
  remoteUsers = {},
  localUser,
  canControlMeeting
}: {
  meetingLayout?: MeetingLayout;
  remoteUsers?: IMeetingRemoteUsers;
  localUser: IMeetingLocalUser;
  canControlMeeting?: boolean;
}) => {
  const { room } = React.useContext(MeetingContext);
  const { isPresentingContent } = React.useContext(ViewportContext);
  const { presentationBackgroundImageUrl } =
    useSelector(
      (state: IMainState) => state.clientState?.virtualBoutiqueConfig
    ) || {};
  const isPresentationLayout =
    meetingLayout?.mode !== MeetingLayoutMode.TILE || isPresentingContent;
  const myId = room?.myUserId();
  const presenterIds = meetingLayout?.presenterIds || [];
  const enlargedVideoParticipantId = meetingLayout?.enlargedVideoParticipantId;
  const isInFullScreenMode = !!enlargedVideoParticipantId;
  const pinnedParticipants = meetingLayout?.pinnedParticipantIds || [];
  const notInvisibleParticipantInPresentation = (participantId: string) => {
    const shouldHide =
      participantId !== myId &&
      !canControlMeeting &&
      meetingLayout?.invisibleParticipantIds?.includes(participantId);

    return !shouldHide;
  };

  const notPresenterAndEnlargedParticipantInPresentation = (
    participantId: string
  ) => {
    return (
      participantId !== meetingLayout?.enlargedVideoParticipantId &&
      !presenterIds.includes(participantId) &&
      !Object.values(meetingLayout?.oneThirdLayout || {}).includes(
        participantId
      )
    );
  };

  const shouldShowLocalVideo = () => {
    if (isInFullScreenMode) {
      return (
        localUser?.participantId !== enlargedVideoParticipantId &&
        !presenterIds?.includes(localUser?.participantId)
      );
    }
    if (isPresentationLayout) {
      return notPresenterAndEnlargedParticipantInPresentation(myId);
    }

    return true;
  };
  const shouldShowRemoteVideo = (participantId: string) => {
    if (
      !canControlMeeting &&
      meetingLayout?.invisibleParticipantIds?.includes(participantId)
    ) {
      return false;
    }
    if (isInFullScreenMode) {
      return participantId !== enlargedVideoParticipantId;
    }
    return true;
  };

  const shouldShowRemoteVideoInPresentation = (participantId: string) => {
    return (
      notInvisibleParticipantInPresentation(participantId) &&
      notPresenterAndEnlargedParticipantInPresentation(participantId) &&
      remoteUsers[participantId]?.role !== MeetingRole.STUDIO
    );
  };

  const remoteUsersVideo = (pinned?: boolean) => {
    return Object.keys(remoteUsers)
      .filter(
        (participantId) =>
          remoteUsers[participantId]?.shouldShowVideo &&
          remoteUsers[participantId].role !== MeetingRole.STUDIO
      )
      .filter((participantId) =>
        pinned
          ? pinnedParticipants.includes(participantId)
          : !pinnedParticipants.includes(participantId)
      )
      .filter(
        isPresentationLayout
          ? shouldShowRemoteVideoInPresentation
          : shouldShowRemoteVideo
      )
      .map((participantId) => (
        <RemoteVideo participantId={participantId} key={participantId} />
      ));
  };
  const presenterVideosComponent = (
    <>
      {myId &&
        presenterIds?.includes(myId) &&
        meetingLayout?.enlargedVideoParticipantId !== myId && <LocalVideo />}
      {presenterIds
        .filter(
          (participantId) =>
            participantId !== meetingLayout?.enlargedVideoParticipantId
        )
        .filter((participantId) => participantId !== myId)
        .filter(notInvisibleParticipantInPresentation)
        .map((participantId) => (
          <RemoteVideo participantId={participantId} key={participantId} />
        ))}
    </>
  );

  const localVideoComponent = shouldShowLocalVideo() && (
    <div id="local-wrapper">
      <LocalVideo />
    </div>
  );

  const participantVideosComponent = (
    <>
      {remoteUsersVideo(true)}
      {remoteUsersVideo()}
    </>
  );
  const totalVideos = React.useMemo(
    () =>
      Object.keys(remoteUsers)
        .filter(
          (participantId) =>
            remoteUsers[participantId]?.shouldShowVideo &&
            remoteUsers[participantId]?.role !== MeetingRole.STUDIO
        )
        .filter(shouldShowRemoteVideo).length + 1,
    [remoteUsers]
  );

  return (
    <>
      {isPresentationLayout && localVideoComponent}
      <div id="videos-wrapper">
        <div className="scroll-container">
          {isPresentationLayout && (
            <div id="presenter-wrapper">{presenterVideosComponent}</div>
          )}
          <div id="participant-wrapper">
            {!isPresentationLayout && localVideoComponent}
            {participantVideosComponent}
          </div>
        </div>
      </div>
      <div className="video-wrapper-bg" />
      {isPresentationLayout && presentationBackgroundImageUrl && (
        <div className="main-presentation-area" />
      )}
      <MDPortraitNormal />
      <SMPortraitNormal
        totalVideos={totalVideos}
        totalPresenters={presenterIds?.length}
      />
      {!isPresentationLayout && (
        <>
          <SMTile totalVideos={totalVideos} />
          <MDTile totalVideos={totalVideos} />
        </>
      )}

      <style jsx>{`
        #videos-wrapper {
          position: fixed;
          z-index: ${zIndex.meetingVideosWrapper};
        }
      `}</style>
    </>
  );
};

export default VideosContainer;
