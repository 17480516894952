import React from 'react';
import {
  VscChromeClose,
  VscChromeMaximize,
  VscChromeMinimize,
  VscChromeRestore
} from 'react-icons/vsc';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import DocumentsViewer from '.';
import { logEvent } from '../../analytics';
import { zIndex } from '../../config';
import { FileViewerLayout, IMeeting } from '../../interfaces';
import {
  actionHideMeetingControls,
  actionParticipantSetActivePage,
  actionRequestUpdateFileViewerLayout,
  actionSetActivePage,
  actionSetMeetingLayoutState,
  actionUpdateFileViewerLayout
} from '../../redux/actions';
import {
  DID_CHANGE_DOCUMENT_VIEWER_LAYOUT,
  DID_CLOSE_DOCUMENT_VIEWER
} from '../../utils/constants';
import { getFileViewerLayoutFromMeetingLayout } from '../../utils/file';
import { wrapActionToBeMirroring } from '../../utils/meeting';
import { useFileView } from '../hooks/fileViewer';
import { useViewportInfo } from '../hooks/ux';
import { useSspState } from '../hooks/common';
import { TLanguage } from '../../mappers/polotno';

const DocumentViewerPopup = ({
  meeting = {},
  onClose,
  activateLanguage
}: {
  meeting?: IMeeting;
  onClose?: () => void;
  activateLanguage?: TLanguage;
}) => {
  const dispatch = useDispatch();
  const props = useFileView();
  const meetingLayoutMode = meeting?.layout?.mode;
  const [prevLayout, setPrevLayout] = React.useState<FileViewerLayout>();
  const { width, height } = useViewportInfo();
  const isPortrait = React.useMemo(() => width + 50 < height, [width, height]);
  const isMinimize = props?.layoutMode === FileViewerLayout.MINIMIZE;
  const isFullScreen = props?.layoutMode === FileViewerLayout.FULLSCREEN;
  const pageOpened = !!props?.currentPage?.id;
  const shouldRequest = !props.viewOnly && !props.canControl;
  const isInSSP = useSspState();
  const restoreLayout = () => {
    if (pageOpened && prevLayout) {
      updateLayoutMode(prevLayout);
    }
  };

  const updateLayoutMode = (layout: FileViewerLayout) => {
    logEvent(
      DID_CHANGE_DOCUMENT_VIEWER_LAYOUT,
      DID_CHANGE_DOCUMENT_VIEWER_LAYOUT,
      { layout }
    );
    if (shouldRequest) {
      dispatch(actionRequestUpdateFileViewerLayout(layout));
      return;
    }
    dispatch(actionUpdateFileViewerLayout(layout));
  };

  const closeDocument = () => {
    if (shouldRequest) {
      dispatch(actionParticipantSetActivePage(null));
    }
    dispatch(actionSetActivePage(null));
    logEvent(DID_CLOSE_DOCUMENT_VIEWER, DID_CLOSE_DOCUMENT_VIEWER, {
      currentPage: props?.currentPage
    });
    onClose?.();
  };

  React.useEffect(() => {
    const layout = getFileViewerLayoutFromMeetingLayout(meetingLayoutMode);
    if (props.canControl) {
      updateLayoutMode(layout);
    }
  }, [meetingLayoutMode]);

  React.useEffect(() => {
    if (props?.layoutMode && !isMinimize) {
      setPrevLayout(props?.layoutMode);
    }
  }, [props?.layoutMode]);

  React.useEffect(() => {
    if (meeting?.layout?.enlargedVideoParticipantId && pageOpened) {
      updateLayoutMode(FileViewerLayout.MINIMIZE);
    } else {
      restoreLayout();
    }
  }, [meeting?.layout?.enlargedVideoParticipantId]);

  React.useEffect(() => {
    if (pageOpened) {
      dispatch(actionHideMeetingControls(true));
    }
    return () => {
      if (meeting.layoutToRestore && pageOpened) {
        wrapActionToBeMirroring(
          dispatch,
          actionSetMeetingLayoutState(meeting.layoutToRestore),
          { to: '' }
        );
      }
    };
  }, [pageOpened]);

  const showControl = props.canControl || !props.viewOnly;
  return (
    <Transition
      in={pageOpened || false}
      timeout={300}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <>
          <DocumentsViewer
            {...props}
            portraitContent={isPortrait}
            className={state}
            activateLanguage={activateLanguage}
          >
            {showControl && !isMinimize && (
              <div className={`popup-control ${state}`}>
                <button
                  className="btn btn-lg"
                  onClick={() => {
                    updateLayoutMode(FileViewerLayout.MINIMIZE);
                  }}
                >
                  <VscChromeMinimize color="#fff" />
                </button>
                <button
                  className={`btn btn-lg`}
                  onClick={() =>
                    updateLayoutMode(
                      isFullScreen
                        ? getFileViewerLayoutFromMeetingLayout(
                            meetingLayoutMode
                          )
                        : FileViewerLayout.FULLSCREEN
                    )
                  }
                >
                  {isFullScreen ? (
                    <VscChromeRestore color="#fff" />
                  ) : (
                    <VscChromeMaximize color="#fff" />
                  )}
                </button>
                <button
                  className="btn btn-lg btn-close"
                  onClick={closeDocument}
                >
                  <VscChromeClose color="#fff" />
                </button>
              </div>
            )}

            {showControl && isMinimize && (
              <div className={`btn-restore`} onClick={restoreLayout} />
            )}
          </DocumentsViewer>

          <style jsx>{`
            .popup-control {
              position: fixed;
              top: 0;
              right: 0;
              padding: 5px;
              z-index: ${props?.layoutMode === FileViewerLayout.FULLSCREEN ||
              props?.layoutMode === FileViewerLayout.PRESENTATION ||
              props?.playerControl?.play
                ? zIndex.meetingVideosWrapper + 1
                : zIndex.documentViewer};
            }

            .popup-control .btn:not(.btn-close) {
              visibility: ${isInSSP ? 'hidden' : 'visible'};
            }
            .popup-control.entering {
              opacity: 0;
            }
            .popup-control.entered {
              opacity: 1;
            }
            .popup-control.exiting {
              opacity: 0;
            }
            .popup-control.exited {
              opacity: 0;
            }

            .btn {
              outline: none !important;
              box-shadow: none !important;
              background: rgba(0, 0, 0, 0.4);
              padding: 0 10px 2px;
              margin: 5px;
            }
            :global(.vbssp) .btn {
              font-size: 30px;
              padding: 0 10px 8px;
            }
            @media (max-width: 768px) {
              .popup-control .btn > :global(svg) {
                width: 23px;
                height: 23px;
              }
            }
          `}</style>
        </>
      )}
    </Transition>
  );
};

export default DocumentViewerPopup;
