import { useRouter } from 'next/router';
import React from 'react';
import { zIndex } from '../../../config';
import { IMeetingStats, MeetingRole } from '../../../interfaces';
import LocalVideo from './LocalVideo';
import RemoteVideo from './RemoteVideo';

const EnlargedVideo = ({
  isLocalVideoEnlarged,
  participantId,
  onToggleEnlarge,
  shouldShowCloseButton,
  localRole,
  stats,
  resolution,
  showEnlargedGrid,
  isOverlayImgVisible
}: {
  isLocalVideoEnlarged: boolean;
  participantId: string;
  onToggleEnlarge: () => void;
  shouldShowCloseButton: boolean;
  localRole: MeetingRole;
  stats?: IMeetingStats;
  resolution: {
    [key: string]: {
      height: number;
      width: number;
    };
  };
  showEnlargedGrid?: boolean;
  isOverlayImgVisible?: boolean;
}) => {
  const router = useRouter();

  const getEnlargedParticipantBitrate = () => {
    if (localRole === MeetingRole.STUDIO) return null;

    const { download, upload } = stats?.bitrate || {};
    if (!download && !upload) {
      return null;
    }
    return (
      <>
        <p className="bitrate">
          <span>&darr;</span>
          {download ? `${download} Kbps` : 'N/A'}
          <span>&uarr;</span>
          {upload ? `${upload} Kbps` : 'N/A'}
        </p>

        <style jsx>
          {`
            p {
              position: fixed;
              top: 150px;
              right: 5px;
              border-radius: 5px;
              background: rgba(0, 0, 0, 0.5);
              padding: 4px 8px;
              font-size: 0.875em;
              color: #fff;
              height: 25px;
              margin: 0;
              z-index: 10000;
            }
          `}
        </style>
      </>
    );
  };

  const getEnlargedParticipantResolution = () => {
    if (localRole === MeetingRole.STUDIO) return null;
    const resolutionString = Object.keys(resolution || {})
      .map((ssrc) => {
        const { width, height } = resolution[ssrc];
        return `${width}x${height}`;
      })
      .join(', ');
    if (resolutionString) {
      return (
        <>
          <p className="resolution">{resolutionString}</p>
          <style jsx>
            {`
              p {
                position: fixed;
                top: 120px;
                right: 5px;
                border-radius: 5px;
                background: rgba(0, 0, 0, 0.5);
                padding: 4px 8px;
                font-size: 0.875em;
                color: #fff;
                height: 25px;
                margin: 0;
                z-index: 10000;
              }
            `}
          </style>
        </>
      );
    }
    return null;
  };

  React.useEffect(() => {
    document.body.classList.add('enlarge-mode');
    return () => {
      document.body.classList.remove('enlarge-mode');
    };
  }, []);
  return (
    <div>
      <div className="enlarge-video fixed-full">
        {isLocalVideoEnlarged ? (
          <LocalVideo />
        ) : (
          <RemoteVideo
            key={participantId}
            participantId={participantId}
            showGrid={showEnlargedGrid}
          />
        )}
        {router.query.debug && getEnlargedParticipantResolution()}
        {router.query.debug && getEnlargedParticipantBitrate()}
        {shouldShowCloseButton && (
          <button className="btn btn-close" onClick={onToggleEnlarge}>
            &times;
          </button>
        )}
      </div>

      <style jsx global>{`
        .enlarge-video {
          z-index: ${isOverlayImgVisible ? 0 : zIndex.meetingVideosWrapper};
        }
        .enlarge-video .video-thumbnail {
          border-radius: 0 !important;
          position: fixed;
          left: 0 !important;
          right: 0 !important;
          top: 0 !important;
          bottom: 0 !important;
          width: auto;
          height: auto;
          margin: 0 !important;
          border-radius: 0 !important;
        }
        .enlarge-video .video-thumbnail video {
          border-radius: 0 !important;
        }
        .enlarge-video .video-thumbnail .ClientControlsButton {
          display: none;
        }

        .enlarge-video .video-thumbnail.isStudio video {
          object-fit: contain !important;
          height: 100%;
          width: 100%;
        }

        body.SM.SMPortrait .enlarge-video .video-thumbnail video {
          object-fit: cover !important;
        }
      `}</style>
    </div>
  );
};

export default EnlargedVideo;
