import React from 'react';
import CountDown from 'react-countdown';
import SessionHealthCheckButton from '../../../Meeting/HealthCheck/SessionHealthCheckButton';

const ChopardWW2021CountDown = ({
  endDate,
  onEnded
}: {
  endDate: Date;
  onEnded: () => void;
}) => {
  React.useEffect(() => {
    if (!endDate || new Date() >= endDate) {
      onEnded();
    }
  }, []);

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="countdown-container">
        <div className="pill-container">
          <div className="pill">
            <div className="countdown-number">{days}</div>
          </div>
          <p className="countdown-pill-label">DAYS</p>
        </div>
        <div className="pill-container">
          <div className="pill">
            <div className="countdown-number">{hours}</div>
          </div>
          <p className="countdown-pill-label">HOURS</p>
        </div>
        <div className="pill-container">
          <div className="pill">
            <div className="countdown-number">{minutes}</div>
          </div>
          <p className="countdown-pill-label">MINUTES</p>
        </div>
        <div className="pill-container">
          <div className="pill">
            <div className="countdown-number">{seconds}</div>
          </div>
          <p className="countdown-pill-label">SECONDS</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="countdown">
        <img
          className="logo"
          alt="Chopard Logo"
          src="/asset/chopard/chopard-countdown-logo.png"
        />
        <div>
          <h1 className="heading mb-1">You are invited to enter</h1>
          <h1 className="heading">Chopard’s Watches and Wonders 2021</h1>
        </div>
        <CountDown
          className="time-left"
          zeroPadDays={2}
          zeroPadTime={2}
          date={endDate}
          onComplete={() => {
            onEnded();
          }}
          renderer={renderer}
        />
        <SessionHealthCheckButton dark />

        <style jsx global>
          {`
            .countdown-container {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .pill {
              background: url('/asset/chopard/chopard-timer-bg.png');
              background-repeat: no-repeat;
              background-size: cover;
              margin: 0 25px;
              width: 200px;
              height: 260px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .countdown-number {
              font-family: 'Helvetica Neue LT Std BLkCn', Arial;
              font-style: normal;
              font-weight: 900;
              font-size: 7rem;
              text-align: center;
              color: #000;
              padding-top: 22px;
            }
            .countdown-pill-label {
              margin: 10px 0;
              font-family: 'Helvetica Neue LT Std Th', Arial;
              font-style: normal;
              font-size: 1rem;
              color: #000;
              font-weight: 200;
            }

            @media (max-width: 998px) {
              .pill {
                width: 120px;
                height: 156px;
                margin: 0 15px;
              }

              .countdown-number {
                font-size: 3.5rem;
                padding-top: 10px;
              }
              .countdown-pill-label {
                font-size: 0.5rem;
              }
            }

            @media (max-width: 768px) {
              .pill {
                width: 100px;
                height: 130px;
                margin: 0 15px;
              }

              .countdown-number {
                font-size: 3.5rem;
                padding-top: 10px;
              }
              .countdown-pill-label {
                font-size: 0.5rem;
              }
            }

            @media (max-width: 520px) {
              .pill {
                width: 80px;
                height: 100px;
                margin: 0 5px;
              }

              .countdown-number {
                font-size: 2rem;
                padding-top: 10px;
              }
            }

            @media only screen and (max-height: 480px) and (orientation: landscape) {
              .pill {
                margin: 0 5px;
              }

              .countdown-number {
                font-size: 2rem;
                padding-top: 10px;
              }
            }

            @media only screen and (max-height: 420px) and (orientation: landscape) {
              .pill {
                width: 60px;
                height: 78px;
                margin: 0 10px;
              }

              .countdown-number {
                font-size: 2rem;
                padding-top: 10px;
              }
            }
          `}
        </style>
        <style jsx>{`
          @font-face {
            font-family: 'Helvetica Neue LT Std BLkCn';
            src: url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.eot');
            src: url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.eot?#iefix')
                format('embedded-opentype'),
              url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.woff2')
                format('woff2'),
              url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.woff')
                format('woff'),
              url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.ttf')
                format('truetype'),
              url('/asset/chopard/fonts/HelveticaNeueLTStd-BlkCn.svg#HelveticaNeueLTStd-BlkCn')
                format('svg');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'Helvetica Neue LT Std Th';
            src: url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.eot');
            src: url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.eot?#iefix')
                format('embedded-opentype'),
              url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.woff2')
                format('woff2'),
              url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.woff')
                format('woff'),
              url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.ttf')
                format('truetype'),
              url('/asset/chopard/fonts/HelveticaNeueLTStd-Th.svg#HelveticaNeueLTStd-Th')
                format('svg');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
          @font-face {
            font-family: 'Lucida Grande';
            src: url('/asset/chopard/fonts/LucidaGrandeBold.eot');
            src: url('/asset/chopard/fonts/LucidaGrandeBold.eot?#iefix')
                format('embedded-opentype'),
              url('/asset/chopard/fonts/LucidaGrandeBold.woff2') format('woff2'),
              url('/asset/chopard/fonts/LucidaGrandeBold.woff') format('woff'),
              url('/asset/chopard/fonts/LucidaGrandeBold.ttf')
                format('truetype'),
              url('/asset/chopard/fonts/LucidaGrandeBold.svg#LucidaGrandeBold')
                format('svg');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
          .countdown {
            background: url('/asset/chopard/Chopard-countdown-background.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;
            text-align: center;
            color: #000;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
          h1 {
            font-size: 2.3rem;
            margin-bottom: 50px;
            font-family: 'Lucida Grande', Arial;
            font-style: normal;
            font-weight: bold;
            text-align: center;
            color: #000000;
          }

          .logo {
            width: 200px;
            top: 50px;
            margin-bottom: 50px;
          }

          :global(.time-left) {
            font-size: 5rem;
            font-weight: 600;
            letter-spacing: 20px;
          }

          button {
            position: fixed;
            bottom: 20px;
            right: 20px;
            background: transparent;
            border: none;
          }

          :global(.toggle-mute svg) {
            width: 40px;
            height: 40px;
          }

          @media (max-width: 768px) {
            h1 {
              font-size: 1.5rem;
            }

            :global(.time-left) {
              font-size: 2.5rem;
              font-weight: 600;
              letter-spacing: 10px;
            }
          }

          @media (max-width: 480px) {
            h1 {
              font-size: 1rem;
              margin-bottom: 20px;
            }

            .logo {
              width: 140px;
              top: 120px;
              position: absolute;
            }
          }

          @media only screen and (max-height: 480px) and (orientation: landscape) {
            .countdown {
              padding-top: 50px;
              justify-content: initial;
            }
            h1 {
              font-size: 1.3rem;
              margin-bottom: 15px;
            }

            .logo {
              width: 140px;
              margin-bottom: 15px;
            }
          }

          @media only screen and (max-height: 380px) and (orientation: landscape) {
            .countdown {
              padding-top: 20px;
              justify-content: initial;
            }
          }
        `}</style>
      </div>
    </>
  );
};

export default ChopardWW2021CountDown;
