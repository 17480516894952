import { truncate } from 'lodash';
import React from 'react';
import { FaCrown } from 'react-icons/fa';
import { parsePlainTextToRichText } from '../../../utils/string';
import { MessageForBubble } from './chatUtils';

const ChatBubble = ({
  message,
  isQuoted,
  onClick,
  quote
}: {
  message: MessageForBubble;
  isQuoted?: boolean;
  onClick?: (id: string) => void;
  quote?: React.ReactNode;
}) => {
  const {
    isHeader,
    senderName,
    isMine,
    text,
    time,
    colorIdentifier,
    id,
    status,
    isFromAdvisor
  } = message || {};

  const remoteBubbleColor = '#fff';
  const remoteBubbleShadow = '#e3e3e3';
  const localBubbleColor = '#e3e3e3';

  const isOffline = status === 'offline';

  const bubbleSender = ((isHeader && !isMine) || isQuoted) && (
    <div className="sender">
      {senderName}{' '}
      {isFromAdvisor && (
        <sup>
          <FaCrown />
        </sup>
      )}{' '}
      {isOffline && <span>(offline)</span>}
      <style jsx>{`
        .sender {
          font-weight: bold;
          display: block;
          font-size: 0.85em;
          color: ${isOffline ? '#a9a9a9' : colorIdentifier};
        }
        .sender span {
          font-weight: normal;
          font-size: 10px;
          color: #ccc;
        }
      `}</style>
    </div>
  );

  return (
    <div
      id={`bubble-${id}`}
      className={`bubble-container ${
        isMine ? 'local-bubble' : 'remote-bubble'
      } ${isQuoted ? 'quoted' : ''}`}
    >
      {!message ? (
        <p className="empty">
          {isQuoted ? 'Quoted' : 'The'} message not available
        </p>
      ) : (
        <div
          onClick={() => !isQuoted && onClick?.(id)}
          className={`bubble ${isHeader ? 'bubble-head' : ''}`}
        >
          {bubbleSender}
          {quote}
          <div className="text">
            {parsePlainTextToRichText(
              isQuoted ? truncate(text, { length: 200 }) : text
            )}
          </div>
          <div className="time">{time}</div>
        </div>
      )}

      <style jsx>{`
        .bubble-container {
          padding: 2px 10px;
          overflow: hidden;
        }
        .bubble-container.quoted {
          padding: 0;
          border-radius: 5px;
        }

        .local-bubble:not(.quoted) {
          text-align: right;
        }
        .remote-bubble:not(.quoted) {
          text-align: left;
        }

        :not(.quoted) .bubble-head {
          margin-top: 10px;
        }

        :not(.quoted) .bubble-head:before {
          content: ' ';
          position: absolute;
          width: 0;
          height: 0;
          top: 0px;
          bottom: auto;
          border: 12px solid;
          border-color: ${remoteBubbleColor} transparent transparent transparent;
          left: -10px;
          right: auto;
        }
        .local-bubble:not(.quoted) .bubble-head:before {
          right: -10px;
          left: auto;
          border-color: ${remoteBubbleShadow} transparent transparent
            transparent;
        }
        .bubble {
          position: relative;
          border-radius: 10px;
          background: ${remoteBubbleColor};
          display: inline-block;
          font-family: Helvetica;
          text-align: left;
          max-width: 80%;
          padding: 5px 10px;
          font-size: 16px;
          cursor: ${isQuoted ? 'inherit' : 'pointer'};
        }
        .bubble :global(.bubble-container) {
          margin: 5px -5px !important;
        }

        .local-bubble .bubble {
          background: ${localBubbleColor};
        }
        .remote-bubble:not(.quoted) .bubble {
          border-bottom: 1px solid ${localBubbleColor};
        }
        .quoted .bubble {
          max-width: none;
          width: 100%;
          border-radius: 5px;
          border-left: 2px solid ${colorIdentifier};
          font-size: 13px;
          background: #f3f3f3;
        }
        .time {
          font-size: 0.75em;
          opacity: 0.6;
          text-align: right;
          margin-top: -15px;
          z-index: 0;
          position: relative;
        }
        .text {
          letter-spacing: 0.3px;
          font-size: 1em;
          overflow-wrap: break-word;
          word-break: break-word;
          z-index: 1;
          position: relative;
        }
        .text:after {
          content: '';
          display: inline-block;
          height: 2px;
          width: 50px;
        }
        .empty {
          font-style: italic;
          font-size: 0.75em;
          margin: 0;
          padding: 5px 10px;
          text-align: center;
          opacity: 0.5;
          letter-spacing: 0;
          background: #f3f3f3;
        }
      `}</style>
    </div>
  );
};

export default ChatBubble;
