import React from 'react';
import CountDown from 'react-countdown';
import { videoBaseUrl } from '../../../../config';
import { LFAssetBaseUrl } from '../../../../config/loubiFuture';
import { getViewportSize } from '../../../../utils/window';
import ReactPlayer from 'react-player';
import SessionHealthCheckButton from '../../../Meeting/HealthCheck/SessionHealthCheckButton';
import { LFColor } from '../../../ThreejsAnimation/loubiFuture/assets';

const asset = `${LFAssetBaseUrl}/countdown/v5`;
const videoLandscape = `${videoBaseUrl}/content/converted/f8d7619c-165e-4799-8326-94f2ec915f9c/countdown.mp4/index_high.m3u8`;
const videoPortrait = `${videoBaseUrl}/content/converted/f8d7619c-165e-4799-8326-94f2ec915f9c/countdown_m.mp4/index.m3u8`;

const LFCountDown = ({
  endDate,
  onEnded,
  healthCheck
}: {
  endDate: Date;
  onEnded: () => void;
  healthCheck?: boolean;
}) => {
  const [{ vh, vw }, setViewportSize] = React.useState({ vh: 0, vw: 0 });
  const [showVideo, setShowVideo] = React.useState(true);
  const [videoUrl, setVideoUrl] = React.useState('');
  const [play, setPlay] = React.useState(false);

  React.useEffect(() => {
    if (!endDate || new Date() >= endDate) {
      onEnded();
    }
    const size = getViewportSize();
    setViewportSize(size);
    setVideoUrl(size.vh <= size.vw ? videoLandscape : videoPortrait);
  }, []);

  const renderNumber = (num: number) => {
    let str = `${num}`;
    if (num / 10 < 1) {
      str = `0${num}`;
    }
    return (
      <div className="timeBox">
        {str[0]}
        {str[1]}
        <style jsx>{`
          .timeBox {
            text-align: center;
            font-family: 'LF-Bold', Arial;
            color: #000;
            text-align: center;
            font-weight: bold;
            flex-grow: 1;
          }
          @media (orientation: portrait) {
            .timeBox {
              font-size: 5.5vw;
            }
          }
          @media (orientation: landscape) {
            .timeBox {
              font-size: 7.5vh;
            }
          }
        `}</style>
      </div>
    );
  };
  const renderer = ({ days, hours, minutes, seconds }) => (
    <div className="timer-container text-center">
      {renderNumber(days)}
      {renderNumber(hours)}
      {renderNumber(minutes)}
      {renderNumber(seconds)}
      <style jsx>
        {`
          .timer-container {
            display: flex;
          }
        `}
      </style>
    </div>
  );

  return (
    <>
      {videoUrl && (
        <div className="video-container fixed-full">
          <ReactPlayer
            playing={play}
            width="100%"
            height="100%"
            autoPlay
            playsinline
            muted
            onDuration={() => {
              setPlay(true);
            }}
            url={videoUrl}
            onError={() => setShowVideo(false)}
            onPause={() => setShowVideo(false)}
            onEded={() => setShowVideo(false)}
          />
        </div>
      )}

      <div
        className={`LFCountDown fixed-full ${
          !showVideo ? 'showing' : 'closing'
        }`}
      >
        <div className="content">
          <CountDown
            zeroPadDays={2}
            zeroPadTime={2}
            date={endDate}
            renderer={renderer}
            onTick={(time) => {
              if (time.total <= 1000) {
                onEnded();
              }
            }}
            onComplete={() => {
              onEnded();
            }}
          />
        </div>
        <div className="roller">
          <img src={`${asset}/roll.png`} className="roll1" alt="" />
          <img src={`${asset}/roll.png`} className="roll2" alt="" />
        </div>
        {healthCheck && <SessionHealthCheckButton dark={true} />}
      </div>
      <style jsx>{`
        .LFCountDown :global(a.dark) {
          border-radius: 40px !important;
          border: 2px solid #13fff1 !important;
          box-shadow: 0px 1px 5px #13fff1, #13fff1 0px 0px 5px inset !important;
          color: ${LFColor.purpleDark};
          font-family: 'LF-Bold', Arial !important;
        }
        .video-container :global(video) {
          position: fixed;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          object-fit: cover !important;
        }
        .LFCountDown {
          display: flex;
          flex-direction: column;
          justify-content: center;
          opacity: 0;
          transition: opacity 1s ease-in;
        }
        .LFCountDown.showing {
          opacity: 1;
        }
        .content {
          margin: auto;
        }
        .roller {
          position: absolute;
          left: 50%;
          top: 50%;
        }
        .roller img {
          width: 24%;
        }
        .roll1 {
          -webkit-animation: rotate-center 2s linear infinite both;
          animation: rotate-center 2s linear infinite both;
        }
        .roll2 {
          -webkit-animation: rotate-center2 2s linear infinite both;
          animation: rotate-center2 2s linear infinite both;
          float: right;
        }
        @-webkit-keyframes rotate-center {
          0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes rotate-center {
          0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        @-webkit-keyframes rotate-center2 {
          0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes rotate-center2 {
          0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        @media (orientation: portrait) {
          .LFCountDown {
            background: #f0be0f url('${asset}/portrait.png') center center
              no-repeat;
            background-size: 100% auto;
          }
          .content {
            width: 54%;
            transform: translateY(-80%);
          }
          .roller {
            transform: translate(-50%, -0.4vw);
            width: 45%;
          }
          .video-container :global(video) {
            width: 100vw !important;
            height: ${vw * 2.315}px !important;
          }
        }
        @media (orientation: landscape) {
          .LFCountDown {
            background: #f0be0f url('${asset}/landscape.png') center center
              no-repeat;
            background-size: cover;
          }
          .content {
            width: 70vh;
            transform: translateY(-13vh);
          }
          .roller {
            transform: translate(-50%, -4vh);
            width: 57vh;
          }
          .video-container :global(video) {
            height: ${vh}px !important;
            width: ${vh * 3.563}px !important;
          }
        }
      `}</style>
    </>
  );
};

export default LFCountDown;
