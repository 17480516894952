import React from 'react';
import { useSelector } from 'react-redux';
import {
  IMainState,
  JitsiRemoteTrack,
  MeetingLayoutMode,
  MeetingRole
} from '../../../interfaces';
import { getTwoLetterFromName } from '../../../utils/string';
import { useLocalUserMeetingEntitlement } from '../../hooks/meeting';
import MeetingParticipantControlsButton from '../Controls/MeetingParticipantControlsButton';
import ParticipantStatusIndicators from '../ParticipantStatusIndicators';
import MeetingVideo from './MeetingVideo';
import MeetingAudio from '../MeetingAudio';
import ParticipantConnectionIndicators from '../ParticipantConnectionIndicators';

const RemoteVideo = ({
  participantId,
  showGrid
}: {
  participantId: string;
  showGrid?: boolean;
}) => {
  const videoRef = React.useRef<HTMLVideoElement>();
  const audioRef = React.useRef<HTMLAudioElement>();
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const participantInfo = (meeting.remoteUsers || {})[participantId] || {};
  const selected = meeting?.participantControls === participantId;
  const role = participantInfo.role;
  const meetingLayout = meeting?.layout;
  const isEnlarged =
    meetingLayout?.enlargedVideoParticipantId === participantId;
  const isRemoteStudio = role === MeetingRole.STUDIO;
  const isInactive =
    !isEnlarged &&
    Object.keys(meeting.remoteUsers || {})?.length > 10 &&
    participantInfo.connectionStatus === 'inactive';

  const isDominantSpeaker =
    meeting?.dominantSpeakerParticipantId === participantId;

  const isRaisingHand = (meeting?.raiseHand?.participantIds || []).includes(
    participantId
  );
  const isInvisible =
    meetingLayout?.invisibleParticipantIds?.includes(participantId);

  const displayName = isRemoteStudio ? 'Studio' : participantInfo.displayName;
  const zoomLevel = meetingLayout?.studioViewState?.zoomLevel;
  const rotateDeg = meetingLayout?.studioViewState?.rotateDeg;
  const isTile = meetingLayout?.mode === MeetingLayoutMode.TILE;

  const [canControlMeeting] = useLocalUserMeetingEntitlement();
  const tracks: JitsiRemoteTrack[] = participantInfo.tracks || [];

  const audioTrack = tracks.find((track) => track.getType() === 'audio');
  const videoTrack = tracks.find((track) => track.getType() === 'video');
  const audioMuted =
    (participantInfo.audioMuted || !audioTrack) && !isRemoteStudio;
  const videoMuted = participantInfo.videoMuted || !videoTrack;

  const remoteStudioIsFullScreen =
    meetingLayout?.enlargedVideoParticipantId === participantId;
  const getScaleSize = () => {
    if (!isRemoteStudio) {
      return 1;
    }
    if (isTile || remoteStudioIsFullScreen) {
      return zoomLevel;
    }
    return 1;
  };
  const getRotateDeg = () => {
    if (!isRemoteStudio) {
      return 0;
    }

    if (isTile || remoteStudioIsFullScreen) {
      return rotateDeg;
    }
    return 0;
  };

  const isSharingScreen = videoTrack?.videoType === 'desktop';

  React.useEffect(() => {
    if (videoTrack && videoTrack.getOriginalStream() && videoRef.current) {
      console.group('track and original stream');
      console.log(videoTrack);
      console.log(videoTrack.getOriginalStream());
      console.groupEnd();
      (videoRef.current as HTMLVideoElement).setAttribute('muted', '');
      (videoRef.current as HTMLVideoElement).setAttribute('playsInline', '');
      (videoRef.current as HTMLVideoElement).setAttribute('autoplay', '');
      videoTrack.attach(videoRef.current);
    }
    return () => {
      if (videoRef.current) {
        videoTrack?.detach(videoRef.current);
      }
    };
  }, [videoTrack, videoMuted, isInactive]);

  React.useEffect(() => {
    if (audioTrack && audioRef.current) {
      if (
        meeting.localUser?.role !== MeetingRole.STUDIO &&
        participantInfo.role !== MeetingRole.STUDIO
      ) {
        audioTrack.attach(audioRef.current);
      }
    }
    return () => {
      if (audioRef.current) {
        audioTrack?.detach(audioRef.current);
      }
    };
  }, [audioTrack, audioMuted, isInactive]);

  const conditionalClassName = [
    `${isEnlarged ? 'enlarge' : ''}`,
    `${isDominantSpeaker && !audioMuted ? 'isSpeaking' : ''}`,
    `${isInvisible ? 'isInvisible' : ''}`,
    `${isRaisingHand ? 'isRaiseHand' : ''}`,
    `${selected ? 'isSelected' : ''}`,
    `${isRemoteStudio ? 'isStudio' : ''}`,
    `${isSharingScreen ? 'isSharingScreen' : ''}`
  ].join(' ');

  return (
    <div
      className={`remote-video-container video-thumbnail ${conditionalClassName}`}
      id={`video-${participantId}`}
    >
      <span className="initialName notranslate">
        {getTwoLetterFromName(displayName)}
      </span>

      {!videoMuted && !isInactive ? (
        <MeetingVideo videoRef={videoRef} />
      ) : (
        <div className="remote-video place-holder"></div>
      )}

      {!audioMuted && <MeetingAudio audioRef={audioRef} />}

      {displayName && (
        <div className="display-name notranslate">{displayName}</div>
      )}

      {canControlMeeting && (
        <MeetingParticipantControlsButton
          participantId={participantId}
          meeting={meeting}
          role={role}
        />
      )}

      <div className="highlight-box" />
      {showGrid && (
        <>
          <div className="grid-line line-vertical" />
          <div className="grid-line line-horizontal" />
        </>
      )}

      <ParticipantStatusIndicators
        invisible={isInvisible}
        raiseHand={isRaisingHand}
        audioMuted={audioMuted}
        role={role}
        videoMuted={videoMuted}
        speaking={isDominantSpeaker && !audioMuted}
        pinned={(meeting?.layout?.pinnedParticipantIds || []).includes(
          participantId
        )}
        viewOnly={!canControlMeeting}
      />
      {meeting?.statsMode && (
        <ParticipantConnectionIndicators
          remoteStats={participantInfo?.stats}
          meetingStats={meeting?.stats}
          participantId={participantId}
          isLocal={false}
        />
      )}
      <style jsx>
        {`
          .loading {
            font-size: 1rem;
          }
          .display-name {
            user-select: none;
          }

          .initialName {
            z-index: ${videoMuted || isInactive ? 2 : 0};
          }

          .remote-video-container.highlight .initialName {
            opacity: 1;
          }

          .enlarge-close {
            z-index: 9;
          }
          .video-thumbnail.isSharingScreen :global(video) {
            object-fit: contain !important;
          }
          .video-thumbnail.isStudio :global(video) {
            transform: scale(${getScaleSize() || 1})
              rotate(${getRotateDeg() || 0}deg);
          }
        `}
      </style>
    </div>
  );
};

export default RemoteVideo;
