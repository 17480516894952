import {
  inspifyGCCSiteId,
  inspifySiteId,
  inspifyJPSilkRoadSiteId,
  inspifyTestingSiteId
} from '../../config';
import { MDGlobalSpec } from '../Meeting/MeetingLayout/MDGlobal';
import { SMGlobalSpec } from '../Meeting/MeetingLayout/SMGlobal';

const BoutiqueCss = ({ isRtl }: { isRtl: boolean | undefined }) => (
  <style jsx global>
    {`
      html {
        height: 100vh;
      }
      body {
        height: 100vh;
        position: relative;
        background-color: #000;
        overflow: hidden;
      }

      button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        border: none;
        cursor: pointer;
      }

      video {
        outline: none;
        border: none;
      }
      input {
        -webkit-user-select: text;
      }

      #embedframe {
        position: fixed;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        margin: 0;
        direction: ltr;
      }

      /* REACT-DATEPICKER */
      .react-datepicker-popper {
        z-index: 5;
      }
      /* REACT-DATEPICKER ENDS */

      /* BUTTON OPTIONS */

      .button-options-list {
        overflow: hidden;
        font-size: 0.93em;
        position: absolute;
        transition: opacity 0.3s ease-out;
        top: 30px;
        left: 0;
        width: auto;
        height: 0;
        opacity: 0;
      }

      .button-options-list.active {
        height: auto;
        opacity: 1;
        border: 2px solid #fff;
      }

      .button-options-list a,
      .button-options-list > span {
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        padding: 12px 20px;
        color: #333;
        text-decoration: none;
        text-align: ${isRtl ? 'right' : 'left'};
        cursor: pointer;
        white-space: nowrap;
        direction: ${isRtl ? 'rtl' : 'ltr'};
      }

      @media (max-width: 768px) {
        .button-options-list {
          top: 50px;
          left: 10px;
          width: auto;
          right: 10px;
          position: fixed;
        }

        .button-options-list a,
        .button-options-list > span {
          padding: 15px 10px;
          white-space: normal;
        }
      }

      .button-options-list a:hover,
      .button-options-list > span:hover {
        background: rgba(255, 255, 255, 0.95);
      }

      .button-options-list a svg,
      .button-options-list > span svg {
        width: 24px;
        height: 24px;
        margin-right: ${isRtl ? '0' : '5px'};
        margin-left: ${isRtl ? '5px' : '0'};
      }

      /* BUTTON OPTIONS ENDS */

      /* MEET */

      .meet-icon-container {
        position: fixed;
        top: 10px;
        right: 50px;
      }

      @media (max-width: 768px) {
        .meet-icon-container {
          display: none;
        }
      }
      #embedframe.in-meeting.show-controls {
        height: calc(100% - 540px);
      }

      #embedframe.presentation-mode + .express-icons-container {
        z-index: 2002;
        position: fixed;
      }

      @media (max-width: 840px) {
        #embedframe.presentation-mode {
          left: 0;
          right: 0;
          width: 100vw;
          bottom: auto;
          height: 40vh;
        }
      }

      .btn-action-group {
        position: fixed;
        top: 10px;
        left: 10px;
        z-index: 40;
        z-index: 2;
        display: flex;
        direction: ltr;
      }

      .meet-container {
        position: fixed;
        bottom: -500px;
        left: 0;
        right: 0;
        height: 540px;
        z-index: 45;
      }

      .meet-container.show-controls {
        bottom: 0;
      }

      #meet {
        background: #000000;
        height: 500px;
      }

      .meet-container p {
        margin: 0;
        background: black;
        color: white;
        padding: 0 20px;
        font-size: 1em;
        line-height: 40px;
        text-align: right;
      }

      .message {
        display: flex;
        justify-content: space-between;
      }

      .controls {
        cursor: pointer;
      }

      .join-url {
        font-style: italic;
        text-decoration: underline;
      }

      .controls svg {
        display: inline-block;
        margin-left: 10px;
        fill: #ffffff;
      }

      .message .share svg {
        width: 22px;
        height: 22px;
        fill: white;
        margin-left: 10px;
      }

      .share button {
        border: none;
        background: none;
        text-decoration: none;
        cursor: pointer;
      }

      .share button:focus {
        outline: none;
      }

      .share a {
        text-decoration: none;
      }

      .share.copy {
        position: relative;
      }

      .copy-tooltip {
        color: white;
        position: absolute;
        background: rgba(0, 0, 0, 0.7);
        top: -45px;
        left: -4px;
        padding: 5px;
        line-height: 20px;
        font-size: 0.875em;
        border-radius: 5px;
        opacity: 0;
      }

      /* END MEET */

      .fadein {
        transition: all 0.3s ease-in-out;
      }

      .fadein.entering {
        opacity: 0;
      }
      .fadein.entered {
        opacity: 1;
      }
      .fadein.exiting {
        opacity: 0;
      }
      .fadein.exited {
        opacity: 0;
      }

      .nav-item {
        width: 100%;
        padding: 20px 30px;
        display: flex;
        background: transparent;
        color: #efefef;
        text-decoration: none;
        align-items: center;
        cursor: pointer;
        text-align: left;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 0.93em;
      }

      .nav-item:hover {
        background: #222;
        color: #fff;
        text-decoration: none;
      }

      .nav-item__label {
        font-weight: 200;
      }

      .nav-item__icon {
        width: 30px;
        height: auto;
        text-align: ${isRtl ? 'right' : 'left'};
      }
      .nav-item__icon :global(svg),
      .nav-item__icon :global(img) {
        height: 22px;
        width: 22px;
      }

      /* SHARE */

      .with-shadow {
        -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.7));
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.7));
      }

      .share-list {
        display: flex;
        flex-wrap: wrap;
      }
      .resp-sharing-button {
        font-size: 0.8125em;
      }
      .resp-sharing-button__icon {
        display: block;
        fill: #fff;
      }
      .resp-sharing-button__link {
        display: block;
        text-align: center;
        width: 50%;
        border: 0.5px solid #333;
        text-decoration: none;
        padding: 20px 0;
        color: #ccc;
        font-weight: 200;
      }

      .resp-sharing-button__link:hover {
        background: #333;
        color: inherit;
        text-decoration: none;
      }
      .resp-sharing-button__icon svg {
        width: 22px;
        height: 22px;
        vertical-align: top;
        fill: #ccc;
      }
      .resp-sharing-button--small svg {
        margin: 0;
        vertical-align: middle;
      }

      /* END SHARE */

      /* APPOINTMENT */

      button:disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }

      .action-button {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
        background: transparent;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border: 2px solid #fff;
        height: 30px;
        font-weight: 700;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
        font-size: 0.75em;
        letter-spacing: 2px;
        text-transform: uppercase;
        z-index: 40;
        margin-right: 10px;
        padding: 1px 6px;
      }

      .action-button a {
        text-decoration: none;
        color: inherit;
      }

      .action-button:hover {
        background: #fff;
        color: #000;
        text-shadow: none;
      }

      .appointment .popup-container {
        height: 100vh;
        z-index: 1003;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        text-align: center;
        position: fixed;
      }

      .appointment .form-container {
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: left;
        padding: 50px 20px 20px 20px;
        overflow-y: auto;
        font-size: 1em;
      }

      .appointment h2,
      .appointment h3 {
        text-align: center;
      }
      .appointment h2 {
        margin: 25px 0 20px 0;
        padding: 0;
        font-size: 1.2em;
        letter-spacing: 3px;
        font-weight: 700;
      }

      .appointment h3 {
        font-size: 0.8em;
        letter-spacing: 5px;
        color: rgb(163, 163, 163);
        margin: 30px 0 20px;
      }

      .appointment .input-group {
        position: relative;
      }
      .appointment label {
        display: block;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 0.7em;
        margin-top: 15px;
        margin-bottom: 5px;
      }
      .appointment a {
        color: inherit;
        text-decoration: underline;
      }

      .appointment input,
      .appointment select,
      .appointment textarea {
        border: none;
        border-bottom: 1px solid rgb(223, 223, 223);
        width: 100%;
        background: transparent;
        font-size: 1em;
        outline: none;
        border-radius: 0;
        box-sizing: border-box;
      }

      .appointment .phone-number-input {
        display: flex;
        width: 100%;
      }

      .phone-number-input {
        direction: ltr;
      }

      .phone-number-input #inputcountrycode {
        width: 60px;
        margin-right: 15px;
      }

      .phone-number-input #inputphone {
        flex-grow: 1;
        text-align: ${isRtl ? 'right' : 'left'};
      }

      .tstc label {
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0.3px;
        color: #666;
        text-align: justify;
        line-height: 1.6;
        width: 100%;
      }

      .tstc input {
        width: auto;
        margin-left: ${isRtl ? '5px' : '0'};
        margin-right: ${isRtl ? '0' : '5px'};
        border: 1px solid rgb(223, 223, 223);
      }

      select,
      select option {
        color: #000000;
      }

      select:invalid,
      select option[value=''] {
        color: #666;
      }

      /*Added for browser compatibility*/
      [hidden] {
        display: none;
      }

      .appointment textarea {
        border: 1px solid rgb(223, 223, 223);
        box-sizing: border-box;
        padding: 5px;
      }
      .appointment .error {
        font-size: 0.56em;
        position: absolute;
        right: ${isRtl ? 'auto' : 0};
        left: ${isRtl ? 0 : 'auto'};
        top: 1px;
        color: rgb(177, 0, 0);
      }

      #sendform,
      #ap-cancel,
      .success button {
        background: #000;
        color: #fff;
        font-size: 0.75em;
        letter-spacing: 5px;
        width: 100%;
        height: 40px;
        margin-top: 10px;
        font-weight: 700;
      }

      #ap-cancel,
      .success button {
        border: 1px solid #000;
        background-color: #fff;
        color: #000;
      }
      .success button {
        width: 200px;
      }
      #ap-close {
        position: absolute;
        right: 10px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        text-align: center;
        top: 10px;
        color: rgb(160, 160, 160);
        background: transparent;
        font-size: 2em;
        font-weight: 400;
      }
      .appointment .logo {
        text-align: center;
        margin-bottom: 40px;
      }

      .appointment .logo img {
        margin: auto;
        width: 140px;
      }

      .appointment .successful {
        text-align: center;
        display: flex;
      }
      .appointment .successful .form-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
      }

      .appointment .successful .logo {
        margin-bottom: 50px;
      }
      .appointment .successful .success {
        display: block;
        text-align: center;
        margin: 0 auto auto auto;
        padding: 0 30px 100px;
      }

      #altdateinput {
        display: flex;
        justify-content: space-between;
      }

      #altdateinput select {
        width: 32%;
      }

      .appointment .additional-disclaimer {
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0.3px;
        color: #666;
        text-align: justify;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .appointment .additional-disclaimer p {
        font-size: 0.7em;
      }

      .appointment .additional-disclaimer ul {
        list-style-position: inside;
        padding: 0;
        font-size: 0.7em;
        margin-bottom: 0;
      }
      .two-col .error.time {
        bottom: -20px;
        top: unset;
      }
      @media (max-width: 1024px) {
        .two-col .error.time {
          top: 18px;
        }
      }
      @media (max-width: 375px) {
        .two-col .error.time {
          bottom: -20px;
          top: unset;
        }
      }
      @media (min-width: 1024px) {
        .appointment .form-container {
          font-size: 1.125em;
          width: 900px;
          margin: auto;
          padding: 70px 100px 30px 100px;
          top: 30px;
          bottom: 30px;
        }

        .appointment h2 {
          font-size: 2em;
        }
        .appointment h3 {
          margin-top: 60px;
          margin-bottom: 0;
        }
        .appointment .input-group {
          margin-top: 40px;
        }

        .appointment .error {
          font-size: 0.625em;
        }
        .two-col {
          display: flex;
          justify-content: space-between;
        }
        .two-col .input-group {
          width: 46%;
        }
        .two-col .error {
          top: 18px;
        }

        #ap-close {
          position: fixed;
          left: 50%;
          right: auto;
          margin-left: 400px;
          top: 40px;
        }

        .appointment .logo {
          text-align: center;
          margin-bottom: 60px;
        }

        .appointment .logo img {
          margin: auto;
          width: 200px;
        }
        .appointment .successful .success {
          padding: 0 120px 200px;
          margin-top: 50px;
        }

        .appointment .additional-disclaimer {
          margin-top: 30px;
          margin-bottom: 0;
        }
      }
      /* END APPOINTMENT */

      /* LANDING PAGE */

      .landing-page {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        display: flex;
        align-items: center;
        z-index: 999;
      }
      .landing-page button {
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
        width: 100%;
        margin-bottom: 15px;
        font-size: 0.75em;
        height: 40px;
        letter-spacing: 1px;
      }
      .landing-page button br {
        display: none;
      }
      .landing-page .inner {
        margin: auto;
        color: #fff;
        padding: 0 20px;
        max-width: 360px;
      }
      .landing-page .logo {
        width: 120px;
      }
      .landing-page .content {
        margin: 40px 0;
      }
      .landing-page h1 {
        font-size: 1em;
        letter-spacing: 2px;
        margin: 0 0 10px;
        font-weight: 600;
        padding: 0;
        text-transform: uppercase;
      }
      .landing-page p {
        margin: 0;
        padding: 0;
        font-weight: 400;
        font-size: 0.81em;
      }

      .tstc-opened .welcome-container {
        display: none;
      }

      .landing-page .tstc {
        margin-top: 40px;
      }

      .landing-page.tstc-opened .tstc {
        display: block;
      }

      .landing-page .tstc label {
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0.3px;
        color: #fff;
        text-align: justify;
        line-height: 1.6;
        font-size: 0.75em;
        display: block;
        margin-bottom: 20px;
        font-size: 0.7em;
      }

      .landing-page .tstc label a {
        color: inherit;
        text-decoration: underline;
      }

      .landing-page .btn-tstc.hidden {
        visibility: hidden;
      }

      @media (max-width: 768px) {
        .btn-group,
        .btn-group-vertical {
          flex-wrap: wrap;
          width: 100%;
        }
      }

      @media (min-width: 1024px) {
        .landing-page .logo {
          width: 240px;
        }

        .landing-page .inner {
          max-width: none;
        }

        .landing-page h1 {
          font-size: 1.8em;
          letter-spacing: 3px;
        }
        .landing-page p {
          font-size: 1em;
          letter-spacing: 1px;
        }

        .landing-page .content {
          margin: 60px 0;
        }
        .landing-page .btn-group {
          display: flex;
          direction: ltr;
          justify-content: space-between;
          width: 500px;
          margin: auto;
        }
        .landing-page button {
          width: calc(50% - 10px);
          height: 70px;
          font-size: 0.94em;
          line-height: 1.4;
          border: 2px solid #fff;
        }

        .landing-page button br {
          display: block;
        }
        .landing-page .tstc {
          margin: 50px auto;
          max-width: 500px;
        }

        .landing-page .tstc .btn-group {
          padding-top: 20px;
        }
      }
      /* END LANDING PAGE */

      /* HOWTO */

      .howto {
        position: fixed;
        z-index: 999;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.8);
      }

      .howto .content-container {
        margin: auto;
        position: relative;
        text-align: center;
        width: calc(100% - 120px);
        max-width: 600px;
      }

      .touch-device .howto .content-container {
        max-width: 330px;
      }

      .howto-nav {
        color: #fff;
        background: transparent;
        position: absolute;
        top: 50%;
        left: -60px;
        width: 70px;
        padding: 0;
        height: 70px;
        margin-top: -70px;
      }

      .howto-next {
        right: -60px;
        left: auto;
      }

      .howto .content {
        margin: auto;
      }

      .howto .content video {
        width: 100%;
        height: auto;
        border-radius: 6px;
      }

      .howto-nav svg {
        margin: 0;
        width: 100%;
        height: auto;
      }

      .howto a {
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 0.75em;
        background: #545454;
        border-radius: 5px;
        padding: 5px 10px;
        color: #fff;
        line-height: 1.3;
      }

      .howto p {
        font-size: 0.82em;
      }

      .howto-close {
        color: #fff;
        background: transparent;
        position: absolute;
        width: 30px;
        right: -30px;
        top: -30px;
        font-size: 1.875em;
      }

      .guide-icon-container {
        position: fixed;
        top: 10px;
        right: 100px;
      }

      .btn-guide {
        padding: 0;
        width: 24px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        border: none;
        z-index: 40;
        cursor: pointer;
      }

      .btn-guide svg {
        fill: #ffffff;
        width: 24px;
        height: 24px;
      }

      @media (max-width: 768px) {
        .guide-icon-container {
          right: 50px;
        }
      }

      @media (min-width: 1024px) {
        .howto p {
          font-size: 1.125em;
        }
      }

      /* END HOWTO */

      .express-icons-container {
        position: fixed;
        right: 12px;
        top: 12px;
        display: flex;
        flex-direction: row-reverse;
        z-index: 3;
      }
      .in-meeting.SM .express-icons-container {
        top: ${SMGlobalSpec.logoSectionHeight + 12}px;
      }
      .in-meeting.MD .express-icons-container {
        top: ${MDGlobalSpec.logoSectionHeight + 12}px;
      }

      .express-icons-container > div {
        margin-left: 20px;
      }

      @media (max-width: 400px) {
        .express-icons-container {
          right: -5px;
        }
        .express-icons-container > div {
          margin-left: 2px;
        }
      }

      .express-icons-container.left-nav {
        left: 55px;
        right: auto;
        flex-direction: row;
      }

      .left-nav .logo {
        margin-left: 30px;
        height: 50px;
      }

      /* RANGE INPUTS */
      input[type='range'] {
        -webkit-appearance: none;
        width: 100%;
        background: transparent;
      }

      input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
      }

      input[type='range']:focus {
        outline: none;
      }

      input[type='range']::-ms-track {
        width: 100%;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
      }
      input[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        background: #efefef;
      }
      input[type='range']::-webkit-slider-thumb {
        box-shadow: none;
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 16px;
        background: #fff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -7px;
      }
      input[type='range']:focus::-webkit-slider-runnable-track {
        background: #fff;
      }
      input[type='range']::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        box-shadow: none;
        background: #fff;
        border-radius: 16px;
        border: none;
      }
      input[type='range']::-moz-range-thumb {
        box-shadow: none;
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 16px;
        background: #fff;
        cursor: pointer;
      }
      input[type='range']::-ms-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
      }
      input[type='range']::-ms-fill-lower {
        background: #fff;
        border: none;
        border-radius: 2.6px;
      }
      input[type='range']::-ms-fill-upper {
        background: #fff;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
      }
      input[type='range']::-ms-thumb {
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 16px;
        background: #fff;
        cursor: pointer;
      }
      input[type='range']:focus::-ms-fill-lower {
        background: #3071a9;
      }
      input[type='range']:focus::-ms-fill-upper {
        background: #367ebd;
      }

      .vbssp .btn-express svg,
      .vbssp .btn-express img {
        height: 50px !important;
        width: auto !important;
      }

      .vbssp .qr-panel {
        width: 200px !important;
      }
      .vbssp .qr-panel.text-center span,
      .vbssp .qr-container .store-device-title {
        font-size: 15px !important;
      }
      .vbssp .qr-container canvas {
        width: 178px !important;
        height: 178px !important;
      }

      .vb-lite.store-${inspifySiteId} .options-container h1 {
        display: none;
      }
      .vb-lite.store-${inspifyTestingSiteId} .options-container h1 {
        display: none;
      }
      .vb-lite.store-${inspifyGCCSiteId} .options-container h1 {
        display: none;
      }
      .vb-lite.store-${inspifyJPSilkRoadSiteId} .options-container h1 {
        display: none;
      }
      @media (min-width: 1400px), (min-width: 1024px) and (max-height: 800px) {
        .vb-lite.store-${inspifySiteId} .logo-middle {
          height: 112px;
          width: 350px;
        }
      }
      @media (min-width: 1400px), (min-width: 1024px) and (max-height: 800px) {
        .vb-lite.store-${inspifyTestingSiteId} .logo-middle {
          height: 112px;
          width: 350px;
        }
        .vb-lite.store-${inspifyGCCSiteId} .logo-middle {
          height: 112px;
          width: 350px;
        }
        .vb-lite.store-${inspifyJPSilkRoadSiteId} .logo-middle {
          width: 350px;
        }
      }
    `}
  </style>
);

export default BoutiqueCss;
