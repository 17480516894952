import React from 'react';
import { BsBell } from 'react-icons/bs';
import { logEvent } from '../../../analytics';
import { zIndex } from '../../../config';
import { ClientState } from '../../../interfaces';
import { DID_TOGGLE_NOTIFICATIONS } from '../../../utils/constants';
import { isUserOniOS, isUserOnMobileOnly } from '../../../utils/deviceDetector';
import { meetingColor } from '../../../utils/meeting';
import { MDLandscapeNormalSpec } from '../MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../MeetingLayout/SMLandscapeNormal';
import MeetingAlert from './MeetingAlert';
import MeetingDisconnectNotification from './MeetingDisconnectedNotification';
import MeetingNewJoinerNotification from './MeetingNewJoinerNotification';
import MeetingRaiseHandNotification from './MeetingRaiseHandNotification';

const MeetingNotifications = ({
  clientState,
  onToggleAudio,
  onToggleVideo
}: {
  clientState: ClientState;
  onToggleVideo: () => void;
  onToggleAudio: () => void;
}) => {
  const [isInIphone, setIsInIphone] = React.useState(false);
  React.useEffect(() => {
    const inIphone = isUserOniOS() && isUserOnMobileOnly();
    setIsInIphone(inIphone);
  }, []);
  const [expand, setExpand] = React.useState(false);
  const notificationButton = (
    <div
      className="notification-button"
      onClick={() => {
        setExpand(!expand);
        logEvent(DID_TOGGLE_NOTIFICATIONS, DID_TOGGLE_NOTIFICATIONS, {
          open: !expand
        });
      }}
    >
      <BsBell className="notification-bell" color="white" />
      <div className="notification-dot" />
      <style jsx>{`
        .notification-button {
          right: 10px;
          cursor: pointer;
          z-index: ${zIndex.notification};
          position: fixed;
          align-items: center;
          animation: bell 3s infinite both;
          width: 20px;
          height: 20px;
          top: ${SMLandscapeNormalSpec.contentArea.top / 2}px;
          margin-top: -10px;
          display: none;
        }
        :global(.notification-container.expanded) .notification-button {
          animation: none;
          opacity: 0.5;
        }
        :global(.NotificationPopup) + .notification-button {
          display: block;
        }

        :global(.MD) .notification-button {
          top: ${MDLandscapeNormalSpec.contentArea.top / 2}px;
          right: ${isInIphone ? '10px' : '45px'};
          width: 24px;
          height: 24px;
          margin-top: -12px;
        }

        :global(.SM.canControl) .notification-button {
          right: ${isInIphone ? '50px' : '85px'};
        }

        :global(.SM:not(.canControl)) .notification-button {
          right: ${isInIphone ? '10px' : '45px'};
        }

        .notification-button :global(svg) {
          width: 100%;
          height: auto;
          display: block;
        }
        .notification-dot {
          position: absolute;
          background: ${meetingColor.warning};
          border-radius: 100px;
          width: 12px;
          height: 12px;
          top: 2px;
          right: -2px;
          border: 2px solid #000;
        }
        :global(.notification-container.expanded) .notification-dot {
          display: none;
        }
        :global(.MD) .notification-dot {
          top: 3px;
        }
        @keyframes bell {
          0%,
          25%,
          100% {
            transform: translateX(0%);
            transform-origin: 50% 50%;
          }
          3% {
            transform: translateX(-5px) rotate(3deg);
          }
          7% {
            transform: translateX(2px) rotate(-3deg);
          }
          11% {
            transform: translateX(-2px) rotate(1.8deg);
          }
          15% {
            transform: translateX(1px) rotate(-1.2deg);
          }
          18% {
            transform: translateX(-0.5px) rotate(0.6deg);
          }
        }
      `}</style>
    </div>
  );
  return (
    <div
      className={`notification-container ${expand && 'expanded'}`}
      onMouseLeave={() => setExpand(false)}
    >
      <MeetingNewJoinerNotification />
      <MeetingAlert
        clientState={clientState}
        onToggleAudio={onToggleAudio}
        onToggleVideo={onToggleVideo}
      />
      <MeetingRaiseHandNotification />
      <MeetingDisconnectNotification />
      {notificationButton}
      <style jsx global>{`
        .notification-container {
          position: fixed;
          z-index: ${zIndex.notification};
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          right: 0;
          top: 0;
          left: 0;
          padding-top: ${SMLandscapeNormalSpec.contentArea.top}px;
          height: ${SMLandscapeNormalSpec.contentArea.top}px;
        }

        .MD .clickable-container,
        .MD .notification-container {
          height: ${MDLandscapeNormalSpec.contentArea.top}px;
          padding-top: ${MDLandscapeNormalSpec.contentArea.top}px;
        }

        .SM.normal-mode.SMLandscape .notification-container {
          left: ${SMLandscapeNormalSpec.contentArea.left}px;
        }
        .MD.normal-mode.MDLandscape .notification-container {
          left: ${MDLandscapeNormalSpec.contentArea.left}px;
        }
        .notification-container .NotificationPopup {
          position: fixed;
          top: 0;
        }

        .MD .notification-container .NotificationPopup {
          top: auto;
        }
        .notification-container.expanded {
          height: auto !important;
          padding-bottom: 40px;
        }
        .notification-container.expanded .NotificationPopup.hide {
          position: relative;
          margin-top: 5px;
          top: auto;
          transform: translateX(0) !important;
        }
        .notification-container .NotificationPopup.show {
          position: relative;
          margin-top: 5px;
          top: auto;
        }
      `}</style>
    </div>
  );
};

export default MeetingNotifications;
