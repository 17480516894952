import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IMainState, ParticipantMeetingState } from '../../interfaces';
import {
  actionEnterMeeting,
  actionParticipantIsReconnected
} from '../../redux/actions';
import { MeetingContext } from './MeetingContext';

function Reconnect() {
  const dispatch = useDispatch();
  const { room } = React.useContext(MeetingContext);
  const clientState =
    useSelector((state: IMainState) => state?.clientState) || {};
  const meeting = clientState?.meeting || {};
  const preMeetingState = meeting.reconnect?.meetingState;
  const isReadyToJoin = meeting.state === ParticipantMeetingState.IN_THE_LOUNGE;
  const isShouldEnterMeeting =
    preMeetingState === ParticipantMeetingState.ENTERED_FROM_LOUNGE;
  React.useEffect(() => {
    if (room && isReadyToJoin) {
      console.log({ preMeetingState });
      room?.setDisplayName(clientState?.user?.alias);
      isShouldEnterMeeting && dispatch(actionEnterMeeting());
      dispatch(actionParticipantIsReconnected());
    }
  }, [room, isReadyToJoin, isShouldEnterMeeting]);

  return (
    <>
      <div className="Reconnect">
        <Spinner animation="border" />
      </div>
      <style jsx>{`
        .Reconnect {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: #000;
          color: #fff;
          align-items: center;
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default Reconnect;
